import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { ShopContent } from '../../../types/cms_shared_types';
import { DefaultBottomBar } from '../BottomBar/DefaultBottomBar';
import { Step } from '../Step';

interface LandingPageProps {
  isError: boolean;
  onSuccessButtonClick: () => void;
  onErrorButtonClick: () => void;
  cmsShopContent: ShopContent;
}

export const LandingPage: FC<LandingPageProps> = ({
  isError,
  onSuccessButtonClick,
  onErrorButtonClick,
  cmsShopContent,
}) => {
  return (
    <Step
      title={isError ? cmsShopContent.landingPageStep.errorTitle : cmsShopContent.landingPageStep.title}
      content={
        <div className="sm:w-9/12 max-w-3xl mx-auto flex flex-col items-center text-center">
          <div className="mb-6 sm:mb-10 py-10 ">
            <div className="mb-8 text-text-dark text-xl font-extrabold">
              {isError
                ? cmsShopContent.landingPageStep.errorDescriptionTitle
                : cmsShopContent.landingPageStep.descriptionTitle}
            </div>

            <div className="text-text-dark text-base font-normal opacity-70 ">
              {isError
                ? render(cmsShopContent.landingPageStep.errorDescriptionText)
                : render(cmsShopContent.landingPageStep.descriptionText)}
            </div>
          </div>

          <div className="mb-24 text-text-dark text-base font-normal opacity-70">
            {render(cmsShopContent.landingPageStep.bottomText)}
          </div>
        </div>
      }
      bottomBar={
        <DefaultBottomBar
          text={isError ? cmsShopContent.landingPageStep.errorButtonText : cmsShopContent.landingPageStep.buttonText}
          iconLeft={<></>}
          iconRight={<></>}
          continueButtonDisabled={false}
          onClick={isError ? onErrorButtonClick : onSuccessButtonClick}
          cmsShopContent={cmsShopContent}
        />
      }
    />
  );
};
