import { FC, useEffect } from 'react';
import { findUserInputResourceByKey, findUserSelectInputResourceByKey } from '../../../helper/cms';
import { ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { InputMode, UserInput } from '../../Common/UserInput';
import { useFormikContext } from 'formik';
import { InvoiceFormInputs } from '../../../types/api_shared_types';
import { SelectOption, UserSelectInput } from '../../Common/UserSelectInput';
import { COUNTRIES } from './countries';
import { validateCharacters } from '../../../helper/validation';

interface InvoiceInformationProps {
  language: string;
  isInvoice?: boolean;
  cmsShopContent: ShopContent;
}

export const InvoiceInformation: FC<InvoiceInformationProps> = ({ language, isInvoice = false, cmsShopContent }) => {
  const { values, setFieldValue, touched } = useFormikContext<InvoiceFormInputs>();

  useEffect(() => {
    if (isInvoice && !touched.invoiceCountryInput) {
      setFieldValue('invoiceCountryInput', values.companyCountryInput);
    }
  }, [isInvoice, values.companyCountryInput, touched.invoiceCountryInput, setFieldValue]);

  const organisationNumberInput = findUserInputResourceByKey(
    'organisation_number_input',
    cmsShopContent.invoiceStep.userInputs,
  );
  const nameInput = findUserInputResourceByKey('name_input', cmsShopContent.invoiceStep.userInputs);
  const coInput = findUserInputResourceByKey('co_input', cmsShopContent.invoiceStep.userInputs);
  const addressInput = findUserInputResourceByKey(
    `${isInvoice ? 'invoice_address_input' : 'address_input'}`,
    cmsShopContent.invoiceStep.userInputs,
  );
  const postNumberInput = findUserInputResourceByKey('post_number_input', cmsShopContent.invoiceStep.userInputs);
  const cityInput = findUserInputResourceByKey('city_input', cmsShopContent.invoiceStep.userInputs);
  const countrySelectInput = findUserSelectInputResourceByKey(
    'country_input',
    cmsShopContent.invoiceStep.userSelectInputs,
  );

  const fetchCountries = (): SelectOption[] => {
    const countries: SelectOption[] = COUNTRIES.map((c) => {
      return { key: c.sv, displayValue: language === 'sv' ? c.sv : c.en };
    });
    return countries.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
  };

  const validations = [{ validate: validateCharacters, errorMessage: cmsShopContent.invalidCharacterError }];

  return (
    <div className="w-full">
      <div className="flex flex-col sm:flex-row gap-x-4">
        <Conditional
          condition={!isInvoice}
          trueRender={
            <UserInput
              name="organisationNumberInput"
              prompt={organisationNumberInput.prompt}
              placeholder={organisationNumberInput.placeholder}
              inputMode={InputMode.Number}
            />
          }
        />

        <UserInput
          name={`${isInvoice ? 'invoiceCareOf' : 'companyName'}Input`}
          prompt={isInvoice ? coInput.prompt : nameInput.prompt}
          placeholder={isInvoice ? coInput.placeholder : nameInput.placeholder}
          validations={validations}
        />
      </div>

      <UserInput
        name={`${isInvoice ? 'invoice' : 'company'}AddressInput`}
        prompt={addressInput.prompt}
        placeholder={addressInput.placeholder}
        validations={validations}
      />

      <div className="flex flex-col sm:flex-row gap-x-4">
        <UserInput
          name={`${isInvoice ? 'invoice' : 'company'}PostNumberInput`}
          prompt={postNumberInput.prompt}
          placeholder={postNumberInput.placeholder}
          inputMode={InputMode.Number}
        />

        <UserInput
          name={`${isInvoice ? 'invoice' : 'company'}CityInput`}
          prompt={cityInput.prompt}
          placeholder={cityInput.placeholder}
          validations={validations}
        />
      </div>

      <UserSelectInput
        name={`${isInvoice ? 'invoice' : 'company'}CountryInput`}
        prompt={countrySelectInput.prompt}
        options={fetchCountries()}
      />
    </div>
  );
};
