import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CmsProduct } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { PriceSimulatorInput } from './PriceSimulatorInput';
import { isMeasureSimulatable, isTimeMeasureSimulatable } from '../../../helper/cms';

interface PriceSimulatorContentProps {
  product: CmsProduct;
  opened: boolean;
  simulatedMeasure: string;
  setSimulatedMeasure: (value: string) => void;
  simulatedTimeMeasure: string;
  setSimulatedTimeMeasure: (value: string) => void;
  updatePrice: (simulatedMeasure?: string, simulatedTimeMeasure?: string) => Promise<void>;
}

export const PriceSimulatorContent: FC<PriceSimulatorContentProps> = ({
  product,
  opened,
  simulatedMeasure,
  setSimulatedMeasure,
  simulatedTimeMeasure,
  setSimulatedTimeMeasure,
  updatePrice,
}) => {
  return (
    <AnimatePresence>
      {opened && (
        <motion.div
          initial={{ height: '0px', opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: '0px', opacity: 0 }}
          transition={{ duration: 0.1 }}
          className="w-full"
        >
          <Conditional
            condition={isMeasureSimulatable(product)}
            trueRender={
              <PriceSimulatorInput
                min={product.measureMin}
                max={product.measureMax}
                unit={product.measureUnit}
                simulatedValue={simulatedMeasure}
                simulationName={'measureSimulation'}
                setSimulatedValue={setSimulatedMeasure}
                updatePrice={updatePrice}
              />
            }
          />
          <Conditional
            condition={isTimeMeasureSimulatable(product)}
            trueRender={
              <PriceSimulatorInput
                min={product.timeMeasureMin}
                max={product.timeMeasureMax}
                unit={product.timeMeasureUnit}
                simulatedValue={simulatedTimeMeasure}
                simulationName={'timeMeasureSimulation'}
                setSimulatedValue={setSimulatedTimeMeasure}
                updatePrice={updatePrice}
              />
            }
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
