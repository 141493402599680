import React, { FC, useState } from 'react';
import { Category, ShopContent } from '../../../types/cms_shared_types';
import { Step } from '../Step';
import { SubcategoryPicker } from './SubcategoryPicker';
import { SidebarTip } from '../SidebarTip';
import { ProductChoiceList } from './ProductChoiceList';
import { Formik } from 'formik';
import { DefaultBottomBar } from '../BottomBar/DefaultBottomBar';
import { Product, ProductListActions } from '../../../types/webshop';
import { findSelectedSubcategoryByKey } from '../../../helper/cms';

export interface ProductChoiceFormInput {
  selectedSubcategoryKey?: string;
}

interface ProductChoiceProps {
  category: Category;
  selectedSubcategoryKey?: string;
  setSelectedSubcategoryKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  nextButtonClicked: () => void;
  cmsShopContent: ShopContent;
}

export const ProductChoice: FC<ProductChoiceProps> = ({
  category,
  selectedSubcategoryKey,
  setSelectedSubcategoryKey,
  selectedProducts,
  selectedProductsActions,
  nextButtonClicked,
  cmsShopContent,
}) => {
  const [showPickSubcategoryText, setShowPickSubcategoryText] = useState<boolean>(false);
  const subcategory = findSelectedSubcategoryByKey(selectedSubcategoryKey, category.subcategories);

  const productChoiceList = (
    <ProductChoiceList
      category={category}
      setSelectedSubcategoryKey={setSelectedSubcategoryKey}
      selectedProducts={selectedProducts}
      selectedProductsActions={selectedProductsActions}
      cmsShopContent={cmsShopContent}
    />
  );

  return (
    <Formik
      onSubmit={nextButtonClicked}
      initialValues={{
        selectedSubcategoryKey: selectedSubcategoryKey,
      }}
      validate={() => {
        if (selectedProducts.length === 0) {
          return { error: Error('No products have been selected!') };
        }
      }}
      validateOnMount
      validateOnChange
    >
      {({ submitForm, isValid }) => (
        <Step
          fullWidth
          title={cmsShopContent.productChoiceStep.title}
          content={
            <div className="flex flex-col sm:flex-row sm:justify-center">
              <div className="sm:flex sm:flex-col sm:w-1/4 max-w-[600px]">
                <div className="sm:w-fit sm:flex sm:flex-col sm:self-end">
                  <SubcategoryPicker
                    category={category}
                    selectedProducts={selectedProducts}
                    selectedProductsActions={selectedProductsActions}
                    cmsShopContent={cmsShopContent}
                    setShowPickSubcategoryText={setShowPickSubcategoryText}
                  />
                </div>
              </div>

              <div className="sm:max-w-1/2 flex flex-col items-center sm:mx-5 lg:mx-14">
                {/* mobile */}
                <div className="block sm:hidden ml-auto">
                  {showPickSubcategoryText ? (
                    <div>{cmsShopContent.productChoiceStep.pickSubcategoryDescriptionText}</div>
                  ) : (
                    productChoiceList
                  )}
                </div>
                {/* non mobile */}
                <div className="hidden sm:flex flex-col">{productChoiceList}</div>
              </div>

              <div className="w-1/4 max-w-[600px] hidden sm:block">
                <SidebarTip
                  isSecondState={selectedProducts.length > 0}
                  tipTitleBefore={
                    subcategory?.helpTextTitle ||
                    category.helpTextTitle ||
                    cmsShopContent.productChoiceStep.tipTitleBefore
                  }
                  tipTitleAfter={cmsShopContent.productChoiceStep.tipTitleAfter}
                  tipContentBefore={
                    subcategory?.helpTextDescription ||
                    category.helpTextDescription ||
                    cmsShopContent.productChoiceStep.tipContentBefore
                  }
                  tipContentAfter={cmsShopContent.productChoiceStep.tipContentAfter}
                />
              </div>
            </div>
          }
          bottomBar={
            <DefaultBottomBar
              continueButtonDisabled={!isValid}
              onClick={submitForm}
              cmsShopContent={cmsShopContent}
              footerContent={cmsShopContent.productChoiceStep.footer}
            />
          }
        />
      )}
    </Formik>
  );
};
