import { FC } from 'react';
import { Info } from 'phosphor-react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface TooltipHelperProps {
  text?: string;
}

export const TooltipHelper: FC<TooltipHelperProps> = ({ text }) => {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      enterTouchDelay={0}
      leaveTouchDelay={10000}
      onClick={(e) => e.stopPropagation()}
      classes={{ popper: className }}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#707075',
      border: '1px solid #B4C5B480',
      padding: '12px',
      text: '#FAFAFF',
      fontSize: '12px',
      color: '#FAFAFF',
      borderRadius: '8px',
      maxWidth: '250px',
      maxHeight: '250px',
      overflowY: 'auto',
    },
  });

  return (
    <CustomTooltip title={text} placement="top">
      <Info size={24} className="text-sami-green hover:text-opacity-50 hover:cursor-pointer" />
    </CustomTooltip>
  );
};
