import { FC } from 'react';
import { ShopContent } from '../../../types/cms_shared_types';
import { DefaultBottomBar } from './DefaultBottomBar';

interface SignBottomBarProps {
  continueButtonDisabled: boolean;
  onClick: () => void;
  cmsShopContent: ShopContent;
  loading?: boolean;
}

export const SignBottomBar: FC<SignBottomBarProps> = ({ continueButtonDisabled, onClick, cmsShopContent, loading }) => {
  return (
    <DefaultBottomBar
      cmsShopContent={cmsShopContent}
      text={cmsShopContent.contactStep.signButton}
      continueButtonDisabled={continueButtonDisabled}
      onClick={onClick}
      iconRight={<></>}
      footerContent={cmsShopContent.contactStep.footer}
      loading={loading}
    />
  );
};
