import { FC, useEffect, useRef } from 'react';
import { ArrowRight } from 'phosphor-react';
import { BrevFranOssContent } from '../../types/cms_shared_types';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Common/Button';
import { SELECTED_CATEGORY_KEY } from '../Webshop/WebshopForm';

interface MusikavtalRedirectProps {
  scrollToBlockMode?: ScrollLogicalPosition;
  cmsBrevFranOssContent: BrevFranOssContent;
  referenceNumber: string;
  onRedirect?: () => void;
}

export const MusikavtalRedirect: FC<MusikavtalRedirectProps> = ({
  scrollToBlockMode,
  cmsBrevFranOssContent,
  referenceNumber,
  onRedirect,
}) => {
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const submit = async (): Promise<void> => {
    if (onRedirect) onRedirect();
    localStorage.removeItem(SELECTED_CATEGORY_KEY);
    navigate('/?referenceNumber=' + referenceNumber);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: scrollToBlockMode ?? 'center' });
  }, [scrollToBlockMode]);

  return (
    <div ref={scrollRef} className="mb-10">
      <div className="mb-6 text-text-dark text-base font-normal">{cmsBrevFranOssContent.createContractInformation}</div>

      <Button
        onClick={submit}
        text={cmsBrevFranOssContent.createContractText}
        rightIcon={<ArrowRight size={20} />}
        filled
      />

      <div className="w-12 sm:w-fit"></div>
    </div>
  );
};
