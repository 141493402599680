import { FC } from 'react';
import { CaretDown } from 'phosphor-react';
import { motion } from 'framer-motion';
import { CmsProduct, ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { TooltipHelper } from '../TooltipHelper';
import { caretTransitionVariants } from '../ShoppingCart/ShoppingProduct';

interface ExtraProductDrawerProps {
  showExtraProductsDrawer: boolean;
  setShowExtraProductsDrawer: (value: boolean) => void;
  productsInExtra: CmsProduct[];
  displayProductsFunc: (products: CmsProduct[]) => JSX.Element;
  cmsShopContent: ShopContent;
}

export const ExtraProductDrawer: FC<ExtraProductDrawerProps> = ({
  showExtraProductsDrawer,
  setShowExtraProductsDrawer,
  productsInExtra,
  displayProductsFunc,
  cmsShopContent,
}) => {
  const displayText = () => {
    if (productsInExtra.length === 1) {
      return showExtraProductsDrawer
        ? cmsShopContent.productChoiceStep.extraProductOpened
        : cmsShopContent.productChoiceStep.extraProductClosed;
    } else {
      return showExtraProductsDrawer
        ? cmsShopContent.productChoiceStep.extraProductsOpened.replace('{x}', productsInExtra.length.toString())
        : cmsShopContent.productChoiceStep.extraProductsClosed.replace('{x}', productsInExtra.length.toString());
    }
  };

  return (
    <div className="w-full flex flex-col mt-2 mr-auto pb-3 border-input-border">
      <Conditional condition={showExtraProductsDrawer} trueRender={<>{displayProductsFunc(productsInExtra)}</>} />

      <div
        onClick={() => setShowExtraProductsDrawer(!showExtraProductsDrawer)}
        className="flex flex-row py-1 items-center cursor-pointer"
      >
        <div className="p-1 mr-1 text-sm text-sami-green font-bold">{displayText()}</div>
        <TooltipHelper text={cmsShopContent.productChoiceStep.extraProductsTooltip} />

        <motion.div
          className="ml-auto text-icon"
          variants={caretTransitionVariants}
          animate={showExtraProductsDrawer ? 'open' : 'closed'}
        >
          <CaretDown size={22} />
        </motion.div>
      </div>
    </div>
  );
};
