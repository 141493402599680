import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { RichText } from '../../types/cms_shared_types';
import { Conditional } from '../Common/Conditional';

interface SidebarTipProps {
  isSecondState: boolean;
  tipTitleBefore?: string;
  tipTitleAfter?: string;
  tipContentBefore?: RichText;
  tipContentAfter?: RichText;
}

export const SidebarTip: React.FC<SidebarTipProps> = ({
  isSecondState,
  tipTitleBefore,
  tipTitleAfter,
  tipContentBefore,
  tipContentAfter,
}) => {
  return (
    <div className="max-h-36 mr-12 text-text-dark text-left">
      <div className={`text-xl font-bold`}>
        <Conditional condition={isSecondState} falseRender={<>{tipTitleBefore}</>} trueRender={<>{tipTitleAfter}</>} />
      </div>
      <div className="mt-3 text-base font-medium">
        <Conditional
          condition={isSecondState}
          falseRender={render(tipContentBefore)}
          trueRender={render(tipContentAfter)}
        />
      </div>
    </div>
  );
};
