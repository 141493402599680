import * as Sentry from '@sentry/react';

export const setupSentry = () => {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT;
  const release = process.env.REACT_APP_RELEASE;

  if (dsn && environment) {
    Sentry.init({
      dsn: dsn,
      environment: environment,
      release: release,
    });
  }
};
