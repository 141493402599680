import React, { FC, useState } from 'react';
import { ShoppingCart } from 'phosphor-react';
import { Modal } from '../Modal';
import { Button } from '../../Common/Button';
import { ShoppingList } from './ShoppingList';
import { ShopContent } from '../../../types/cms_shared_types';
import { Product, ProductListActions } from '../../../types/webshop';
import { TotalPrice } from './TotalPrice';

interface ShoppingCartModalProps {
  showShoppingCartModal: boolean;
  setShowShoppingCartModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  price: string;
  vatPrice: string;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  cmsShopContent: ShopContent;
}

export const ShoppingCartModal: FC<ShoppingCartModalProps> = ({
  showShoppingCartModal,
  setShowShoppingCartModal,
  selectedCategoryKey,
  selectedSubcategoryKey,
  price,
  vatPrice,
  selectedProducts,
  selectedProductsActions,
  cmsShopContent,
}) => {
  const [totalPriceFetching, setTotalPriceFetching] = useState(false);

  return (
    <Modal
      showModal={showShoppingCartModal}
      setShowModal={setShowShoppingCartModal}
      header={
        <div className="flex flex-row items-center">
          <ShoppingCart size={32} className="hidden sm:block ml-1 mr-4 text-sami-green" />
          {cmsShopContent.shoppingCart.shoppingCartModal.title}
        </div>
      }
      content={
        <ShoppingList
          selectedProducts={selectedProducts}
          selectedProductsActions={selectedProductsActions}
          setTotalPriceFetching={setTotalPriceFetching}
          selectedCategoryKey={selectedCategoryKey}
          selectedSubcategoryKey={selectedSubcategoryKey}
          cmsShopContent={cmsShopContent}
        />
      }
      actions={
        <div className="mx-auto w-fit">
          <Button
            text={cmsShopContent.shoppingCart.shoppingCartModal.closeButton}
            onClick={() => setShowShoppingCartModal(false)}
          />
        </div>
      }
      bottomStaticInfo={
        <TotalPrice
          price={price}
          vatPrice={vatPrice}
          totalPriceFetching={totalPriceFetching}
          cmsShopContent={cmsShopContent}
        />
      }
    />
  );
};
