import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const WebshopWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const newPath = location.pathname.split('/webshop')[1];
    navigate(newPath);
  }, [location, navigate]);

  return <></>;
};
