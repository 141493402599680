import React from 'react';
import { RadioButton } from '../../Common/RadioButton';

interface RadioItemProps {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}

export const RadioItem: React.FC<RadioItemProps> = ({ name, isSelected, onClick }) => {
  return (
    <div onClick={onClick} className="flex flex-row items-center cursor-pointer">
      <RadioButton selected={isSelected} />
      <div className={`ml-4 text-sm text-text-dark ${isSelected ? 'font-bold' : 'font-normal'}`}>{name}</div>
    </div>
  );
};
