import { FC, useRef, useState } from 'react';
import { ArrowRight } from 'phosphor-react';
import { NODE_UL, render } from 'storyblok-rich-text-react-renderer';
import { Footer, ShopContent } from '../../../types/cms_shared_types';
import { Button } from '../../Common/Button';
import { HelpButton } from './HelpButton';
import { Conditional } from '../../Common/Conditional';
import { LoadingSpinner } from '../../Common/LoadingSpinner';

interface DefaultBottomBarProps {
  text?: string;
  continueButtonDisabled: boolean;
  onClick: () => void;
  blackButton?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  cmsShopContent: ShopContent;
  footerContent?: Footer;
  loading?: boolean;
}

export const DefaultBottomBar: FC<DefaultBottomBarProps> = ({
  text,
  continueButtonDisabled,
  onClick,
  blackButton,
  iconLeft,
  iconRight = <ArrowRight size={18} />,
  cmsShopContent,
  footerContent,
  loading,
}) => {
  const scrollTo = useRef<HTMLInputElement>(null);
  const [showFooter, setShowFooter] = useState(false);

  const executeScroll = () => scrollTo?.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' });

  const displayMedia = () => {
    return footerContent?.mediaUrl ? (
      <>
        <Conditional
          condition={footerContent?.mediaUrl.endsWith('.mp4') ?? false}
          trueRender={
            <video id={'footerVideo'} autoPlay playsInline loop className="w-full">
              <source src={footerContent?.mediaUrl} type="video/mp4" />
            </video>
          }
          falseRender={<img src={footerContent?.mediaUrl}></img>}
        />
        <div className="mb-10 mt-4">{render(footerContent?.mediaFooter)}</div>
      </>
    ) : null;
  };

  return (
    <div className={`${footerContent?.displayFooter ? 'sticky' : ''} fixed bottom-0 inset-x-0`}>
      <div className="h-20 border border-input-gray p-6 bg-input-gray flex flex-row items-center">
        <div className="w-1/3 sm:w-1/5 mr-auto">
          <Conditional
            condition={Boolean(footerContent?.displayFooter)}
            trueRender={
              <HelpButton
                text={cmsShopContent.helpButton}
                onClick={() => {
                  setShowFooter(true);
                  // Unfortunately we have (?) to use this hack, otherwise 'showFooter' is
                  // not yet set to true (it takes time when using 'useState').
                  // So if we run 'executeScroll' immediately after, no scrollRef will be found
                  // and therefore no scrolling will happen.
                  // 100 ms seems to be enough for the change to register, while also being good UX.
                  setTimeout(() => executeScroll(), 100);
                }}
              />
            }
          />
        </div>

        <div className="grow mx-auto flex flex-col items-center pl-2">
          <Button
            filled
            large
            black={blackButton}
            text={text ?? cmsShopContent.continueButton}
            leftIcon={iconLeft}
            rightIcon={loading ? <LoadingSpinner size={'1em'} /> : iconRight}
            disabled={continueButtonDisabled || loading}
            onClick={onClick}
          />
        </div>

        <div className="w-1/3 sm:w-1/5 ml-auto" />
      </div>

      <Conditional
        condition={showFooter}
        trueRender={
          <div
            ref={scrollTo}
            className={`${
              footerContent?.displayFooter ? 'sm:flex' : ''
            } justify-evenly  sm:p-6 bg-sami-dark text-input-gray absolute`}
          >
            <div className="sm:hidden block mx-mobile-side my-10">{displayMedia()}</div>

            <div className="sm:w-5/12 sm:mx-10 mx-mobile-side my-10 sm:my-20 h-auto">
              {render(footerContent?.leftColumn)}
            </div>
            <div className="sm:w-5/12 sm:mx-10 mx-mobile-side my-10 sm:my-20 h-auto">
              <div className="sm:block hidden">{displayMedia()}</div>

              {render(footerContent?.rightColumn, {
                nodeResolvers: {
                  [NODE_UL]: (children) => <ul className="list-disc">{children}</ul>,
                },
              })}
            </div>
          </div>
        }
      />
    </div>
  );
};
