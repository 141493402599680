import { FC } from 'react';
import { findUserInputResourceByKey, findUserSelectInputResourceByKey } from '../../../helper/cms';
import { ShopContent } from '../../../types/cms_shared_types';
import { Step } from '../Step';
import { Formik } from 'formik';
import { SidebarTip } from '../SidebarTip';
import { Conditional } from '../../Common/Conditional';
import * as Yup from 'yup';
import { InputMode, UserInput } from '../../Common/UserInput';
import { UserSelectInput } from '../../Common/UserSelectInput';
import { DefaultBottomBar } from '../BottomBar/DefaultBottomBar';
import { LETTERS_ONLY_REGEX, SWEDISH_POST_CODE_REGEX } from '../../../helper/helper';
import { InformationFormInputs } from '../../../types/api_shared_types';
import { Validations, validateCharacters } from '../../../helper/validation';

interface InformationProps {
  informationFormInputs: InformationFormInputs;
  setInformationFormInputs: (inputs: InformationFormInputs) => void;
  hasSpecialShoppingProduct?: boolean;
  nextButtonClicked: () => void;
  cmsShopContent: ShopContent;
}

export const Information: FC<InformationProps> = ({
  informationFormInputs,
  setInformationFormInputs,
  hasSpecialShoppingProduct = false,
  nextButtonClicked,
  cmsShopContent,
}) => {
  const businessNameInput = findUserInputResourceByKey(
    'business_name_input',
    cmsShopContent.informationStep.userInputs,
  );
  const businessAddressInput = findUserInputResourceByKey(
    'business_address_input',
    cmsShopContent.informationStep.userInputs,
  );
  const businessPostNumberInput = findUserInputResourceByKey(
    'business_post_number_input',
    cmsShopContent.informationStep.userInputs,
  );
  const businessCityInput = findUserInputResourceByKey(
    'business_city_input',
    cmsShopContent.informationStep.userInputs,
  );

  const shoppingCategoryInput = findUserSelectInputResourceByKey(
    'shopping_category_input',
    cmsShopContent.informationStep.userSelectInputs,
  );

  const validationSchema = Yup.object().shape(
    {
      businessNameInput: Yup.string().required(cmsShopContent.inputRequiredError),
      businessCategoryInput: Yup.string().when('businessCategoryInput', () => {
        if (hasSpecialShoppingProduct) {
          return Yup.string().required(cmsShopContent.inputRequiredError);
        } else {
          return Yup.string().notRequired();
        }
      }),
      businessAddressInput: Yup.string().required(cmsShopContent.inputRequiredError),
      businessPostNumberInput: Yup.string()
        .matches(SWEDISH_POST_CODE_REGEX, cmsShopContent.invalidPostCodeError)
        .required(cmsShopContent.inputRequiredError),
      businessCityInput: Yup.string()
        .matches(LETTERS_ONLY_REGEX, cmsShopContent.invalidCityError)
        .required(cmsShopContent.inputRequiredError),
    },
    [['businessCategoryInput', 'businessCategoryInput']], // cyclic dependency (see https://stackoverflow.com/a/72402785)
  );

  const validations: Validations<string> = [
    { validate: validateCharacters, errorMessage: cmsShopContent.invalidCharacterError },
  ];

  return (
    <Formik
      onSubmit={(values) => {
        setInformationFormInputs(values);
        nextButtonClicked();
      }}
      initialValues={{
        ...informationFormInputs,
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, submitForm }) => (
        <Step
          fullWidth
          title={cmsShopContent.informationStep.title}
          subtitle={cmsShopContent.informationStep.subtitle}
          content={
            <div className="flex flex-row place-content-center">
              <div className="hidden w-1/4 sm:flex flex-row-reverse" />

              <div className="sm:max-w-[600px] flex grow flex-col items-center sm:mx-14">
                <UserInput
                  name="businessNameInput"
                  prompt={businessNameInput?.prompt}
                  placeholder={businessNameInput?.placeholder}
                  validations={validations}
                />

                <Conditional
                  condition={hasSpecialShoppingProduct}
                  trueRender={
                    <UserSelectInput
                      name="businessCategoryInput"
                      prompt={shoppingCategoryInput?.prompt}
                      placeholder={shoppingCategoryInput?.placeholder}
                      options={shoppingCategoryInput?.options}
                    />
                  }
                />

                <UserInput
                  name="businessAddressInput"
                  prompt={businessAddressInput?.prompt}
                  placeholder={businessAddressInput?.placeholder}
                  validations={validations}
                />

                <div className="w-full flex flex-col sm:flex-row gap-x-4">
                  <UserInput
                    name="businessPostNumberInput"
                    prompt={businessPostNumberInput?.prompt}
                    placeholder={businessPostNumberInput?.placeholder}
                    inputMode={InputMode.Number}
                  />

                  <UserInput
                    name="businessCityInput"
                    prompt={businessCityInput?.prompt}
                    placeholder={businessCityInput?.placeholder}
                    validations={validations}
                  />
                </div>
              </div>

              <div className="max-w-[600px] hidden sm:block w-1/4 mt-6">
                <SidebarTip
                  isSecondState={isValid}
                  tipTitleBefore={cmsShopContent.informationStep.tipTitleBefore}
                  tipTitleAfter={cmsShopContent.informationStep.tipTitleAfter}
                  tipContentBefore={cmsShopContent.informationStep.tipContentBefore}
                  tipContentAfter={cmsShopContent.informationStep.tipContentAfter}
                />
              </div>
            </div>
          }
          bottomBar={
            <DefaultBottomBar
              continueButtonDisabled={!isValid}
              onClick={submitForm}
              cmsShopContent={cmsShopContent}
              footerContent={cmsShopContent.informationStep.footer}
            />
          }
        />
      )}
    </Formik>
  );
};
