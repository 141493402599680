import { FC } from 'react';
import { InputMode, UserInput } from '../../../Common/UserInput';
import { ProductInputTypeOption } from '../../../../types/cms_shared_types';
import { UserSelectInput } from '../../../Common/UserSelectInput';
import { isInvalidDropdownType } from '../../../../helper/cms';
import { Conditional } from '../../../Common/Conditional';
import { useFormikContext } from 'formik';
import { ProductConfigurationModalFormInputs } from './ProductConfigurationModal';

export enum InputType {
  NUMBER = 'number',
  DROPDOWN = 'dropdown',
}

export interface InputParams {
  key: string;
  type: string;
  min?: number;
  max?: number;
  prompt: string;
  placeholder: string;
  options: ProductInputTypeOption[];
  withCustomLastOption: boolean;
  customOptionSelectedFieldKey: string;
  customOptionNameFieldKey: string;
  lastOptionName: string;
  lastOptionPlaceholder: string;
  lastOptionMin: number;
  lastOptionMax: number;
}

interface ProductInputProps {
  code: string;
  inputParams: InputParams;
  type: 'Measure' | 'TimeMeasure';
}

export const ProductInput: FC<ProductInputProps> = ({ code, inputParams, type }) => {
  const { values } = useFormikContext<ProductConfigurationModalFormInputs>();

  const render = () => {
    if (isInvalidDropdownType(inputParams.type, inputParams.options)) {
      // eslint-disable-next-line no-console
      console.warn(
        `The '${inputParams.key}' for product '${code}' has input type '${InputType.DROPDOWN}' but has no options. Go on Storyblok and add some options. In the meantime, we are handling this field as a '${InputType.NUMBER}' type.`,
      );
      inputParams.type = InputType.NUMBER;
    }

    if (inputParams.type === InputType.NUMBER) {
      return (
        <UserInput
          name={inputParams.key}
          prompt={inputParams.prompt}
          placeholder={inputParams.placeholder}
          inputMode={InputMode.Number}
        />
      );
    } else if (inputParams.type === InputType.DROPDOWN && inputParams.withCustomLastOption) {
      const allOptions = [
        ...inputParams.options,
        { displayValue: inputParams.lastOptionName, salesforceValue: inputParams.lastOptionName },
      ];

      return (
        <>
          <UserSelectInput
            name={inputParams.key}
            prompt={inputParams.prompt}
            placeholder={inputParams.placeholder}
            options={allOptions.map((o) => {
              return {
                key: o.salesforceValue.toString(),
                displayValue: o.displayValue,
                backendValue: o.salesforceValue.toString(),
              };
            })}
            customInputSelected={values[`custom${type}InputSelected`]}
            customInputSelectedFieldName={inputParams.customOptionSelectedFieldKey}
            lastOptionName={inputParams.lastOptionName}
          />

          <Conditional
            condition={values[`custom${type}InputSelected`]}
            trueRender={
              <div className="relative top-[-10px]">
                <UserInput
                  name={inputParams.customOptionNameFieldKey}
                  placeholder={inputParams.lastOptionPlaceholder}
                  inputMode={InputMode.Number}
                />
              </div>
            }
          />
        </>
      );
    } else if (inputParams.type === InputType.DROPDOWN) {
      return (
        <UserSelectInput
          name={inputParams.key}
          prompt={inputParams.prompt}
          placeholder={inputParams.placeholder}
          options={inputParams.options.map((o) => {
            return {
              key: o.salesforceValue.toString(),
              displayValue: o.displayValue,
              backendValue: o.salesforceValue.toString(),
            };
          })}
        />
      );
    }
  };

  return <div>{render()}</div>;
};
