import { FC } from 'react';

export enum AnswerState {
  NONE,
  YES,
  NO,
}

interface QuestionButtonProps {
  text: string;
  answerState: AnswerState;
  buttonType: string;
  onClick: () => void;
  disabled?: boolean;
}

export const QuestionButton: FC<QuestionButtonProps> = ({ text, answerState, buttonType, onClick, disabled }) => {
  const handleClick = (): void => {
    if (!disabled) {
      onClick();
    }
  };

  const customStyling = () => {
    if (disabled) {
      return 'text-white bg-lightest hover:text-opacity-40 cursor-not-allowed';
    }
    if (buttonType === 'yes' && answerState === AnswerState.YES) {
      return 'border-sami-green bg-sami-green text-white hover:bg-sami-green-dark hover:text-lightest';
    }
    if (buttonType === 'yes' && answerState !== AnswerState.YES) {
      return 'text-text-gray hover:bg-sami-green hover:bg-opacity-10 hover:text-black';
    }
    if (buttonType === 'no' && answerState === AnswerState.NO) {
      return 'border-black bg-black text-white hover:bg-opacity-70 hover:text-lightest';
    }
    if (buttonType === 'no' && answerState !== AnswerState.NO) {
      return 'text-text-gray hover:bg-lightest hover:bg-opacity-10 hover:text-black';
    }
    return '';
  };

  return (
    <button
      type="button"
      className={`rounded-lg mr-8 text-lg font-bold py-2 px-6 border focus:outline-none ${customStyling()}`}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};
