export const FontPreloader = () => {
  return (
    <>
      <link
        rel="preload"
        as="font"
        href={'/static/media/montserrat-latin-400-normal.acb6629fe45c43ad5d8b.woff2'}
        type="font/woff2"
      />
      <link
        rel="preload"
        as="font"
        href={'/static/media/montserrat-latin-500-normal.acb6629fe45c43ad5d8b.woff2'}
        type="font/woff2"
      />
      <link
        rel="preload"
        as="font"
        href={'/static/media/montserrat-latin-700-normal.acb6629fe45c43ad5d8b.woff2'}
        type="font/woff2"
      />
      <link
        rel="preload"
        as="font"
        href={'/static/media/montserrat-latin-800-normal.acb6629fe45c43ad5d8b.woff2'}
        type="font/woff2"
      />
    </>
  );
};
