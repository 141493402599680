import { FC } from 'react';
import { Conditional } from './Conditional';

interface ButtonProps {
  text?: string;
  textColor?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  filled?: boolean;
  large?: boolean;
  black?: boolean;
  disabled?: boolean;
  hover?: boolean;
  onClick: () => void;
}

export const Button: FC<ButtonProps> = ({
  text,
  textColor,
  leftIcon,
  rightIcon,
  filled = false,
  large = false,
  black = false,
  disabled = false,
  hover = false,
  onClick,
}) => {
  const getTextStyling = () => {
    return `font-bold ${textColor ? textColor : black ? 'text-dark' : filled ? 'text-white' : 'text-sami-green'} ${
      large ? 'text-lg' : 'text-sm'
    }`;
  };

  const getBackgroundStyling = () => {
    if (disabled) {
      return 'bg-lightest';
    } else if (filled && black) {
      return 'bg-white';
    } else if (filled) {
      return 'bg-sami-green';
    } else {
      return '';
    }
  };

  const getHoverStyling = () => {
    if (hover) {
      return 'hover:bg-black hover:bg-opacity-5';
    }
  };

  const getDisableStyling = () => {
    return `${disabled ? 'bg-lightest hover:text-opacity-40 cursor-not-allowed' : ''}`;
  };

  const getBorderStyling = () => {
    return `${black ? 'border border-text-dark' : ''}`;
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`flex flex-row items-center min-w-[90px] justify-center ${
        large ? 'px-7' : ' px-5'
      } py-3 rounded-full outline-none ${getBackgroundStyling()} ${getTextStyling()} ${getDisableStyling()} ${getBorderStyling()} ${getHoverStyling()} `}
    >
      <Conditional condition={leftIcon !== undefined} trueRender={<div className="mr-2">{leftIcon}</div>} />
      <div className="truncate ">{text}</div>
      <Conditional
        condition={rightIcon !== undefined}
        trueRender={<div className="ml-2 flex items-center">{rightIcon}</div>}
      />
    </button>
  );
};
