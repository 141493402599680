import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FontPreloader } from './components/Common/FontPreloader';
import { LanguageWrapper } from './LanguageWrapper';
import { BrevFranOssPage } from './pages/BrevFranOssPage';
import { Error404Page } from './pages/Error404Page';
import { ErrorDefaultPage } from './pages/ErrorDefaultPage';
import { WebshopPage } from './pages/WebshopPage';
import { WebshopWrapper } from './WebshopWrapper';

function App() {
  return (
    <div>
      <FontPreloader />
      <BrowserRouter>
        <Routes>
          <Route path="/webshop/*" element={<WebshopWrapper />} />
          <Route path="/en/*" element={<LanguageWrapper />} />

          <Route path="/brev-fran-oss" element={<BrevFranOssPage />} />
          <Route path="/shop" element={<WebshopPage />} />

          <Route path="/error" element={<ErrorDefaultPage />} />
          <Route path="/" element={<WebshopPage />} />
          <Route path="*" element={<Error404Page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
