import { FC, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Category, ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { TooltipHelper } from '../TooltipHelper';
import { ProductChoiceFormInput } from './ProductChoice';
import { SubcategoryItem } from './SubcategoryItem';
import { SubcategoryPickerMobile } from './SubcategoryPickerMobile';
import { Product, ProductListActions } from '../../../types/webshop';

interface SubcategoryPickerProps {
  category: Category;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  cmsShopContent: ShopContent;
  setShowPickSubcategoryText: (value: boolean) => void;
}

export const SubcategoryPicker: FC<SubcategoryPickerProps> = ({
  category,
  selectedProducts,
  selectedProductsActions,
  cmsShopContent,
  setShowPickSubcategoryText,
}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [potentialNewSubcategoryKey, setPotentialNewSubcategoryKey] = useState('');
  const { values, setFieldValue } = useFormikContext<ProductChoiceFormInput>();

  useEffect(() => {
    if (values.selectedSubcategoryKey) {
      return;
    }

    if (category.subcategories.length > 0) {
      setFieldValue('selectedSubcategoryKey', category?.subcategories[0].key);
    } else {
      throw new Error(`Category '${category.name}' has no associated subcategory`);
    }
  }, [category.name, category.subcategories, values.selectedSubcategoryKey, setFieldValue]);

  const switchSubcategories = (newSelectedSubcategoryKey: string) => {
    if (selectedProducts.length > 0 && values.selectedSubcategoryKey !== newSelectedSubcategoryKey) {
      setPotentialNewSubcategoryKey(newSelectedSubcategoryKey);
      setShowConfirmationDialog(true);
      return;
    }
    setFieldValue('selectedSubcategoryKey', newSelectedSubcategoryKey);
  };

  return (
    <div className="flex flex-row sm:flex-col mb-4 sm:mb-0 sm:mx-0">
      <div className="flex sm:flex-row sm:items-center my-auto">
        <div className="mr-2 text-base text-text-dark font-extrabold">
          {cmsShopContent.productChoiceStep.categoryHeader}
        </div>
        <TooltipHelper text={cmsShopContent.productChoiceStep.subcategoryTooltip} />
      </div>

      <div className="block sm:hidden ml-auto">
        <SubcategoryPickerMobile
          subcategories={category?.subcategories}
          selectedSubcategoryKey={values.selectedSubcategoryKey}
          cmsShopContent={cmsShopContent}
          switchSubcategories={switchSubcategories}
          setShowPickSubcategoryText={setShowPickSubcategoryText}
        />
      </div>

      <div className="hidden sm:flex flex-col">
        {category?.subcategories?.map((subcategory, index) => {
          return (
            <SubcategoryItem
              key={index}
              name={subcategory.name}
              isSelected={values.selectedSubcategoryKey === subcategory.key}
              onClick={() => switchSubcategories(subcategory.key)}
            />
          );
        })}
      </div>

      <Conditional
        condition={showConfirmationDialog}
        trueRender={
          <ConfirmationDialog
            showModal={true}
            setShowModal={setShowConfirmationDialog}
            onYesCallback={() => {
              selectedProductsActions.reset();
              setFieldValue('selectedSubcategoryKey', potentialNewSubcategoryKey);
            }}
            title={cmsShopContent.confirmationDialog.titleWhenSwitchingSubcategory}
            description={cmsShopContent.confirmationDialog.descriptionWhenSwitchingSubcategory}
            cmsShopContent={cmsShopContent}
          />
        }
      />
    </div>
  );
};
