import { FC } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { render } from 'storyblok-rich-text-react-renderer';
import { CmsProduct, ShopContent } from '../../../types/cms_shared_types';
import { Step } from '../Step';
import {
  displayProductInformation,
  retrieveCmsProduct,
  findSelectedCategoryByKey,
  findSelectedSubcategoryByKey,
  displaySchablonProductInformation,
} from '../../../helper/cms';
import { ContactInformation } from './ContactInformation';
import { Conditional } from '../../Common/Conditional';
import { LineDelimiter } from '../../Common/LineDelimiter';
import { Checkbox } from '../../Common/Checkbox';
import { UserBooleanInput } from '../../Common/UserBooleanInput';
import { SignBottomBar } from '../BottomBar/SignBottomBar';
import { ContactFormInputs, InformationFormInputs, InvoiceFormInputs, ShopForm } from '../../../types/api_shared_types';
import { Product } from '../../../types/webshop';
import { parseAndFormatPhoneNumber, validatePhoneNumber } from '../../../helper/phone';
import { EMAIL_REGEX, REFERENCE_NUMBER_REGEX } from '../../../helper/helper';
import { validateCharacters } from '../../../helper/validation';

interface ContactProps {
  selectedProducts: Product[];
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  comments?: string;
  informationFormInputs: InformationFormInputs;
  invoiceFormInputs: InvoiceFormInputs;
  contactFormInputs: ContactFormInputs;
  setContactFormInputs: (inputs: ContactFormInputs) => void;
  startOneflowAgreementSigning: (shopForm: ShopForm) => Promise<void>;
  atLeastOneReportingProduct: boolean;
  cmsShopContent: ShopContent;
  referenceNumber: string | null;
}

export const Contact: FC<ContactProps> = ({
  selectedProducts,
  selectedCategoryKey,
  selectedSubcategoryKey,
  comments,
  informationFormInputs,
  invoiceFormInputs,
  contactFormInputs,
  setContactFormInputs,
  startOneflowAgreementSigning,
  atLeastOneReportingProduct,
  cmsShopContent,
  referenceNumber,
}) => {
  const hasExtraProductsSelected = (): boolean => {
    const category = findSelectedCategoryByKey(cmsShopContent.categories, selectedCategoryKey);
    if (category) {
      const subcategory = findSelectedSubcategoryByKey(selectedSubcategoryKey, category.subcategories);
      if (subcategory) {
        const extraProducts = new Set(subcategory.extraProducts.map((p) => p.code));
        return selectedProducts.some((p) => extraProducts.has(p.code));
      }
    }
    return false;
  };

  const getSummary = (prod: Product, product?: CmsProduct): string => {
    if (!product) {
      return `${prod.quantity}, ${prod.timeQuantity}`;
    }

    if (product.isSchablon) {
      return displaySchablonProductInformation(prod, product);
    }

    if (product.reporting) {
      return cmsShopContent.reportingProductSummary;
    }

    return displayProductInformation(product, selectedProducts);
  };

  const validationSchema = Yup.object().shape({
    contactFirstNameInput: Yup.string()
      .required(cmsShopContent.inputRequiredError)
      .test('firstName', cmsShopContent.invalidCharacterError, (v) => validateCharacters(v ?? '')),
    contactLastNameInput: Yup.string()
      .required(cmsShopContent.inputRequiredError)
      .test('lastName', cmsShopContent.invalidCharacterError, (v) => validateCharacters(v ?? '')),
    contactEmailInput: Yup.string()
      .matches(EMAIL_REGEX, cmsShopContent.invalidEmailError)
      .required(cmsShopContent.inputRequiredError),
    contactTelephoneNumberInput: Yup.string()
      .required(cmsShopContent.inputRequiredError)
      .test('phoneNumber', cmsShopContent.invalidPhoneNumberError, validatePhoneNumber),
    sameReportingContactInput: Yup.boolean(),

    reportingFirstNameInput: Yup.string().when('sameReportingContactInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () => Yup.string().required(cmsShopContent.inputRequiredError),
    }),
    reportingLastNameInput: Yup.string().when('sameReportingContactInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () => Yup.string().required(cmsShopContent.inputRequiredError),
    }),
    reportingEmailInput: Yup.string().when('sameReportingContactInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () =>
        Yup.string().matches(EMAIL_REGEX, cmsShopContent.invalidEmailError).required(cmsShopContent.inputRequiredError),
    }),
    reportingTelephoneNumberInput: Yup.string()
      .notRequired()
      .when('sameReportingContactInput', {
        is: false,
        then: (schema) =>
          schema.when('reportingTelephoneNumberInput', {
            is: (value: string) => value?.length,
            then: (schema) => schema.test('phoneNumber', cmsShopContent.invalidPhoneNumberError, validatePhoneNumber),
          }),
      }),

    acceptTermsAndConditions: Yup.boolean().isTrue(),
  });

  return (
    <Formik
      onSubmit={async (values) => {
        const shopForm: ShopForm = {
          products: selectedProducts.map((p) => {
            const product = retrieveCmsProduct(
              p.code,
              cmsShopContent.categories,
              selectedCategoryKey,
              selectedSubcategoryKey,
            );

            return {
              code: p.code,
              quantity: p.schablonQuantity ?? p.quantity,
              timeQuantity: p.schablonTimeQuantity ?? p.timeQuantity,
              musicClass: p.musicClass,
              startDate: p.startDate,
              endDate: p.endDate,
              priceYearInclVat: product?.reporting ? 0 : p.priceYearInclVat,
              priceYearExclVat: product?.reporting ? 0 : p.priceYearExclVat,
              name: product?.name ?? '',
              summary: getSummary(p, product),
              reporting: product?.reporting ?? false,
            };
          }),
          category: selectedCategoryKey ?? '',
          subcategory: selectedSubcategoryKey ?? '',
          comments: comments ?? '',
          information: { ...informationFormInputs },
          invoice: {
            ...invoiceFormInputs,
            organisationNumberInput: invoiceFormInputs.organisationNumberInput.includes('-')
              ? invoiceFormInputs.organisationNumberInput
              : invoiceFormInputs.organisationNumberInput.slice(0, 6) +
                '-' +
                invoiceFormInputs.organisationNumberInput.slice(6),
            startDateInput: dayjs(invoiceFormInputs.startDateInput).format('YYYY-MM-DD'),
            emailAddressPdfInput: invoiceFormInputs.paperInvoiceInput ? '' : invoiceFormInputs.emailAddressPdfInput,
          },
          contacts: { ...values },
          reviewRequired: hasExtraProductsSelected(),
          referenceNumber: REFERENCE_NUMBER_REGEX.test('' + referenceNumber) ? referenceNumber : undefined,
        };

        shopForm.contacts.reportingTelephoneNumberInput = parseAndFormatPhoneNumber(
          shopForm.contacts.reportingTelephoneNumberInput,
        );
        shopForm.contacts.contactTelephoneNumberInput = parseAndFormatPhoneNumber(
          shopForm.contacts.contactTelephoneNumberInput,
        );
        await startOneflowAgreementSigning(shopForm);

        setContactFormInputs(values);
      }}
      initialValues={{
        ...contactFormInputs,
        acceptTermsAndConditions: false,
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, values, setFieldValue, submitForm, isSubmitting }) => (
        <Step
          title={cmsShopContent.contactStep.title}
          subtitle={cmsShopContent.contactStep.subtitle}
          content={
            <div className="flex flex-col">
              <ContactInformation cmsShopContent={cmsShopContent} />

              <Conditional
                condition={atLeastOneReportingProduct}
                trueRender={
                  <UserBooleanInput
                    name="sameReportingContactInput"
                    title={cmsShopContent.contactStep.accountingContactTitle}
                    description={cmsShopContent.contactStep.accountingContactDescription}
                  />
                }
              />

              <LineDelimiter />

              <Conditional
                condition={!values.sameReportingContactInput}
                trueRender={
                  <div>
                    <div className="mb-8 text-xl text-text-dark font-bold">
                      {cmsShopContent.contactStep.accountingContactTitle}
                    </div>
                    <ContactInformation isReportingContact cmsShopContent={cmsShopContent} />
                    <LineDelimiter />
                  </div>
                }
              />

              <div className="mb-8">
                <div className="mb-2 text-sm text-text-dark font-bold">
                  {cmsShopContent.contactStep.agreementConditionsTitle}
                </div>
                <div className="flex flex-row items-center">
                  <Checkbox
                    selected={values.acceptTermsAndConditions}
                    setSelected={(val) => setFieldValue('acceptTermsAndConditions', val)}
                  />
                  <div className="ml-4 text-sm text-text-dark font-medium">
                    {render(cmsShopContent.contactStep.agreementConditionsDescription)}
                  </div>
                </div>
              </div>
            </div>
          }
          bottomBar={
            <SignBottomBar
              continueButtonDisabled={!isValid}
              onClick={submitForm}
              cmsShopContent={cmsShopContent}
              loading={isSubmitting}
            />
          }
        />
      )}
    </Formik>
  );
};
