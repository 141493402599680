import { FC, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { render } from 'storyblok-rich-text-react-renderer';
import { Header } from '../components/Header/Header';
import { apiGetBrevFranOssCmsContent, apiCreateCase, apiCreateCaseWithoutWaiting } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { BrevFranOssContent } from '../types/cms_shared_types';
import { CreateCaseParams } from '../types/api_shared_types';
import { handleError } from '../helper/errors';
import { Formik } from 'formik';
import { getBrevFranOssUserInput } from '../helper/cms';
import * as Yup from 'yup';
import { Questions } from '../components/BrevFranOss/Questions';
import useLocalStorageState from 'use-local-storage-state';
import { DEFAULT_LANGUAGE } from '../components/Header/LanguageDropdown';
import { UserInput } from '../components/Common/UserInput';
import { SubmitStatus } from '../components/BrevFranOss/SubmitButton';
import { AnswerState } from '../components/BrevFranOss/QuestionButton';
import { LandingPage } from '../components/BrevFranOss/LandingPage/LandingPage';
import { parseAndFormatPhoneNumber, validatePhoneNumber } from '../helper/phone';
import { EMAIL_REGEX, REFERENCE_NUMBER_REGEX } from '../helper/helper';
import { Button } from '../components/Common/Button';
import { Conditional } from '../components/Common/Conditional';
import { trackEvent } from '../helper/analytics';

export interface BrevFranOssFormInputs {
  referenceNumber: string;
  name: string;
  email: string;
  phoneNumber: string;
  date: Dayjs | null;
  answers: AnswerState[];
}

interface BrevFranOssPageProps {
  defaultLanguage?: string;
}

const atLeastOneYes = (answers: AnswerState[]) => answers.slice(0, -1).some((a) => a === AnswerState.YES);

export const BrevFranOssPage: FC<BrevFranOssPageProps> = ({ defaultLanguage }) => {
  const navigate = useNavigate();

  const [language, setLanguage] = useLocalStorageState<string>('language', {
    defaultValue: defaultLanguage ?? DEFAULT_LANGUAGE,
  });

  const [cmsBrevFranOssContent, setCmsBrevFranOssContent] = useState<BrevFranOssContent | undefined>();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.NONE);

  const [hasClickedOK, setHasClickedOK] = useState(false);

  const [landingPageValue, setLandingPageValue] = useState('');

  useEffect(() => {
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
    }
  }, [defaultLanguage, setLanguage, navigate]);

  useEffect(() => {
    const fetchCmsContent = async () => {
      try {
        const { data } = await apiGetBrevFranOssCmsContent(language);
        setCmsBrevFranOssContent(data);
      } catch (e) {
        handleError(e, navigate);
      }
    };
    fetchCmsContent();
  }, [language, navigate]);

  const referenceNumber = getBrevFranOssUserInput('reference_number_user_input', cmsBrevFranOssContent);

  const validationSchema = Yup.object().shape({
    referenceNumber: Yup.string()
      .matches(REFERENCE_NUMBER_REGEX, cmsBrevFranOssContent?.invalidReferenceNumberError)
      .required(cmsBrevFranOssContent?.inputRequiredError),

    name: Yup.string().when('answers', {
      is: (answers: AnswerState[]) => atLeastOneYes(answers),
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(cmsBrevFranOssContent?.inputRequiredError),
    }),

    email: Yup.string().when('answers', {
      is: (answers: AnswerState[]) => atLeastOneYes(answers),
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema
          .matches(EMAIL_REGEX, cmsBrevFranOssContent?.invalidEmailError)
          .required(cmsBrevFranOssContent?.inputRequiredError),
    }),

    phoneNumber: Yup.string().when('answers', {
      is: (answers: AnswerState[]) => atLeastOneYes(answers),
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.test('phoneNumber', cmsBrevFranOssContent?.invalidPhoneNumberError ?? '', validatePhoneNumber),
    }),
  });

  const submitForm = async (
    values: BrevFranOssFormInputs,
    waitForAnswer = true,
    ignoreContact = false,
  ): Promise<void> => {
    setSubmitStatus(SubmitStatus.NONE);

    const stateMap = {
      [AnswerState.YES]: true,
      [AnswerState.NO]: false,
      [AnswerState.NONE]: null,
    };

    try {
      const params: CreateCaseParams = {
        referenceNumber: values.referenceNumber,
        name: ignoreContact ? '' : values.name,
        email: ignoreContact ? '' : values.email,
        phoneNumber: ignoreContact ? '' : parseAndFormatPhoneNumber(values.phoneNumber),
        date: ignoreContact ? undefined : values.date?.format('YYYY-MM-DD'),
        questions: values.answers.map((a, idx) => {
          return {
            question: cmsBrevFranOssContent?.questions[idx].question ?? `question_${idx + 1}`,
            answer: stateMap[a],
          };
        }),
      };

      if (waitForAnswer) {
        await apiCreateCase(params);
      } else {
        apiCreateCaseWithoutWaiting(params);
      }
      setSubmitStatus(SubmitStatus.SUCCESS);
      setLandingPageValue(params.questions.every((q) => !q.answer) ? 'N' : '' + params.date);

      trackEvent(
        'SubmittedLetter',
        'Choices:' +
          values.answers
            .filter((answer) => answer !== AnswerState.NONE)
            .map((answer) => (answer === AnswerState.YES ? 'YES' : 'NO'))
            .join('-'),
      );
    } catch (e) {
      setSubmitStatus(SubmitStatus.FAIL);
    }
  };

  if (cmsBrevFranOssContent === undefined) {
    return null;
  }

  if (landingPageValue && submitStatus === SubmitStatus.SUCCESS) {
    return (
      <>
        <div className="min-h-screen">
          <Header title={cmsBrevFranOssContent.header} cmsContent={cmsBrevFranOssContent} />
          <LandingPage
            cmsLandingPage={
              landingPageValue === 'N' ? cmsBrevFranOssContent.landingPageNo : cmsBrevFranOssContent.landingPageYes
            }
            date={landingPageValue !== 'N' ? landingPageValue : undefined}
          ></LandingPage>
        </div>
      </>
    );
  }

  return (
    <Formik
      onSubmit={(values) => submitForm(values, true)}
      initialValues={{
        referenceNumber: '',
        name: '',
        email: '',
        phoneNumber: '',
        date: dayjs(),
        answers: cmsBrevFranOssContent.questions.map(() => AnswerState.NONE),
      }}
      validationSchema={validationSchema}
      validateOnChange
    >
      {({ values, errors }) => (
        <>
          <div className="min-h-screen">
            <Header title={cmsBrevFranOssContent.header} cmsContent={cmsBrevFranOssContent} />

            <div className="sm:w-2/3 sm:max-w-[800px] flex flex-col place-items-center m-auto px-mobile-side">
              <div className="self-start mt-14 mb-4">
                <div className="text-3xl text-text-dark font-extrabold">{cmsBrevFranOssContent?.title}</div>
              </div>

              <div className="pb-6">{render(cmsBrevFranOssContent.description)}</div>

              <div className="flex flex-row w-full mt-8 mb-4 text-xl text-text-dark font-bold">
                {cmsBrevFranOssContent.firstSectionTitle}
              </div>

              <div className="w-full mb-8 text-base text-text-dark font-normal">
                {cmsBrevFranOssContent.firstSectionDescription}
              </div>

              <div className="w-full flex flex-col sm:flex-row justify-content-center">
                <UserInput
                  name="referenceNumber"
                  prompt={referenceNumber?.prompt}
                  placeholder={referenceNumber?.placeholder}
                />
                <div className="mb-8 sm:ml-4 sm:mt-8">
                  <Button
                    filled
                    text={'OK'}
                    onClick={() => setHasClickedOK(true)}
                    disabled={!!errors.referenceNumber || !values.referenceNumber}
                  />
                </div>
              </div>

              <Conditional
                condition={hasClickedOK}
                trueRender={
                  <div className="w-full">
                    <div className="w-full mb-12 text-base text-text-dark font-normal">
                      {cmsBrevFranOssContent.firstSectionSuccessMessage}
                    </div>

                    <Questions
                      submitStatus={submitStatus}
                      setSubmitStatus={setSubmitStatus}
                      sendCreateCaseRequest={(ignoreContact) => submitForm(values, false, ignoreContact)}
                      cmsBrevFranOssContent={cmsBrevFranOssContent}
                      referenceNumber={values.referenceNumber}
                    />
                  </div>
                }
              />

              <div className="pb-16 sm:pb-32" />
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
