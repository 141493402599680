import { FC, useRef, useEffect } from 'react';
import { Button } from '../Common/Button';
import { Conditional } from '../Common/Conditional';
import { AnswerState } from './QuestionButton';

export { AnswerState } from './QuestionButton';

interface QuestionCardProps {
  question: string;
  explanation: string;
  yesLabel: string;
  noLabel: string;
  onYesClick: () => void;
  onNoClick: () => void;
  state: AnswerState;
  scrollToBlockMode?: ScrollLogicalPosition;
  noScrollRef?: boolean;
}

export const QuestionCard: FC<QuestionCardProps> = ({
  question,
  explanation,
  yesLabel,
  noLabel,
  onYesClick,
  onNoClick,
  state,
  scrollToBlockMode,
  noScrollRef,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: scrollToBlockMode ?? 'center' });
  }, [scrollToBlockMode]);

  return (
    <div className="flex flex-col mb-6 sm:mb-8 rounded-lg">
      <div className="mb-4 font-bold text-xl">{question}</div>
      <div className="mb-6 text-text-dark text-base font-normal">{explanation}</div>
      <div ref={noScrollRef ? null : scrollRef} className="flex items-center">
        <Conditional
          condition={state === AnswerState.YES}
          trueRender={<Button filled text={yesLabel} onClick={onYesClick} />}
          falseRender={<Button black text={yesLabel} onClick={onYesClick} />}
        />
        <div className="ml-8">
          <Conditional
            condition={state === AnswerState.NO}
            trueRender={<Button filled text={noLabel} onClick={onNoClick} />}
            falseRender={<Button black text={noLabel} onClick={onNoClick} />}
          />
        </div>
      </div>
    </div>
  );
};
