import { Check } from 'phosphor-react';
import React from 'react';
import { Conditional } from './Conditional';

interface CheckboxProps {
  selected: boolean;
  setSelected?: (value: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ selected, setSelected }) => {
  const getStyling = () => {
    if (selected) {
      return 'bg-sami-green';
    } else {
      return 'bg-input-gray border border-input';
    }
  };

  const changeSelected = () => {
    if (setSelected) {
      setSelected(!selected);
    }
  };

  return (
    <button
      onClick={changeSelected}
      className={`flex items-center place-content-center h-5 w-5 rounded ${getStyling()} cursor-pointer`}
    >
      <Conditional condition={selected} trueRender={<Check weight="bold" size={16} className="text-white" />} />
    </button>
  );
};
