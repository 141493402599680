import { FC } from 'react';
import { BrevFranOssLandingPage } from '../../../types/cms_shared_types';

interface LandingPageProps {
  cmsLandingPage: BrevFranOssLandingPage;
  date?: string;
}

export const LandingPage: FC<LandingPageProps> = ({ cmsLandingPage, date }) => {
  const title = cmsLandingPage.title;
  let text = cmsLandingPage.text;
  const buttonText = cmsLandingPage.buttonText;

  if (date && date.length > 0) {
    const replaceDate = new Date(date);
    text = text.replace(
      '{DATE}',
      replaceDate.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    );
  }

  const onClick = () => {
    window.location.href = 'https://www.sami.se';
  };

  return (
    <div className="w-full mx-1 sm:mt-20 mt-10 mb-10">
      <div className="text-center">
        <div className="sm:mx-80 mx-6 sm:mt-20 mt-10 mb-10">
          <h1 className="text-4xl font-extrabold antialiased">{title}</h1>
          <p className="text-lg sm:mt-16 mt-10">{text}</p>
          <div className="content-center sm:mt-40 mt-20">
            <button
              type="button"
              onClick={onClick}
              className="py-3 px-10 rounded-full font-bold text-m text-white bg-sami-green hover:opacity-50 uppercase"
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
