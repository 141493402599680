import { FC } from 'react';
import { ArrowRight } from 'phosphor-react';
import { Conditional } from '../../Common/Conditional';

interface BreadcrumbStepProps {
  name: string;
  stepNumber: number;
  selected: boolean;
}

export const BreadcrumbStep: FC<BreadcrumbStepProps> = ({ name, stepNumber, selected }) => {
  return (
    <div className="flex flex-row items-center">
      <div className="mx-2">
        <Conditional
          condition={stepNumber > 0}
          trueRender={<ArrowRight size={14} weight="bold" className="text-text-gray" />}
        />
      </div>
      <div className={`text-sm ${selected ? 'text-sami-green font-bold' : 'text-text-gray font-normal'}`}>{name}</div>
    </div>
  );
};
