import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WebshopForm } from '../components/Webshop/WebshopForm';
import useLocalStorageState from 'use-local-storage-state';
import { DEFAULT_LANGUAGE } from '../components/Header/LanguageDropdown';
import { Product, ProductListActions } from '../types/webshop';
import { ShopContent } from '../types/cms_shared_types';
import { apiGetCmsContent } from '../api/api';
import { handleError } from '../helper/errors';
import { REFERENCE_NUMBER_REGEX } from '../helper/helper';

interface WebshopPageProps {
  defaultLanguage?: string;
}

export const WebshopPage: FC<WebshopPageProps> = ({ defaultLanguage }) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [cmsShopContent, setCmsShopContent] = useState<ShopContent | undefined>();

  const [language, setLanguage] = useLocalStorageState<string>('language', {
    defaultValue: defaultLanguage ?? DEFAULT_LANGUAGE,
  });

  useEffect(() => {
    const getCmsContent = async () => {
      try {
        const res = await apiGetCmsContent(language, 'shop');
        setCmsShopContent(res.data as ShopContent);
      } catch (err) {
        handleError(err, navigate);
      }
    };

    getCmsContent();
  }, [language, navigate]);

  useEffect(() => {
    if (defaultLanguage) {
      setLanguage(defaultLanguage);
      navigate('/');
    }
  }, [defaultLanguage, setLanguage, navigate]);

  const [selectedProducts, setSelectedProducts] = useLocalStorageState<Product[]>('selected_products', {
    defaultValue: [],
  });

  const add = (newProduct: Product) => {
    setSelectedProducts([...selectedProducts, newProduct]);
  };

  const replace = (newProduct: Product) => {
    const index = selectedProducts.findIndex((p) => p.code === newProduct.code);
    setSelectedProducts([...selectedProducts.slice(0, index), newProduct, ...selectedProducts.slice(index + 1)]);
  };

  const remove = (index: number) => {
    setSelectedProducts([...selectedProducts.slice(0, index), ...selectedProducts.slice(index + 1)]);
  };

  const reset = () => setSelectedProducts([]);

  const selectedProductsActions: ProductListActions = { add, replace, remove, reset };

  // Handle reference number
  const [referenceNumber, setReferenceNumber] = useState<string | null>(null);
  const paramReferenceNum = searchParams.get('referenceNum') || searchParams.get('referenceNumber');
  useEffect(() => {
    if (paramReferenceNum && REFERENCE_NUMBER_REGEX.test(paramReferenceNum)) {
      setReferenceNumber(paramReferenceNum);
    } else {
      setReferenceNumber(null);
    }
  }, [paramReferenceNum, setReferenceNumber]);

  if (!cmsShopContent) {
    return <></>;
  }

  return (
    <WebshopForm
      selectedProducts={selectedProducts}
      selectedProductsActions={selectedProductsActions}
      cmsShopContent={cmsShopContent}
      referenceNumber={referenceNumber}
    />
  );
};
