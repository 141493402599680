import { FC } from 'react';

interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  onBlur?: () => void;
}

export const Toggle: FC<ToggleProps> = ({ checked, onChange, onBlur }) => {
  return (
    <div className="form-check form-switch ">
      <input
        className="form-check-input appearance-none w-12 -ml-10 rounded-full float-left h-7 align-top bg-no-repeat bg-input-border checked:bg-sami-green focus:outline-none cursor-pointer"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};
