import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { X } from 'phosphor-react';
import { Conditional } from '../Common/Conditional';

interface ModalProps {
  showModal: boolean;
  setShowModal?: (value: boolean) => void;
  header?: JSX.Element;
  description?: JSX.Element;
  actions?: JSX.Element;
  content?: JSX.Element;
  bottomStaticInfo?: JSX.Element;
  small?: boolean;
  outsideClickCloses?: boolean;
}

export const Modal: FC<ModalProps> = ({
  showModal,
  setShowModal,
  header,
  description,
  actions,
  content,
  bottomStaticInfo,
  small,
  outsideClickCloses = true,
}) => {
  const [maxMiddleHeight, setMaxMiddleHeight] = useState(window.innerHeight);

  const determineMiddleModalHeight = () => {
    const PADDING_Y = 20;

    const topModal = document.getElementById('top-modal');
    const middleModal = document.getElementById('middle-modal');
    const bottomModal = document.getElementById('bottom-modal');

    if (!topModal || !middleModal || !bottomModal) {
      return;
    }

    const newMaxMiddleHeight = window.innerHeight - topModal.clientHeight - bottomModal.clientHeight - 2 * PADDING_Y;
    setMaxMiddleHeight(newMaxMiddleHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', determineMiddleModalHeight);
    determineMiddleModalHeight();

    if (showModal) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      window.removeEventListener('resize', determineMiddleModalHeight);
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  const escFunction = useCallback(
    (e: KeyboardEvent) => {
      if (setShowModal && outsideClickCloses && e.key === 'Escape') {
        setShowModal(false);
      }
    },
    [outsideClickCloses, setShowModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const closeModal = (e?: MouseEvent) => {
    if (!outsideClickCloses) {
      return;
    }

    e?.stopPropagation();
    if (setShowModal) {
      setShowModal(false);
    }
  };

  const getBorders = () => {
    return `border-input-border sm:border-transparent`;
  };

  return (
    <Conditional
      condition={showModal}
      trueRender={
        <div
          onClick={closeModal}
          className="justify-center items-center flex fixed inset-0 z-50 backdrop-blur-sm bg-black bg-opacity-40 cursor-default"
        >
          <motion.div
            initial={{ y: '150%' }}
            animate={{ y: 0 }}
            transition={{
              duration: 0.4,
            }}
            onClick={(e) => e.stopPropagation()}
            className={`relative ${
              small ? 'w-11/12 sm:w-[600px]' : 'w-11/12 mx-4 sm:w-2/3 sm:max-w-[800px]'
            } rounded-2xl`}
          >
            <div className="shadow-xl flex flex-col w-full bg-white outline-none focus:outline-none rounded-2xl">
              <div
                id="top-modal"
                className="flex flex-row items-start px-modal-side-mobile sm:px-modal-side-desktop pt-6 sm:pt-10 pb-4 sm:pb-2"
              >
                <div className="sm:w-4/5 text-text-dark text-xl sm:text-2xl font-bold leading-tight">{header}</div>
                <button onClick={closeModal} className="ml-auto text-text-dark">
                  <Conditional condition={outsideClickCloses} trueRender={<X size={32} />} />
                </button>
              </div>

              <div
                id="middle-modal"
                style={{ maxHeight: maxMiddleHeight }}
                className={`flex flex-col px-modal-side-mobile sm:px-modal-side-desktop pt-2 pb-4 ${getBorders()}`}
              >
                <div className="overflow-y-auto flex flex-col">
                  <Conditional
                    condition={description !== undefined}
                    trueRender={
                      <div className="pt-2 sm:pt-0 pb-6 sm:pb-10 text-text-dark text-base font-medium">
                        {description}
                      </div>
                    }
                    falseRender={<div className="pb-2 sm:pb-4" />}
                  />

                  <Conditional
                    condition={content !== undefined}
                    trueRender={<div className="pb-0 sm:pb-4">{content}</div>}
                  />
                </div>

                <Conditional condition={bottomStaticInfo !== undefined} trueRender={<div>{bottomStaticInfo}</div>} />
              </div>

              <div id="bottom-modal" className="px-modal-side-mobile sm:px-modal-side-desktop pt-2 pb-3 sm:pb-10">
                {actions}
              </div>
            </div>
          </motion.div>
        </div>
      }
    />
  );
};
