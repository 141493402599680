import { FC, useEffect, useState } from 'react';
import { Title } from './Title';

interface StepProps {
  title?: string;
  subtitle?: string;
  content: JSX.Element;
  bottomBar: JSX.Element;
  fullWidth?: boolean;
}

const FIXED_ELEMENTS_HEIGHT = 208; // sum of heights: header + breadcrumb + bottomBar = 208px

export const Step: FC<StepProps> = ({ title, subtitle, content, bottomBar, fullWidth = false }) => {
  const [height, setHeight] = useState<number>(window.innerHeight - FIXED_ELEMENTS_HEIGHT);

  const determineHeight = () => setHeight(window.innerHeight - FIXED_ELEMENTS_HEIGHT);

  useEffect(() => {
    window.addEventListener('resize', determineHeight);
    determineHeight();

    return () => window.removeEventListener('resize', determineHeight);
  }, []);

  return (
    <div>
      <div
        style={{ minHeight: height }}
        className={`w-full ${fullWidth ? '' : 'sm:max-w-3xl'} px-mobile-side sm:mx-auto`}
      >
        <Title title={title} subtitle={subtitle} />

        <div className="pb-8 sm:pb-12 mb-20">{content}</div>
      </div>

      {bottomBar}
    </div>
  );
};
