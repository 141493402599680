import { FC } from 'react';
import { ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';

interface ShoppingProductDesktopProps {
  name: string;
  schablonSummary?: string;
  summary: string;
  price: string;
  icon: JSX.Element;
  priceSimulatorHeader?: JSX.Element;
  priceSimulatorContent?: JSX.Element;
  cmsShopContent: ShopContent;
}

export const ShoppingProductDesktop: FC<ShoppingProductDesktopProps> = ({
  name,
  schablonSummary,
  summary,
  price,
  icon,
  priceSimulatorHeader,
  priceSimulatorContent,
  cmsShopContent,
}) => {
  return (
    <div className="flex flex-row items-center mb-4">
      <div className="grow mr-4 pl-6 py-4 rounded-lg bg-white border border-sami-card-border shadow hover:shadow-md">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col pr-4 md:pr-12">
            <div className="text-text-dark leading-tight font-bold text-base">{name}</div>
            <Conditional
              condition={schablonSummary !== undefined}
              trueRender={<div className="mt-2 text-text-dark text-sm">{schablonSummary}</div>}
            />
            <div className="mt-2 text-text-dark text-sm">{summary}</div>
          </div>

          <div className="flex flex-col items-end mx-6 text-end shrink-0">
            <Conditional
              condition={priceSimulatorHeader !== undefined}
              trueRender={<>{priceSimulatorHeader}</>}
              falseRender={
                <div className="text-text-dark font-bold text-xs sm:text-base truncate">
                  {price} {cmsShopContent.priceWithMonthFormat}
                </div>
              }
            />
          </div>
        </div>

        {priceSimulatorContent}
      </div>

      {icon}
    </div>
  );
};
