interface Country {
  sv: string;
  en: string;
}

export const COUNTRIES: Country[] = [
  { sv: 'Belgien', en: 'Belgium' },
  { sv: 'Bulgarien', en: 'Bulgaria' },
  { sv: 'Cypern', en: 'Cyprus' },
  { sv: 'Danmark', en: 'Denmark' },
  { sv: 'Estland', en: 'Estonia' },
  { sv: 'Finland', en: 'Finland' },
  { sv: 'Frankrike', en: 'France' },
  { sv: 'Grekland', en: 'Greece' },
  { sv: 'Irland', en: 'Ireland' },
  { sv: 'Italien', en: 'Italy' },
  { sv: 'Kroatien', en: 'Croatia' },
  { sv: 'Lettland', en: 'Latvia' },
  { sv: 'Litauen', en: 'Lithuania' },
  { sv: 'Luxemburg', en: 'Luxembourg' },
  { sv: 'Malta', en: 'Malta' },
  { sv: 'Marocco', en: 'Morocco' },
  { sv: 'Montenegro', en: 'Montenegro' },
  { sv: 'Nederländerna', en: 'Netherlands' },
  { sv: 'Norge', en: 'Norway' },
  { sv: 'Polen', en: 'Poland' },
  { sv: 'Portugal', en: 'Portugal' },
  { sv: 'Rumänien', en: 'Roumania' },
  { sv: 'Schweiz', en: 'Switzerland' },
  { sv: 'Serbien', en: 'Serbia' },
  { sv: 'Slovakien', en: 'Slovakia' },
  { sv: 'Slovenien', en: 'Slovenia' },
  { sv: 'Spanien', en: 'Spain' },
  { sv: 'Storbritannien', en: 'United Kingdom' },
  { sv: 'Sverige', en: 'Sweden' },
  { sv: 'Tjeckien', en: 'Czechia' },
  { sv: 'Tyskland', en: 'Germany' },
  { sv: 'Ungern', en: 'Hungary' },
  { sv: 'Åland', en: 'Åland' },
  { sv: 'Österrike', en: 'Austria' },
];

export const DEFAULT_COUNTRY_KEY = COUNTRIES.find((c) => c.sv === 'Sverige');
