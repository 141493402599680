import { FC } from 'react';
import { Conditional } from './Conditional';

interface RadioButtonProps {
  selected: boolean;
  setSelected?: (value: boolean) => void;
}

export const RadioButton: FC<RadioButtonProps> = ({ selected, setSelected }) => {
  const changeSelected = () => {
    if (setSelected) {
      setSelected(!selected);
    }
  };

  return (
    <button
      onClick={changeSelected}
      className={`flex items-center place-content-center h-5 w-5 rounded-3xl bg-input-gray border border-input cursor-pointer`}
    >
      <Conditional condition={selected} trueRender={<span className="w-3 h-3 dot rounded-3xl bg-sami-green" />} />
    </button>
  );
};
