import { FC } from 'react';
import { ResourceText } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { RadioItem } from './RadioItem';

interface RadioDialogProps {
  prompt: string;
  options: ResourceText[];
  selectedKey: string;
  setSelectedKey: (value: string) => void;
  isSelected: (selectedKey: string, option: ResourceText) => boolean;
  informationText?: string;
  renderTextInsteadOfRadioItems: boolean;
  renderTextInsteadOfRadioItemsText?: string;
}

export const RadioDialog: FC<RadioDialogProps> = ({
  prompt,
  options,
  selectedKey,
  setSelectedKey,
  isSelected,
  informationText,
  renderTextInsteadOfRadioItems: renderTextInsteadOfRadioButtons,
  renderTextInsteadOfRadioItemsText: renderTextInsteadOfRadioButtonsText,
}) => {
  return (
    <div className="w-full mb-8">
      <div className="flex flex-col mb-2 text-sm font-bold text-text-dark">{prompt}</div>
      <Conditional
        condition={renderTextInsteadOfRadioButtons}
        trueRender={<div className="text-text-dark text-sm font-medium">{renderTextInsteadOfRadioButtonsText}</div>}
        falseRender={
          <div className={`flex flex-col ${informationText && informationText.length > 0 ? '' : 'pt-4'}`}>
            <div className="grid grid-rows-2 grid-cols-2 sm:flex sm:flex-row gap-x-8 gap-y-6">
              {options.map((option) => {
                return (
                  <RadioItem
                    key={option.key}
                    name={option.value}
                    isSelected={isSelected(selectedKey, option)}
                    onClick={() => setSelectedKey(option.key)}
                  />
                );
              })}
            </div>
            <div className="mt-3 self-start text-text-dark text-sm font-medium">{informationText}</div>
          </div>
        }
      />
    </div>
  );
};
