import React, { FC, useState } from 'react';
import { Field, FieldProps, Formik } from 'formik';
import { ShopContent } from '../../../types/cms_shared_types';
import { Product, ProductListActions } from '../../../types/webshop';
import { DefaultBottomBar } from '../BottomBar/DefaultBottomBar';
import { ShoppingList } from '../ShoppingCart/ShoppingList';
import { Step } from '../Step';
import { TooltipHelper } from '../TooltipHelper';
import { TotalPrice } from '../ShoppingCart/TotalPrice';
import { Validations, gen_validate, validateCharacters } from '../../../helper/validation';
import { Conditional } from '../../Common/Conditional';

interface SummaryProps {
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  price: string;
  vatPrice: string;
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  summaryFormComment: string;
  setSummaryFormComment: React.Dispatch<React.SetStateAction<string>>;
  nextButtonClicked: () => void;
  cmsShopContent: ShopContent;
}

export const Summary: FC<SummaryProps> = ({
  selectedProducts,
  selectedProductsActions,
  price,
  vatPrice,
  selectedCategoryKey,
  selectedSubcategoryKey,
  summaryFormComment,
  setSummaryFormComment,
  nextButtonClicked,
  cmsShopContent,
}) => {
  const [totalPriceFetching, setTotalPriceFetching] = useState(false);
  const validations: Validations<string> = [
    { validate: validateCharacters, errorMessage: cmsShopContent.invalidCharacterError },
  ];

  return (
    <Formik
      onSubmit={(values) => {
        setSummaryFormComment(values.summaryFormInputs);
      }}
      initialValues={{
        selectedSubcategoryKey: selectedSubcategoryKey,
        summaryFormInputs: summaryFormComment,
      }}
      validate={() => {
        if (selectedProducts.length === 0) {
          return { error: Error('No products have been selected!') };
        }
      }}
      validateOnMount
    >
      {({ isValid, submitForm }) => (
        <Step
          title={cmsShopContent.summaryStep.title}
          subtitle={cmsShopContent.summaryStep.subtitle}
          content={
            <div className="sm:pl-[54px]">
              <ShoppingList
                selectedProducts={selectedProducts}
                selectedProductsActions={selectedProductsActions}
                setTotalPriceFetching={setTotalPriceFetching}
                withEdit={true}
                selectedCategoryKey={selectedCategoryKey}
                selectedSubcategoryKey={selectedSubcategoryKey}
                cmsShopContent={cmsShopContent}
              >
                <div className="mt-6 sm:mr-14 mb-6">
                  <div className="mb-2 flex flex-row items-center">
                    <div className="mr-2 text-sm text-text-dark font-bold">
                      {cmsShopContent.summaryStep.informationTitle}
                    </div>
                    <TooltipHelper text={cmsShopContent.summaryStep.informationTooltip} />
                  </div>
                  <Field name={'summaryFormInputs'} validate={gen_validate(validations)}>
                    {({ field, meta }: FieldProps<string>) => (
                      <>
                        <textarea
                          rows={3}
                          cols={40}
                          maxLength={800}
                          placeholder={cmsShopContent.summaryStep.informationPlaceholder}
                          className={`w-full min-h-[130px] sm:min-h-[100px] py-2 sm:py-3 px-3 sm:pl-6 sm:pr-4 align-top
                          rounded-md bg-input-gray border border-input-border focus:outline-none ${
                            meta.error
                              ? 'text-red-darker border-sami-red focus:border-sami-red'
                              : 'text-text-dark focus:border-sami-green'
                          }`}
                          {...field}
                        />
                        <Conditional
                          condition={!!meta.error && (meta.touched || !!field.value)}
                          trueRender={
                            <div className="flex flex-col mt-2 pl-4 text-sm font-medium text-sami-red">
                              {meta.error}
                            </div>
                          }
                        />
                      </>
                    )}
                  </Field>
                </div>
              </ShoppingList>

              <TotalPrice
                price={price}
                vatPrice={vatPrice}
                totalPriceFetching={totalPriceFetching}
                cmsShopContent={cmsShopContent}
              />
            </div>
          }
          bottomBar={
            <DefaultBottomBar
              continueButtonDisabled={!isValid}
              onClick={() => {
                submitForm();
                nextButtonClicked();
              }}
              cmsShopContent={cmsShopContent}
              footerContent={cmsShopContent.summaryStep.footer}
            />
          }
        />
      )}
    </Formik>
  );
};
