import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { Conditional } from './Conditional';
import { Validations, gen_validate } from '../../helper/validation';

export enum InputMode {
  Text = 'text',
  Number = 'numeric',
  Email = 'email',
  PhoneNumber = 'tel',
}

interface UserInputProps {
  name: string;
  prompt?: string;
  placeholder?: string;
  extraInformation?: string;
  inputMode?: InputMode;
  validations?: Validations<string>;
}

export const UserInput: FC<UserInputProps> = ({
  name,
  prompt,
  placeholder,
  extraInformation,
  inputMode = InputMode.Text,
  validations,
}) => {
  const getBorderStyling = (hasErrors: boolean) => {
    if (hasErrors) {
      return 'text-sami-red border-sami-red border-opacity-100 border-b-2 border-t-0 border-x-0 hover:text-sami-red hover:bg-sami-red-lighter hover:border-sami-red';
    } else {
      return 'text-text-gray border-input-border hover:text-sami-green hover:bg-sami-hover hover:border-sami-green';
    }
  };

  return (
    <Field name={name} validate={gen_validate(validations)}>
      {({ field, meta }: FieldProps<string>) => (
        <div className="w-full mb-8">
          <div className="flex flex-col mb-2 text-sm font-bold text-text-dark">{prompt}</div>

          <div
            className={`flex flex-row items-center overflow-hidden bg-input-gray rounded-lg border border-opacity-20 hover:border-b-2 hover:border-t-0 hover:border-x-0 ${getBorderStyling(
              !!meta.error && (meta.touched || !!meta.value),
            )}`}
          >
            <input
              type="text"
              placeholder={placeholder}
              inputMode={inputMode}
              className={`w-full py-3 pl-6 pr-3 bg-inherit text-base ${
                meta.error ? 'text-red-darker' : 'text-text-dark'
              } focus:outline-none overflow-hidden text-ellipsis`}
              {...field}
            />
          </div>

          {extraInformation && <div className="mt-3 self-start text-text-gray text-xs">{extraInformation}</div>}

          <Conditional
            condition={!!meta.error && (meta.touched || !!meta.value)}
            trueRender={<div className="flex flex-col mt-2 pl-4 text-sm font-medium text-sami-red">{meta.error}</div>}
          />
        </div>
      )}
    </Field>
  );
};
