import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { Header } from '../components/Header/Header';
import { DEFAULT_LANGUAGE } from '../components/Header/LanguageDropdown';
import KontaktBrev from '../images/kontakt_brev.png';

export const Error404Page = () => {
  const [language] = useLocalStorageState<string>('language', { defaultValue: DEFAULT_LANGUAGE });

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [homeLink, setHomeLink] = useState('');
  const [homeText, setHomeText] = useState('');

  useEffect(() => {
    if (language === 'sv') {
      setTitle('Oj, nu blev det fel!');
      setSubtitle('Sidan finns inte eller så var det något fel på länken.');
      setHomeLink('https://www.sami.se');
      setHomeText('Gå till startsidan');
    } else {
      setTitle('Oops, something went wrong!');
      setSubtitle('The page does not exist or the link is incorrect.');
      setHomeLink('https://www.sami.se');
      setHomeText('Go to the homepage');
    }
  }, [language]);

  return (
    <div className="flex flex-col h-screen justify-between">
      <Header />
      <div className="mb-auto flex flex-col mx-auto px-mobile-side sm:px-0">
        <div className="pt-6 sm:pt-8 pb-8 sm:pb-8 flex flex-col items-center">
          <div className="pt-16 sm:pt-24 pb-16 sm:pb-24 sm:px-24 px-16" style={{ fontFamily: 'Montserrat,sans-serif' }}>
            <div className="sm:text-8xl text-3xl text-text-dark font-bold text-center">
              {title}
              <div className="mt-8 sm:mt-16 sm:text-2xl text-sm text-text-dark font-medium">
                {subtitle + ' '}
                <span>
                  <a className="text-sami-green" href={homeLink}>
                    {homeText} <span style={{ fontFamily: 'Arial,Helvetica,sans-serif' }}>&rarr;</span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="sm:h-20 h:16 sm:mx-4 sm:my-2 mx-2 my-1">
        <figure className="sm:flex bg-sami-green hover:bg-sami-green-dark rounded-full p-2 sm:p-2 sm:w-16 sm:h-16 w-12 h-12 float-right">
          <a href="mailto:support@sami.se">
            <img
              className="sm:w-12 sm:h-12 w-8 h-8 rounded-full mx-auto"
              src={KontaktBrev}
              alt=""
              width="384"
              height="512"
            />
          </a>
        </figure>
      </footer>
    </div>
  );
};
