import { FC, useEffect, useRef } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { SubmitButton, SubmitStatus } from './SubmitButton';
import { UserInput } from '../Common/UserInput';
import { getBrevFranOssUserInput } from '../../helper/cms';
import { UserDateInput } from '../Common/UserDateInput';
import { BrevFranOssContent } from '../../types/cms_shared_types';
import { useFormikContext } from 'formik';
import { BrevFranOssFormInputs } from '../../pages/BrevFranOssPage';

interface SubmissionFormProps {
  submitStatus: SubmitStatus;
  cmsBrevFranOssContent: BrevFranOssContent;
}

export const SubmissionForm: FC<SubmissionFormProps> = ({ submitStatus, cmsBrevFranOssContent }) => {
  const scrollTo = useRef<HTMLInputElement>(null);

  const executeScroll = () => scrollTo?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  useEffect(() => {
    executeScroll();
  }, []);

  const { isSubmitting, isValid, submitForm } = useFormikContext<BrevFranOssFormInputs>();

  const nameInput = getBrevFranOssUserInput('name_user_input', cmsBrevFranOssContent);
  const emailInput = getBrevFranOssUserInput('email_user_input', cmsBrevFranOssContent);
  const phoneNumberInput = getBrevFranOssUserInput('phone_number_user_input', cmsBrevFranOssContent);

  return (
    <div ref={scrollTo}>
      <div className="mb-6 text-text-dark text-base font-normal">
        {render(cmsBrevFranOssContent.submissionFormMessage)}
      </div>

      <div className="mt-8">
        <UserDateInput prompt={cmsBrevFranOssContent.datePrompt} name="date" />
        <UserInput name="name" prompt={nameInput?.prompt} placeholder={nameInput?.placeholder} />
        <UserInput name="email" prompt={emailInput?.prompt} placeholder={emailInput?.placeholder} />
        <UserInput name="phoneNumber" prompt={phoneNumberInput?.prompt} placeholder={phoneNumberInput?.placeholder} />
        <div className="mt-12 flex flex-row items-center place-content-center sm:place-content-start">
          <SubmitButton
            text={cmsBrevFranOssContent.submitButton}
            feedbackSuccessfulText={cmsBrevFranOssContent.submitSuccessfulMessage}
            feedbackErrorText={cmsBrevFranOssContent.submitErrorMessage}
            isSubmitting={isSubmitting}
            submitStatus={submitStatus}
            disabled={!isValid || isSubmitting}
            onClick={submitForm}
          />
        </div>
      </div>
    </div>
  );
};
