import { FC } from 'react';
import { X } from 'phosphor-react';
import LogoSAMI from '../../images/sami_logo.svg';
import { LanguageDropdown } from './LanguageDropdown';
import { BrevFranOssContent, ShopContent } from '../../types/cms_shared_types';

interface HeaderProps {
  title?: string;
  cmsContent?: ShopContent | BrevFranOssContent;
}

export const Header: FC<HeaderProps> = ({ title, cmsContent }) => {
  return (
    <div className="sticky top-0 h-16 z-10 w-full flex flex-row items-center shadow-md bg-white">
      <div className="flex-1">
        <a href="https://www.sami.se/" className="flex flex-row items-center">
          <div className="h-16 w-16 p-4 bg-sami-green">
            <X size={32} className="text-white" />
          </div>
          <img src={LogoSAMI} alt="sami-logo" />
        </a>
      </div>
      <div className="hidden sm:block flex-1 self-center text-center text-sm font-bold text-gray">
        {title?.toUpperCase()}
      </div>
      <div className="flex-1 text-right">
        <div className="mr-4">
          <LanguageDropdown cmsContent={cmsContent} />
        </div>
      </div>
    </div>
  );
};
