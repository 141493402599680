import { FC } from 'react';
import { CaretDown } from 'phosphor-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Field, FieldProps } from 'formik';

const theme = createTheme({
  palette: {
    primary: {
      main: '#26a46f',
    },
  },
});

export interface SelectOption {
  key: string;
  displayValue: string;
  backendValue?: string;
}

interface UserSelectInputProps {
  name: string;
  prompt?: string;
  placeholder?: string;
  options: SelectOption[];
  customInputSelected?: boolean;
  customInputSelectedFieldName?: string;
  lastOptionName?: string;
}

export const UserSelectInput: FC<UserSelectInputProps> = ({
  name,
  prompt,
  placeholder,
  options,
  customInputSelected,
  customInputSelectedFieldName,
  lastOptionName,
}) => {
  const isLastOption = (value: string): boolean => {
    return options[options.length - 1].key === value;
  };

  return (
    <Field name={name}>
      {({ field, form }: FieldProps<string>) => (
        <div className="w-full mb-8 text-text-dark">
          <ThemeProvider theme={theme}>
            <div className="flex flex-col mb-2 text-sm font-bold text-text-dark">{prompt}</div>

            <FormControl fullWidth>
              <InputLabel sx={{ fontFamily: 'Montserrat' }} shrink={false}>
                {field.value ? '' : placeholder}
              </InputLabel>
              <Select
                labelId="select-label"
                sx={{
                  borderRadius: '0.5rem',
                  fontFamily: 'Montserrat',
                  color: '#000000',
                  fontSize: '16px',
                  paddingLeft: '10px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid rgb(180 180 197 / 0.2)',
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: '1px solid rgb(180 180 197 / 0.2)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderBottom: '2px solid rgb(38, 164, 111)',
                  },
                  height: '50px',
                }}
                value={customInputSelected ? lastOptionName : field.value}
                onChange={(event) => {
                  const newValues = {
                    ...form.values,
                    [name]: event.target.value,
                  };

                  if (customInputSelectedFieldName) {
                    newValues[customInputSelectedFieldName] = isLastOption(event.target.value);
                  }

                  form.setValues(newValues);
                }}
                onBlur={() => form.setTouched({ ...form.touched, [field.name]: true }, true)}
                IconComponent={() => {
                  return (
                    <div className="mr-4">
                      <CaretDown />
                    </div>
                  );
                }}
                className="bg-input-gray hover:bg-sami-hover"
              >
                {options.map((option) => {
                  return (
                    <MenuItem
                      key={option.key}
                      value={option.backendValue ?? option.key}
                      style={{ fontFamily: 'Montserrat' }}
                    >
                      {option.displayValue}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </ThemeProvider>
        </div>
      )}
    </Field>
  );
};
