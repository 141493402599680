import { createRef, FC, useState, useEffect } from 'react';
import { CaretDown } from 'phosphor-react';
import { findSelectedSubcategoryByKey } from '../../../helper/cms';
import { ShopContent, Subcategory } from '../../../types/cms_shared_types';

interface SubcategoryPickerMobileProps {
  selectedSubcategoryKey?: string;
  subcategories: Subcategory[];
  cmsShopContent: ShopContent;
  switchSubcategories: (selectedSubcategoryKey: string) => void;
  setShowPickSubcategoryText: (value: boolean) => void;
}

export const SubcategoryPickerMobile: FC<SubcategoryPickerMobileProps> = ({
  selectedSubcategoryKey,
  subcategories,
  cmsShopContent,
  switchSubcategories,
  setShowPickSubcategoryText,
}) => {
  const [hasMadeSelection, setHasMadeSelection] = useState(false);
  const wrapperRef = createRef<HTMLInputElement>();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setShowPickSubcategoryText(!hasMadeSelection);
  }, [hasMadeSelection, setShowPickSubcategoryText]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef} className="text-left z-0">
      <button
        id="menu-button"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="group inline-flex sm:justify-center rounded-lg px-4 py-2 hover:text-text-gray border border-input-border"
      >
        <div className="mr-2 text-sm font-medium text-sami-dark group-hover:text-text-gray truncate">
          {hasMadeSelection
            ? findSelectedSubcategoryByKey(selectedSubcategoryKey, subcategories)?.name ?? ''
            : cmsShopContent.productChoiceStep.pickSubcategorySelectText}
        </div>
        <CaretDown size={16} weight="bold" className="self-center text-text-gray" />
      </button>

      <div
        hidden={!isOpen}
        role="menu"
        className="absolute right-4 z-10 mt-2 origin-top-right rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div>
          {subcategories.map((subcategory, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setHasMadeSelection(true);
                  switchSubcategories(subcategory.key);
                  setIsOpen(false);
                }}
                className="flex justify-start text-sami-dark px-4 py-3 text-sm hover:bg-input-gray hover:cursor-pointer truncate"
              >
                {subcategory.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
