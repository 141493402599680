import { FC } from 'react';
import { retrieveCmsProduct } from '../../../helper/cms';
import { ShopContent } from '../../../types/cms_shared_types';
import { ShoppingProduct } from './ShoppingProduct';
import { Product, ProductListActions } from '../../../types/webshop';

interface ShoppingListProps {
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  setTotalPriceFetching: (value: boolean) => void;
  withEdit?: boolean;
  children?: JSX.Element;
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  cmsShopContent: ShopContent;
}

export const ShoppingList: FC<ShoppingListProps> = ({
  selectedProducts,
  selectedProductsActions,
  setTotalPriceFetching,
  withEdit = false,
  children,
  selectedCategoryKey,
  selectedSubcategoryKey,
  cmsShopContent,
}) => {
  return (
    <div className="flex flex-col">
      {selectedProducts.map((prod: Product) => {
        const p = retrieveCmsProduct(prod.code, cmsShopContent.categories, selectedCategoryKey, selectedSubcategoryKey);
        if (!p) {
          return <></>;
        }
        return (
          <ShoppingProduct
            key={prod.code}
            price={prod.priceYearExclVat.toLocaleString('sv-SE')}
            product={p}
            selectedProducts={selectedProducts}
            selectedProductsActions={selectedProductsActions}
            setTotalPriceFetching={setTotalPriceFetching}
            withEdit={withEdit}
            cmsShopContent={cmsShopContent}
          />
        );
      })}
      {children}
    </div>
  );
};
