import { FC } from 'react';
import { CaretDown } from 'phosphor-react';
import { motion } from 'framer-motion';
import { CmsProduct, ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { TooltipHelper } from '../TooltipHelper';
import { caretTransitionVariants } from './ShoppingProduct';
import { LoadingSpinner } from '../../Common/LoadingSpinner';
import { isMeasureSimulatable, isTimeMeasureSimulatable } from '../../../helper/cms';

interface PriceSimulatorHeaderProps {
  product: CmsProduct;
  opened: boolean;
  setOpened: (value: boolean) => void;
  price: string;
  isFetching: boolean;
  cmsShopContent: ShopContent;
}

export const PriceSimulatorHeader: FC<PriceSimulatorHeaderProps> = ({
  product,
  opened,
  setOpened,
  price,
  isFetching,
  cmsShopContent,
}) => {
  return (
    <div className="flex flex-col items-start sm:items-end">
      <div className="flex flex-row items-center">
        <div className="mr-2 text-text-dark text-sm italic md:truncate">
          {cmsShopContent.shoppingCart.shoppingCartModal.reporting}
        </div>
        <TooltipHelper text={cmsShopContent.shoppingCart.shoppingCartModal.reportingTooltip} />
      </div>
      <Conditional
        condition={isMeasureSimulatable(product) || isTimeMeasureSimulatable(product)}
        trueRender={
          <div onClick={() => setOpened(!opened)} className="mt-1 sm:ml-2 cursor-pointer">
            <div className="flex flex-row items-center">
              <Conditional
                condition={opened}
                trueRender={
                  <Conditional
                    condition={isFetching}
                    trueRender={<LoadingSpinner size={18} />}
                    falseRender={
                      <div className="flex flex-row align-center text-text-dark text-sm font-bold">
                        {price} {cmsShopContent.priceWithMonthFormat}
                      </div>
                    }
                  />
                }
                falseRender={
                  <div className="text-sami-green text-sm font-bold">
                    {cmsShopContent.shoppingCart.shoppingCartModal.simulatePrice}
                  </div>
                }
              />
              <motion.div
                className="ml-2 text-sami-green"
                variants={caretTransitionVariants}
                animate={opened ? 'open' : 'closed'}
              >
                <CaretDown size={22} />
              </motion.div>
            </div>
          </div>
        }
      />
    </div>
  );
};
