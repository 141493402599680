import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Pencil, Trash } from 'phosphor-react';
import { CmsProduct, ShopContent } from '../../../types/cms_shared_types';
import {
  displayProductInformation,
  displaySchablonProductInformation,
  getSchablonPricePerMonth,
} from '../../../helper/cms';
import { Conditional } from '../../Common/Conditional';
import { ProductConfigurationModal } from '../ProductChoice/ProductConfigurationModal/ProductConfigurationModal';
import { apiGetPrice } from '../../../api/api';
import { Product, ProductListActions } from '../../../types/webshop';
import { PriceProduct } from '../../../types/api_shared_types';
import { ShoppingProductDesktop } from './ShoppingProductDesktop';
import { ShoppingProductMobile } from './ShoppingProductMobile';
import { PriceSimulatorContent } from './PriceSimulatorContent';
import { PriceSimulatorHeader } from './PriceSimulatorHeader';
import { debounce } from '@mui/material';

export const caretTransitionVariants = {
  closed: { transformOrigin: 'center', rotate: 0, transition: { duration: 0.2 } },
  open: { transformOrigin: 'center', rotate: 180, transition: { duration: 0.2 } },
};

interface ShoppingProductProps {
  price: string;
  product: CmsProduct;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  setTotalPriceFetching: (value: boolean) => void;
  withEdit: boolean;
  cmsShopContent: ShopContent;
}

export const ShoppingProduct: FC<ShoppingProductProps> = ({
  price,
  product,
  selectedProducts,
  selectedProductsActions,
  setTotalPriceFetching,
  withEdit,
  cmsShopContent,
}) => {
  const [opened, setOpened] = useState(false);
  const [showProductConfigurationModal, setShowProductConfigurationModal] = useState(false);

  const [simulatedMeasure, setSimulatedMeasure] = useState('');
  const [simulatedTimeMeasure, setSimulatedTimeMeasure] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const requestId = useRef(0);
  useEffect(() => {
    setTotalPriceFetching(isFetching);
  }, [isFetching, setTotalPriceFetching]);

  const findSelectedProduct = useMemo(
    () =>
      (code: string): Product => {
        const prod = selectedProducts.find((p) => p.code === code);
        if (!prod) {
          throw new Error(`Couldn't find product '${code}'`);
        }
        return prod;
      },
    [selectedProducts],
  );
  const returnProduct = findSelectedProduct(product.code);

  useEffect(() => {
    setSimulatedMeasure(returnProduct.quantity);
    setSimulatedTimeMeasure(returnProduct.timeQuantity);
  }, [returnProduct]);

  const updatePrice = useMemo(
    () =>
      debounce(async (simulatedMeasure?: string, simulatedTimeMeasure?: string) => {
        if (!product.reporting) {
          return;
        }

        if (simulatedMeasure || simulatedTimeMeasure) {
          setIsFetching(true);

          const tempProduct = findSelectedProduct(product.code);
          const priceProduct: PriceProduct = {
            code: tempProduct.code,
            quantity: simulatedMeasure?.toString() ?? tempProduct.quantity,
            timeQuantity: simulatedTimeMeasure?.toString() ?? tempProduct.timeQuantity,
            musicClass: tempProduct.musicClass,
          };
          requestId.current += 1;
          const id = requestId.current;
          const {
            data: { priceYearInclVat, priceYearExclVat },
          } = await apiGetPrice(priceProduct);

          if (requestId.current === id) {
            selectedProductsActions.replace({
              ...priceProduct,
              priceYearInclVat: Math.round(getSchablonPricePerMonth(product, priceYearInclVat)),
              priceYearExclVat: Math.round(getSchablonPricePerMonth(product, priceYearExclVat)),
              schablonQuantity: tempProduct.schablonQuantity,
              schablonTimeQuantity: tempProduct.schablonTimeQuantity,
            });

            setIsFetching(false);
            return;
          }
        }
      }, 400),
    [product, findSelectedProduct, selectedProductsActions],
  );

  const Icon = () => {
    return (
      <button type="button" className="ml-auto pl-2">
        <Conditional
          condition={withEdit && !product.reporting}
          trueRender={
            <Pencil onClick={() => setShowProductConfigurationModal(true)} size={32} className="text-sami-green" />
          }
          falseRender={
            <Trash
              onClick={() => {
                const prodIndex = selectedProducts.findIndex((p) => p.code === product.code);
                selectedProductsActions.remove(prodIndex);
              }}
              size={32}
              className="text-sami-red"
            />
          }
        />
      </button>
    );
  };

  const priceSimulatorHeader = () => {
    return product.reporting ? (
      <PriceSimulatorHeader
        product={product}
        opened={opened}
        setOpened={setOpened}
        price={price}
        isFetching={isFetching}
        cmsShopContent={cmsShopContent}
      />
    ) : undefined;
  };

  const priceSimulatorContent = () => {
    return product.reporting ? (
      <PriceSimulatorContent
        product={product}
        opened={opened}
        simulatedMeasure={simulatedMeasure}
        setSimulatedMeasure={setSimulatedMeasure}
        simulatedTimeMeasure={simulatedTimeMeasure}
        setSimulatedTimeMeasure={setSimulatedTimeMeasure}
        updatePrice={updatePrice}
      />
    ) : undefined;
  };

  return (
    <div>
      <div className="w-full hidden sm:block">
        <ShoppingProductDesktop
          name={product.name}
          schablonSummary={product.isSchablon ? displaySchablonProductInformation(returnProduct, product) : undefined}
          summary={displayProductInformation(product, selectedProducts)}
          price={price}
          icon={Icon()}
          priceSimulatorHeader={priceSimulatorHeader()}
          priceSimulatorContent={priceSimulatorContent()}
          cmsShopContent={cmsShopContent}
        />
      </div>

      <div className="w-full block sm:hidden">
        <ShoppingProductMobile
          name={product.name}
          schablonSummary={product.isSchablon ? displaySchablonProductInformation(returnProduct, product) : undefined}
          summary={displayProductInformation(product, selectedProducts)}
          price={price}
          icon={Icon()}
          priceSimulatorHeader={priceSimulatorHeader()}
          priceSimulatorContent={priceSimulatorContent()}
          cmsShopContent={cmsShopContent}
        />
      </div>

      <ProductConfigurationModal
        product={product}
        editMode={true}
        showModal={showProductConfigurationModal}
        setShowModal={setShowProductConfigurationModal}
        selectedProducts={selectedProducts}
        selectedProductsActions={selectedProductsActions}
        cmsShopContent={cmsShopContent}
      />
    </div>
  );
};
