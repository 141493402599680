import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

export const LanguageWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [language, setLanguage] = useLocalStorageState<string>('language');

  useEffect(() => {
    setLanguage('en');
    const newPath = location.pathname.split('/en')[1];
    navigate(newPath);
  }, [location, navigate, setLanguage]);

  return <></>;
};
