import { FC } from 'react';
import { CheckCircle, WarningOctagon } from 'phosphor-react';
import { LoadingSpinner } from '../Common/LoadingSpinner';

export enum SubmitStatus {
  NONE,
  SUCCESS,
  FAIL,
}

interface SubmitButtonProps {
  text: string;
  feedbackSuccessfulText: string;
  feedbackErrorText: string;
  isSubmitting: boolean;
  submitStatus: SubmitStatus;
  disabled?: boolean;
  onClick: () => void;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  text,
  feedbackSuccessfulText,
  feedbackErrorText,
  isSubmitting,
  submitStatus,
  disabled,
  onClick,
}) => {
  const Success: FC = () => {
    return (
      <div className="flex flex-row items-center">
        <CheckCircle size={32} className="text-sami-green" />
        <div className="ml-4">{feedbackSuccessfulText}</div>
      </div>
    );
  };

  const Error: FC = () => {
    return (
      <div className="flex flex-row items-center">
        <WarningOctagon size={32} className="text-sami-red" />
        <div className="ml-4">{feedbackErrorText}</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col sm:flex-row items-center sm:mx-0">
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={`py-3 px-4 rounded-full font-bold text-m text-white ${
          !disabled ? 'bg-sami-green hover:opacity-50' : 'bg-lightest hover:text-opacity-40 cursor-not-allowed'
        }`}
      >
        {text}
      </button>

      <div className="mt-6 sm:mt-0 sm:ml-6 flex flex-row items-center">
        {isSubmitting && <LoadingSpinner className="ml-1" />}
        {!isSubmitting && submitStatus === SubmitStatus.SUCCESS && <Success />}
        {!isSubmitting && submitStatus === SubmitStatus.FAIL && <Error />}
      </div>
    </div>
  );
};
