import { FC, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import luhn from 'fast-luhn';
import {
  findSelectedCategoryByKey,
  findSelectedSubcategoryByKey,
  findUserInputResourceByKey,
} from '../../../helper/cms';
import { ResourceText, ShopContent } from '../../../types/cms_shared_types';
import { UserDateInput } from '../../Common/UserDateInput';
import { Conditional } from '../../Common/Conditional';
import { Step } from '../Step';
import { InvoiceInformation } from './InvoiceInformation';
import { RadioDialog } from './RadioDialog';
import { LineDelimiter } from '../../Common/LineDelimiter';
import { InputMode, UserInput } from '../../Common/UserInput';
import { UserBooleanInput } from '../../Common/UserBooleanInput';
import { DefaultBottomBar } from '../BottomBar/DefaultBottomBar';
import { DEFAULT_COUNTRY_KEY } from './countries';
import { EMAIL_REGEX, LETTERS_ONLY_REGEX, SWEDISH_POST_CODE_REGEX } from '../../../helper/helper';
import { InvoiceFormInputs } from '../../../types/api_shared_types';

const DEFAULT_MONTHLY_INVOICE_THRESHOLD = 300;

export enum Period {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  HALFYEARLY = 'halfyearly',
  YEARLY = 'yearly',
}

export enum Method {
  EMAIL = 'email',
  PAPER = 'paper',
}

const FALLBACK_INVOICE_PERIOD = Period.YEARLY;

interface InvoiceProps {
  price: string;
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  invoiceFormInputs: InvoiceFormInputs;
  setInvoiceFormInputs: (inputs: InvoiceFormInputs) => void;
  atLeastOneReportingProduct: boolean;
  nextButtonClicked: () => void;
  cmsShopContent: ShopContent;
  language: string;
}

export const Invoice: FC<InvoiceProps> = ({
  price,
  selectedCategoryKey,
  selectedSubcategoryKey,
  invoiceFormInputs,
  setInvoiceFormInputs,
  atLeastOneReportingProduct,
  nextButtonClicked,
  cmsShopContent,
  language,
}) => {
  const scrollRef = useRef<HTMLInputElement>(null);
  const [enableScroll, setEnableScroll] = useState(false);
  const executeScroll = () => scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  useEffect(() => {
    if (enableScroll) {
      executeScroll();
    }
  }, [enableScroll]);

  const [availablePeriods, setAvailablePeriods] = useState<ResourceText[]>([]);

  const isPriceBelowMonthlyThreshold =
    Number.parseInt(price.replace(/\s/g, '')) <
    (cmsShopContent.invoiceStep.monthlyInvoiceThreshold ?? DEFAULT_MONTHLY_INVOICE_THRESHOLD);

  useEffect(() => {
    if (isPriceBelowMonthlyThreshold) {
      setAvailablePeriods(cmsShopContent.invoiceStep.invoicePeriods.filter((p) => p.key !== Period.MONTHLY) ?? []);
    } else {
      setAvailablePeriods(cmsShopContent.invoiceStep.invoicePeriods ?? []);
    }
  }, [atLeastOneReportingProduct, isPriceBelowMonthlyThreshold, cmsShopContent.invoiceStep.invoicePeriods]);

  const [availableMethods, setAvailableMethods] = useState<ResourceText[]>([]);

  useEffect(() => {
    setAvailableMethods(cmsShopContent.invoiceStep.invoiceMethods ?? []);
  }, [cmsShopContent.invoiceStep.invoiceMethods]);

  const fetchDefaultInvoicePeriod = (): string => {
    const category = findSelectedCategoryByKey(cmsShopContent.categories, selectedCategoryKey);
    const subcategory = findSelectedSubcategoryByKey(selectedSubcategoryKey, category?.subcategories);
    const defaultInvoicePeriod =
      subcategory?.defaultInvoicePeriod && subcategory?.defaultInvoicePeriod !== ''
        ? subcategory.defaultInvoicePeriod
        : Period.YEARLY;

    if (isPriceBelowMonthlyThreshold && defaultInvoicePeriod === Period.MONTHLY) {
      return FALLBACK_INVOICE_PERIOD;
    }
    return defaultInvoicePeriod;
  };

  const getDefaultPeriodInCorrectLanguage = (): string => {
    const defaultPeriod = fetchDefaultInvoicePeriod();
    return cmsShopContent.invoiceStep.invoicePeriods.find((p) => p.key === defaultPeriod)?.value ?? '';
  };

  const organisationNumberRegex = /^\d{6}-?\d{4}$/;
  const emailInput = findUserInputResourceByKey('email_address_pdf_input', cmsShopContent.invoiceStep.userInputs);

  const postCodeValidation = (field: string) => {
    return Yup.string()
      .required(cmsShopContent.inputRequiredError)
      .when(field, {
        is: DEFAULT_COUNTRY_KEY?.sv,
        then: (schema) => schema.matches(SWEDISH_POST_CODE_REGEX, cmsShopContent.invalidPostCodeError),
        otherwise: (schema) => schema.required(cmsShopContent.inputRequiredError),
      });
  };

  const validationSchema = Yup.object().shape({
    organisationNumberInput: Yup.string()
      .matches(organisationNumberRegex, cmsShopContent.invalidOrganisationNumberError)
      .test('luhn-validation', cmsShopContent.invalidLuhnOrganisationNumberError, (value) => {
        if (!value) {
          return false;
        }
        const val = (value as string).replace('-', '');
        return luhn(val);
      })
      .required(cmsShopContent.inputRequiredError),
    companyNameInput: Yup.string().required(cmsShopContent.inputRequiredError),
    companyAddressInput: Yup.string().required(cmsShopContent.inputRequiredError),
    companyPostNumberInput: postCodeValidation('companyCountryInput'),
    companyCityInput: Yup.string()
      .matches(LETTERS_ONLY_REGEX, cmsShopContent.invalidCityError)
      .required(cmsShopContent.inputRequiredError),
    companyCountryInput: Yup.string().required(cmsShopContent.inputRequiredError),

    sameInvoiceAddressInput: Yup.boolean(),

    invoiceNameInput: Yup.string().notRequired(),
    invoiceAddressInput: Yup.string().when('sameInvoiceAddressInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () => Yup.string().required(cmsShopContent.inputRequiredError),
    }),
    invoicePostNumberInput: Yup.string().when('sameInvoiceAddressInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () => postCodeValidation('invoiceCountryInput'),
    }),
    invoiceCityInput: Yup.string().when('sameInvoiceAddressInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () =>
        Yup.string()
          .matches(LETTERS_ONLY_REGEX, cmsShopContent.invalidCityError)
          .required(cmsShopContent.inputRequiredError),
    }),
    invoiceCountryInput: Yup.string().when('sameInvoiceAddressInput', {
      is: true,
      then: () => Yup.string().notRequired(),
      otherwise: () => Yup.string().required(cmsShopContent.inputRequiredError),
    }),

    startDateInput: Yup.string().required(cmsShopContent.inputRequiredError),
    invoicePeriodInput: Yup.string().required(cmsShopContent.inputRequiredError),
    emailAddressPdfInput: Yup.string().when('paperInvoiceInput', {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.matches(EMAIL_REGEX, cmsShopContent.invalidEmailError).required(cmsShopContent.inputRequiredError),
    }),
    paperInvoiceInput: Yup.boolean(),
  });

  return (
    <Formik
      onSubmit={(values) => {
        if (values.invoicePeriodInput === Period.MONTHLY) {
          setInvoiceFormInputs({ ...values, paperInvoiceInput: false });
        } else {
          setInvoiceFormInputs({ ...values });
        }
        nextButtonClicked();
      }}
      initialValues={{
        organisationNumberInput: invoiceFormInputs.organisationNumberInput,
        companyNameInput: invoiceFormInputs.companyNameInput,
        companyAddressInput: invoiceFormInputs.companyAddressInput,
        companyPostNumberInput: invoiceFormInputs.companyPostNumberInput,
        companyCityInput: invoiceFormInputs.companyCityInput,
        companyCountryInput:
          invoiceFormInputs.companyCountryInput === ''
            ? DEFAULT_COUNTRY_KEY?.sv ?? 'Sverige'
            : invoiceFormInputs.companyCountryInput,

        sameInvoiceAddressInput: invoiceFormInputs.sameInvoiceAddressInput,

        invoiceCareOfInput: invoiceFormInputs.invoiceCareOfInput,
        invoiceAddressInput: invoiceFormInputs.invoiceAddressInput,
        invoicePostNumberInput: invoiceFormInputs.invoicePostNumberInput,
        invoiceCityInput: invoiceFormInputs.invoiceCityInput,
        invoiceCountryInput: invoiceFormInputs.invoiceCountryInput,

        startDateInput: invoiceFormInputs.startDateInput,
        invoicePeriodInput:
          invoiceFormInputs.invoicePeriodInput === ''
            ? fetchDefaultInvoicePeriod()
            : invoiceFormInputs.invoicePeriodInput,
        emailAddressPdfInput: invoiceFormInputs.emailAddressPdfInput,
        paperInvoiceInput: invoiceFormInputs.paperInvoiceInput,
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ isValid, values, setFieldValue, setValues, submitForm }) => (
        <Step
          fullWidth
          title={cmsShopContent.invoiceStep.title}
          subtitle={cmsShopContent.invoiceStep.subtitle}
          content={
            <div className="mx-auto flex flex-col sm:flex-row place-content-center">
              <div className="sm:w-1/2 sm:max-w-[600px] flex flex-col items-center sm:ml-14 sm:mr-8">
                <InvoiceInformation language={language} cmsShopContent={cmsShopContent} />

                <UserBooleanInput
                  name="sameInvoiceAddressInput"
                  title={cmsShopContent.invoiceStep.invoiceAddressTitle}
                  description={cmsShopContent.invoiceStep.invoiceAddressDescription}
                  callback={(value: boolean) => setEnableScroll(!value)}
                />

                <Conditional
                  condition={!values.sameInvoiceAddressInput}
                  trueRender={
                    <>
                      <LineDelimiter />
                      <div ref={scrollRef} className="w-full">
                        <div className="text-xl text-text-dark font-bold mb-8">
                          {cmsShopContent.invoiceStep.invoiceAddressTitle}
                        </div>
                        <InvoiceInformation isInvoice language={language} cmsShopContent={cmsShopContent} />
                      </div>
                    </>
                  }
                />

                <Conditional condition={!values.sameInvoiceAddressInput} trueRender={<LineDelimiter hideOnDesktop />} />
              </div>

              <div className="sm:w-1/2 sm:max-w-[600px] flex flex-col items-center sm:ml-8 sm:mr-14">
                <UserDateInput
                  name="startDateInput"
                  prompt={cmsShopContent.invoiceStep.startDatePrompt}
                  allowPastDates
                />

                <RadioDialog
                  prompt={cmsShopContent.invoiceStep.invoicePeriodTitle}
                  options={availablePeriods}
                  selectedKey={values.invoicePeriodInput}
                  setSelectedKey={(val) => {
                    const newValues = {
                      ...values,
                      invoicePeriodInput: val as Period,
                    };

                    if (val === Period.MONTHLY) {
                      newValues.paperInvoiceInput = false;
                    }

                    setValues(newValues);
                  }}
                  isSelected={(selectedKey: string, option: ResourceText): boolean => selectedKey === option.key}
                  informationText={cmsShopContent.invoiceStep.defaultInvoicePeriodReviewWarning.replace(
                    '{default}',
                    getDefaultPeriodInCorrectLanguage().toLowerCase(),
                  )}
                  renderTextInsteadOfRadioItems={atLeastOneReportingProduct}
                  renderTextInsteadOfRadioItemsText={cmsShopContent.invoiceStep.defaultInvoicePeriodForReporting.replace(
                    '{default}',
                    getDefaultPeriodInCorrectLanguage().toLowerCase(),
                  )}
                />

                <Conditional
                  condition={values.invoicePeriodInput !== Period.MONTHLY}
                  trueRender={
                    <div className="w-full sm:h-[78px] sm:mb-8">
                      <RadioDialog
                        prompt={cmsShopContent.invoiceStep.invoiceMethodTitle}
                        options={availableMethods}
                        selectedKey={values.paperInvoiceInput.toString()}
                        setSelectedKey={(value: string): void =>
                          setFieldValue('paperInvoiceInput', value === Method.PAPER.toString())
                        }
                        isSelected={(selectedKey: string, option: ResourceText): boolean => {
                          if (selectedKey === 'true') {
                            return option.key === Method.PAPER;
                          } else {
                            return option.key === Method.EMAIL;
                          }
                        }}
                        renderTextInsteadOfRadioItems={false}
                      />
                      <Conditional
                        condition={values.paperInvoiceInput}
                        trueRender={
                          <div className="text-text-dark text-sm font-medium">
                            {cmsShopContent.invoiceStep.paperInvoiceExtraDescription}
                          </div>
                        }
                      />
                    </div>
                  }
                />

                <Conditional
                  condition={!values.paperInvoiceInput || values.invoicePeriodInput === Period.MONTHLY}
                  trueRender={
                    <>
                      <UserInput
                        name="emailAddressPdfInput"
                        prompt={emailInput?.prompt}
                        placeholder={emailInput?.placeholder}
                        extraInformation={
                          values.invoicePeriodInput === Period.MONTHLY
                            ? cmsShopContent.invoiceStep.monthlyInvoiceExtraDescription
                            : ''
                        }
                        inputMode={InputMode.Email}
                      />
                    </>
                  }
                />
              </div>
            </div>
          }
          bottomBar={
            <DefaultBottomBar
              continueButtonDisabled={!isValid}
              onClick={submitForm}
              cmsShopContent={cmsShopContent}
              footerContent={cmsShopContent.invoiceStep.footer}
            />
          }
        />
      )}
    </Formik>
  );
};
