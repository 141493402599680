import { FC } from 'react';
import { BreadcrumbList } from './BreadcrumbList';
import { ResourceText, ShopContent } from '../../../types/cms_shared_types';
import { Button } from '../../Common/Button';
import { ArrowLeft } from 'phosphor-react';
import { Conditional } from '../../Common/Conditional';
import { Steps } from '../WebshopForm';
import { ShoppingCart } from '../ShoppingCart/ShoppingCart';
import { Product, ProductListActions } from '../../../types/webshop';

interface BreadcrumbsProps {
  currentStep: number;
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  price: string;
  vatPrice: string;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  onBackClick: () => void;
  cmsShopContent: ShopContent;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  currentStep,
  selectedCategoryKey,
  selectedSubcategoryKey,
  price,
  vatPrice,
  selectedProducts,
  selectedProductsActions,
  onBackClick,
  cmsShopContent,
}) => {
  const showBreadcrumbsContent = (): boolean => currentStep <= Steps.CONTACT;

  const getBottomBorder = () => (showBreadcrumbsContent() ? 'border-b border-input-border' : '');

  const getSteps = () => cmsShopContent.breadcrumb.steps.map((step: ResourceText) => step.value) ?? [];

  const showShoppingCart = () => {
    return currentStep === Steps.PRODUCT_CHOICE || (currentStep >= Steps.INFORMATION && currentStep <= Steps.CONTACT);
  };

  const disableShoppingCart = () => {
    return currentStep >= Steps.LANDING_PAGE;
  };

  return (
    <div className={`w-screen h-16 flex flex-row items-center ${getBottomBorder()}`}>
      <div className="flex-none w-1/6">
        <Conditional
          condition={currentStep != Steps.PRODUCT_FAMILY && currentStep < getSteps().length}
          trueRender={
            <div className="flex place-content-center lg:place-content-end">
              <div className="hidden lg:block">
                <Button
                  text={cmsShopContent.breadcrumb.backButton}
                  leftIcon={<ArrowLeft size={24} className="text-icon" />}
                  onClick={onBackClick}
                />
              </div>

              <button className="block ml-6 lg:hidden" onClick={onBackClick}>
                <ArrowLeft size={24} className="text-icon" />
              </button>
            </div>
          }
        />
      </div>

      <div className="grow text-center">
        <Conditional
          condition={showBreadcrumbsContent()}
          trueRender={<BreadcrumbList steps={getSteps()} currentStep={currentStep} />}
        />
      </div>

      <div className="min-w-fit flex-none w-1/6">
        <Conditional
          condition={showShoppingCart() && selectedProducts.length > 0}
          trueRender={
            <ShoppingCart
              selectedCategoryKey={selectedCategoryKey}
              selectedSubcategoryKey={selectedSubcategoryKey}
              price={price}
              vatPrice={vatPrice}
              selectedProducts={selectedProducts}
              selectedProductsActions={selectedProductsActions}
              cmsShopContent={cmsShopContent}
              disable={disableShoppingCart()}
            />
          }
        />
      </div>
    </div>
  );
};
