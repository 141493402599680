import { FC, useEffect, useState } from 'react';
import { Box, createTheme, Slider, ThemeProvider } from '@mui/material';
import { capitalizeFirstLetter } from '../../../helper/helper';
import { InputMode } from '../../Common/UserInput';

const theme = createTheme({
  palette: {
    primary: {
      main: '#26a46f',
    },
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: { color: 'white' },
      },
    },
  },
});

interface PriceSimulatorProps {
  min?: number;
  max?: number;
  unit: string;
  simulatedValue: string;
  simulationName: 'measureSimulation' | 'timeMeasureSimulation';
  setSimulatedValue: (value: string) => void;
  updatePrice: (simulatedMeasure?: string, simulatedTimeMeasure?: string) => Promise<void>;
}

export const PriceSimulatorInput: FC<PriceSimulatorProps> = ({
  min,
  max,
  unit,
  simulatedValue,
  simulationName,
  setSimulatedValue,
  updatePrice,
}) => {
  const [displayValue, setDisplayValue] = useState<number>(min ?? 0);
  const [minValue, setMinValue] = useState<number>(Number(min));
  const [maxValue, setMaxValue] = useState<number>(Number(max));
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setDisplayValue(Number(simulatedValue));
    if (min) {
      setMinValue(Number(min));
    }
    if (max) {
      setMaxValue(Number(max));
    }
  }, [max, min, simulatedValue]);

  useEffect(() => {
    setInputValue(simulatedValue);
  }, [simulatedValue]);

  return (
    <div className="w-full flex flex-col sm:flex-row items-start sm:items-center mt-4 sm:mt-2 pr-6">
      <div className="sm:w-[150px] sm:pr-6 text-sm text-text-dark font-bold">{capitalizeFirstLetter(unit)}</div>

      <ThemeProvider theme={theme}>
        <Box className="w-full sm:w-3/5 mr-4 mt-2">
          <Slider
            value={displayValue}
            min={minValue}
            max={maxValue}
            aria-label="Small"
            valueLabelDisplay="auto"
            onChange={(_e, v) => setDisplayValue(v as number)}
            onChangeCommitted={(_e, v) => {
              setSimulatedValue(v.toString());
              updatePrice(
                simulationName === 'measureSimulation' ? v.toString() : undefined,
                simulationName === 'timeMeasureSimulation' ? v.toString() : undefined,
              );
            }}
          />
        </Box>
      </ThemeProvider>

      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          const value = e.target.value;
          const val = Number(value);
          if (value === '' || isNaN(val)) {
            setSimulatedValue(minValue.toString());
            return;
          }

          const v = Math.min(Math.max(val, minValue), maxValue).toString();

          setSimulatedValue(v);
          updatePrice(
            simulationName === 'measureSimulation' ? v : undefined,
            simulationName === 'timeMeasureSimulation' ? v : undefined,
          );
        }}
        inputMode={InputMode.Number}
        className="w-[100px] px-4 rounded bg-input-gray border border-input-border text-text-dark text-start sm:text-end focus:outline-sami-green"
      />
    </div>
  );
};
