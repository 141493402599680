export const Conditional = ({
  condition,
  trueRender,
  falseRender,
}: {
  condition: boolean;
  trueRender: JSX.Element;
  falseRender?: JSX.Element;
}) => {
  if (condition) {
    return trueRender;
  } else {
    return falseRender ?? <></>;
  }
};
