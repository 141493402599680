export function validateCharacters(input: string): boolean {
  return ![';'].some((character) => input.includes(character));
}

export type Validations<T> = Array<{
  validate: (input: T) => boolean;
  errorMessage: string;
}>;

export function gen_validate<T>(validations?: Validations<T>): ((value: T) => string | undefined) | undefined {
  if (validations && validations.length) {
    return (value: T) => {
      for (const validation of validations) {
        if (!validation.validate(value)) {
          return validation.errorMessage;
        }
      }
    };
  }
}
