import React, { FC, useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { RequiredNumberSchema } from 'yup/lib/number';
import { CmsProduct, ShopContent } from '../../../../types/cms_shared_types';
import { Conditional } from '../../../Common/Conditional';
import { Button } from '../../../Common/Button';
import { Modal } from '../../Modal';
import { apiGetPrice } from '../../../../api/api';
import { Product, ProductListActions } from '../../../../types/webshop';
import { UserBooleanInput } from '../../../Common/UserBooleanInput';
import { InputParams, ProductInput } from './ProductInput';
import { PriceProduct } from '../../../../types/api_shared_types';
import { LoadingSpinner } from '../../../Common/LoadingSpinner';
import { UserDateInput } from '../../../Common/UserDateInput';
import { DayCount } from '../../../Common/DayCount';
import dayjs from 'dayjs';
import { getSchablonPricePerMonth } from '../../../../helper/cms';

export enum MusicClass {
  A = 'A',
  B = 'B',
}

const MINIMUM_DELAY_FOR_SPINNER_IN_MS = 1000;

export interface ProductConfigurationModalFormInputs {
  measureInput: string;
  customMeasureInputSelected: boolean;
  customMeasureInput: string;
  timeMeasureInput: string;
  customTimeMeasureInputSelected: boolean;
  customTimeMeasureInput: string;
  musicClassToggleInput: boolean;
  startDate?: string;
  endDate?: string;
  _timeMeasureInputSelection?: string;
}

interface ProductConfigurationModalProps {
  product: CmsProduct;
  editMode?: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  cmsShopContent: ShopContent;
}

export const ProductConfigurationModal: FC<ProductConfigurationModalProps> = ({
  product,
  editMode = false,
  showModal,
  setShowModal,
  selectedProducts,
  selectedProductsActions,
  cmsShopContent,
}) => {
  const [queryingApi, setQueryingApi] = useState(false);
  const [initialMeasure, setInitialMeasure] = useState('');
  const [initialCustomMeasureSelected, setInitialCustomMeasureSelected] = useState(false);
  const [initialCustomMeasure, setInitialCustomMeasure] = useState('');
  const [initialTimeMeasure, setInitialTimeMeasure] = useState('');
  const [initialTimeMeasureSelection, setInitialTimeMeasureSelection] = useState('');
  const [initialCustomTimeMeasureSelected, setInitialCustomTimeMeasureSelected] = useState(false);
  const [initialCustomTimeMeasure, setInitialCustomTimeMeasure] = useState('');
  const [initialMusicClassToggle, setInitialMusicClassToggle] = useState(false);
  const [initialStartDate, setInitialStartDate] = useState<string | undefined>(undefined);
  const [initialEndDate, setInitialEndDate] = useState<string | undefined>(undefined);
  const seasonSelectionEnabled = !!(product.startDatePrompt && product.endDatePrompt);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    if (product.reporting && !product.isSchablon) {
      setInitialMeasure('0');
      setInitialTimeMeasure('0');
      setInitialTimeMeasureSelection('0');
      setInitialMusicClassToggle(false);
      return;
    }

    const prod = selectedProducts.find((p) => p.code === product.code);
    if (prod && product.isSchablon) {
      setInitialMeasure(prod.schablonQuantity ?? '');
      setInitialTimeMeasure(prod.schablonTimeQuantity ?? '');
      setInitialTimeMeasureSelection(prod._timeQuantitySelection ?? prod.schablonTimeQuantity ?? '');
      setInitialMusicClassToggle(product.musicClass === MusicClass.B);
    } else if (prod) {
      const isCustomMeasureInput =
        product.measureOptions.length > 0 &&
        product.measureOptions.every((o) => o.salesforceValue.toString() !== prod.quantity);
      setInitialCustomMeasureSelected(isCustomMeasureInput);
      setInitialMeasure(isCustomMeasureInput ? product.measureOptionsLastOptionName : prod.quantity);
      if (isCustomMeasureInput) {
        setInitialCustomMeasure(prod.quantity);
      }

      if (seasonSelectionEnabled) {
        setInitialCustomTimeMeasureSelected(false);
        setInitialTimeMeasure(prod.timeQuantity);
        setInitialTimeMeasureSelection(prod._timeQuantitySelection ?? prod.timeQuantity);
      } else {
        const isCustomTimeMeasureInput =
          product.timeMeasureOptions.length > 0 &&
          product.timeMeasureOptions.every((o) => o.salesforceValue.toString() !== prod.timeQuantity);
        setInitialCustomTimeMeasureSelected(isCustomTimeMeasureInput);
        setInitialTimeMeasure(isCustomTimeMeasureInput ? product.timeMeasureOptionsLastOptionName : prod.timeQuantity);
        setInitialTimeMeasureSelection(
          prod._timeQuantitySelection ??
            (isCustomTimeMeasureInput ? product.timeMeasureOptionsLastOptionName : prod.timeQuantity),
        );
        if (isCustomTimeMeasureInput) {
          setInitialCustomTimeMeasure(prod.timeQuantity);
        }
      }

      setInitialMusicClassToggle(prod.musicClass === MusicClass.B);

      setInitialStartDate(prod.startDate ?? dayjs().toString());
      setInitialEndDate(prod.endDate ?? dayjs().toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.code, showModal]);

  const measureInputParams: InputParams = {
    key: 'measureInput',
    type: product.measureInputType,
    min: product.measureMin,
    max: product.measureMax,
    prompt: product.measurePrompt,
    placeholder: product.measurePlaceholder,
    options: product.measureOptions,
    withCustomLastOption: product.measureOptionsWithCustomLastOption,
    customOptionSelectedFieldKey: 'customMeasureInputSelected',
    customOptionNameFieldKey: 'customMeasureInput',
    lastOptionName: product.measureOptionsLastOptionName,
    lastOptionPlaceholder: product.measureOptionsPlaceholder,
    lastOptionMin: product.measureOptionsMin,
    lastOptionMax: product.measureOptionsMax,
  };

  const timeMeasureInputParams: InputParams = {
    key: seasonSelectionEnabled ? '_timeMeasureInputSelection' : 'timeMeasureInput',
    type: product.timeMeasureInputType,
    min: product.timeMeasureMin,
    max: product.timeMeasureMax,
    prompt: product.timeMeasurePrompt,
    placeholder: product.timeMeasurePlaceholder,
    options: product.timeMeasureOptions,
    withCustomLastOption: product.timeMeasureOptionsWithCustomLastOption,
    customOptionSelectedFieldKey: `${seasonSelectionEnabled ? '_' : ''}customTimeMeasureInputSelected`,
    customOptionNameFieldKey: `${seasonSelectionEnabled ? '_' : ''}customTimeMeasureInput`,
    lastOptionName: product.timeMeasureOptionsLastOptionName,
    lastOptionPlaceholder: product.timeMeasureOptionsPlaceholder,
    lastOptionMin: product.timeMeasureOptionsMin,
    lastOptionMax: product.timeMeasureOptionsMax,
  };

  const getProductPrice = async (values: ProductConfigurationModalFormInputs) => {
    const priceProduct: PriceProduct = {
      code: product.code,
      quantity: values.customMeasureInputSelected ? values.customMeasureInput : values.measureInput,
      timeQuantity: values.timeMeasureInput,
      musicClass: values.musicClassToggleInput ? MusicClass.B : MusicClass.A,
    };

    const {
      data: { priceYearInclVat, priceYearExclVat },
    } = await apiGetPrice(priceProduct);

    return {
      priceYearInclVat: priceYearInclVat,
      priceYearExclVat: priceYearExclVat,
    };
  };

  const cleanUpValue = (value: string) => {
    return value.replace(/^0+/, '').replace('.', '').replace(' ', '');
  };

  const changingProduct = async (action: (prod: Product) => void, values: ProductConfigurationModalFormInputs) => {
    if (product.isSchablon) {
      const prod = selectedProducts.find((p) => p.code === product.code);
      const newProduct: Product = {
        code: product.code,
        quantity: prod?.quantity ?? '0',
        timeQuantity: prod?.timeQuantity ?? '0',
        musicClass: product.musicClass,
        priceYearInclVat: getSchablonPricePerMonth(product, prod?.priceYearInclVat ?? 0), // variable name priceYear not currently accurate as it is not always price per year
        priceYearExclVat: getSchablonPricePerMonth(product, prod?.priceYearExclVat ?? 0), // variable name priceYear not currently accurate as it is not always price per year
        schablonQuantity: cleanUpValue(values.measureInput),
        schablonTimeQuantity: cleanUpValue(values.timeMeasureInput),
        startDate: values.startDate,
        endDate: values.endDate,
        _timeQuantitySelection: values._timeMeasureInputSelection,
      };
      action(newProduct);
      setShowModal(false);
      return;
    }

    const start = Date.now();
    setQueryingApi(true);
    try {
      if (values.customMeasureInputSelected) {
        values.measureInput = values.customMeasureInput;
      }
      if (values.customTimeMeasureInputSelected) {
        values.timeMeasureInput = values.customTimeMeasureInput;
      }

      const { priceYearInclVat, priceYearExclVat } = await getProductPrice(values);
      const newProduct: Product = {
        code: product.code,
        quantity: cleanUpValue(values.measureInput),
        timeQuantity: cleanUpValue(values.timeMeasureInput),
        musicClass: values.musicClassToggleInput ? MusicClass.B : MusicClass.A,
        priceYearInclVat: Math.round(priceYearInclVat / 12),
        priceYearExclVat: Math.round(priceYearExclVat / 12),
        startDate: values.startDate,
        endDate: values.endDate,
        _timeQuantitySelection: values._timeMeasureInputSelection,
      };
      action(newProduct);
    } finally {
      const end = Date.now();
      setTimeout(() => {
        setQueryingApi(false);
        setShowModal(false);
      }, Math.max(MINIMUM_DELAY_FOR_SPINNER_IN_MS - (end - start), 0));
    }
  };

  const addProduct = async (values: ProductConfigurationModalFormInputs) =>
    changingProduct(selectedProductsActions.add, values);

  const editProduct = async (values: ProductConfigurationModalFormInputs) =>
    changingProduct(selectedProductsActions.replace, values);

  const removeProduct = () => {
    const prodIndex = selectedProducts.findIndex((p) => p.code === product.code);
    selectedProductsActions.remove(prodIndex);
    setShowModal(false);
  };

  const positiveNumberErrorMessage = cmsShopContent.productChoiceStep.productConfigurationModal.inputErrorMessage;

  const minMaxInputValidation = (min?: number, max?: number): RequiredNumberSchema<number | undefined> => {
    let schema = Yup.number()
      .integer(positiveNumberErrorMessage)
      .positive(positiveNumberErrorMessage)
      .typeError(positiveNumberErrorMessage)
      .required(cmsShopContent.inputRequiredError);

    if (min) {
      schema = schema.min(
        min,
        `${cmsShopContent.productChoiceStep.productConfigurationModal.inputTooLowErrorMessage.replace(
          '{x}',
          min.toString(),
        )}`,
      );
    }

    if (max) {
      schema = schema.max(
        max,
        `${cmsShopContent.productChoiceStep.productConfigurationModal.inputTooHighErrorMessage.replace(
          '{x}',
          max.toString(),
        )}`,
      );
    }

    return schema;
  };

  const validationSchema = Yup.object().shape(
    {
      measureInput: Yup.string().when('customMeasureInputSelected', {
        is: false,
        then: () => {
          if (product.hasMeasure) {
            return product.isSchablon
              ? minMaxInputValidation(product.measureSchablonMin, product.measureSchablonMax)
              : minMaxInputValidation(product.measureMin, product.measureMax);
          } else {
            return Yup.string().notRequired();
          }
        },
        otherwise: (schema) => schema.notRequired(),
      }),
      customMeasureInputSelected: Yup.boolean(),
      customMeasureInput: Yup.string().when('customMeasureInputSelected', {
        is: true,
        then: () => minMaxInputValidation(product.measureOptionsMin, product.measureOptionsMax),
        otherwise: (schema) => schema.notRequired(),
      }),
      timeMeasureInput: Yup.string().when('customTimeMeasureInputSelected', {
        is: false,
        then: () => {
          if (product.hasTimeMeasure) {
            return product.isSchablon
              ? minMaxInputValidation(product.timeMeasureSchablonMin, product.timeMeasureSchablonMax)
              : minMaxInputValidation(product.timeMeasureMin, product.timeMeasureMax);
          } else {
            return Yup.string().notRequired();
          }
        },
        otherwise: (schema) => schema.notRequired(),
      }),
      customTimeMeasureInputSelected: Yup.boolean(),
      customTimeMeasureInput: Yup.string().when('customTimeMeasureInputSelected', {
        is: true,
        then: () => minMaxInputValidation(product.timeMeasureOptionsMin, product.timeMeasureOptionsMax),
        otherwise: (schema) => schema.notRequired(),
      }),
      musicClassToggleInput: Yup.boolean().when('musicClassToggleInput', () => {
        if (!product.reporting && product.musicClass.length === 1) {
          return Yup.boolean().notRequired();
        } else {
          return Yup.boolean().required(positiveNumberErrorMessage);
        }
      }),
      startDate: Yup.date().when(['endDate'], {
        is: (endDate: Date) => !!endDate,
        then: Yup.date().required(cmsShopContent.invalidDateRangeError),
      }),
      endDate: Yup.date().when(['startDate'], {
        is: (startDate: Date) => !!startDate,
        then: Yup.date().required(cmsShopContent.invalidDateRangeError),
      }),
    },
    // cyclic dependency (see https://stackoverflow.com/a/72402785)
    [
      ['musicClassToggleInput', 'musicClassToggleInput'],
      ['startDate', 'endDate'],
    ],
  );

  const valuesHaveNotChanged = (values: ProductConfigurationModalFormInputs) => {
    return (
      (product.hasMeasure || product.hasTimeMeasure) &&
      values.measureInput === initialMeasure &&
      values.customMeasureInputSelected === initialCustomMeasureSelected &&
      values.customMeasureInput === initialCustomMeasure &&
      values.timeMeasureInput === initialTimeMeasure &&
      values._timeMeasureInputSelection === initialTimeMeasureSelection &&
      values.musicClassToggleInput === initialMusicClassToggle &&
      values.customTimeMeasureInputSelected === initialCustomTimeMeasureSelected &&
      values.customTimeMeasureInput === initialCustomTimeMeasure &&
      values.startDate === initialStartDate &&
      values.endDate === initialEndDate
    );
  };

  return (
    <Formik
      onSubmit={(values) => {
        if (valuesHaveNotChanged(values)) {
          setShowModal(false);
          return;
        }

        if (editMode) {
          editProduct(values);
        } else {
          addProduct(values);
        }
      }}
      initialValues={{
        measureInput: initialMeasure,
        customMeasureInputSelected: initialCustomMeasureSelected,
        customMeasureInput: initialCustomMeasure,
        timeMeasureInput: initialTimeMeasure,
        _timeMeasureInputSelection: initialTimeMeasureSelection,
        customTimeMeasureInputSelected: initialCustomTimeMeasureSelected,
        customTimeMeasureInput: initialCustomTimeMeasure,
        musicClassToggleInput: initialMusicClassToggle,
        startDate: initialStartDate,
        endDate: initialEndDate,
      }}
      initialTouched={{
        startDate: true,
        endDate: true,
      }}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize={true}
    >
      {({ isValid, submitForm, values }) => (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          header={<div>{product.name}</div>}
          description={<Conditional condition={!queryingApi} trueRender={<div>{product.description}</div>} />}
          outsideClickCloses={!queryingApi}
          content={
            <Conditional
              condition={queryingApi}
              trueRender={
                <div className="text-center">
                  <LoadingSpinner size={42} />
                </div>
              }
              falseRender={
                <>
                  <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-10">
                    <Conditional
                      condition={product.hasMeasure}
                      trueRender={<ProductInput code={product.code} inputParams={measureInputParams} type="Measure" />}
                    />
                    {(seasonSelectionEnabled && !!(product.startDatePrompt && product.endDatePrompt) && (
                      <>
                        <Conditional
                          condition={product.hasTimeMeasure}
                          trueRender={
                            <ProductInput code={product.code} inputParams={timeMeasureInputParams} type="TimeMeasure" />
                          }
                        />
                        <UserDateInput
                          name="startDate"
                          prompt={product.startDatePrompt}
                          inputFormat="DD/MM"
                          allowPastDates
                          dateLimitLate={values.endDate}
                        />
                        <UserDateInput
                          name="endDate"
                          prompt={product.endDatePrompt}
                          inputFormat="DD/MM"
                          allowPastDates
                          dateLimitEarly={values.startDate}
                        />
                      </>
                    )) || (
                      <Conditional
                        condition={product.hasTimeMeasure}
                        trueRender={
                          <ProductInput code={product.code} inputParams={timeMeasureInputParams} type="TimeMeasure" />
                        }
                      />
                    )}
                    <Conditional
                      condition={!product.isSchablon && product.musicClass.includes('B')}
                      trueRender={
                        <UserBooleanInput
                          name="musicClassToggleInput"
                          title={cmsShopContent.productChoiceStep.productConfigurationModal.radioTvTitle}
                          description={cmsShopContent.productChoiceStep.productConfigurationModal.radioTvDescription}
                        />
                      }
                    />
                    <Conditional
                      condition={seasonSelectionEnabled}
                      trueRender={
                        <DayCount
                          formikParams={{ key: 'timeMeasureInput' }}
                          title={cmsShopContent.productChoiceStep.productConfigurationModal.dayCountTitle}
                          description={cmsShopContent.productChoiceStep.productConfigurationModal.dayCountDescription}
                          startDate={values.startDate}
                          endDate={values.endDate}
                          daysPerYear={values._timeMeasureInputSelection}
                        />
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="startDate"
                    render={(error) => (
                      <div className="flex flex-col mt-2 pl-4 text-sm font-medium text-sami-red">{error}</div>
                    )}
                  />
                  <ErrorMessage
                    name="endDate"
                    render={(error) => (
                      <div className="flex flex-col mt-2 pl-4 text-sm font-medium text-sami-red">{error}</div>
                    )}
                  />
                </>
              }
            />
          }
          actions={
            <Conditional
              condition={!queryingApi && editMode}
              trueRender={
                <div className="w-full flex flex-row items-center">
                  <Button
                    text={cmsShopContent.productChoiceStep.removeProductButton}
                    onClick={removeProduct}
                    textColor={'text-sami-red'}
                  />
                  <Conditional
                    condition={!product.reporting || product.isSchablon}
                    trueRender={
                      <div className="ml-auto">
                        <Button
                          filled
                          text={cmsShopContent.productChoiceStep.productConfigurationModal.saveButton}
                          disabled={!isValid}
                          onClick={submitForm}
                        />
                      </div>
                    }
                  />
                </div>
              }
              falseRender={
                <div className="w-fit mx-auto">
                  <Conditional
                    condition={queryingApi}
                    trueRender={<></>}
                    falseRender={
                      <Button
                        filled
                        text={cmsShopContent.productChoiceStep.addProductButton}
                        disabled={!isValid}
                        onClick={submitForm}
                      />
                    }
                  />
                </div>
              }
            />
          }
        />
      )}
    </Formik>
  );
};
