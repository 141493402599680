import { FC } from 'react';
import { ShopContent } from '../../../types/cms_shared_types';
import { DefaultBottomBar } from './DefaultBottomBar';

interface CancelBottomBarProps {
  onClick: () => void;
  cmsShopContent: ShopContent;
}

export const CancelBottomBar: FC<CancelBottomBarProps> = ({ onClick, cmsShopContent }) => {
  return (
    <DefaultBottomBar
      text={cmsShopContent.signingPageStep.cancelButton}
      continueButtonDisabled={false}
      onClick={onClick}
      blackButton
      iconLeft={<></>}
      iconRight={<></>}
      cmsShopContent={cmsShopContent}
    />
  );
};
