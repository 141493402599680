import React, { useEffect } from 'react';
import { findUserInputResourceByKey } from '../../../helper/cms';
import { ShopContent } from '../../../types/cms_shared_types';
import { InputMode, UserInput } from '../../Common/UserInput';

interface ContactInformationProps {
  isReportingContact?: boolean;
  cmsShopContent: ShopContent;
}

export const ContactInformation: React.FC<ContactInformationProps> = ({ isReportingContact, cmsShopContent }) => {
  const scrollTo = React.useRef<HTMLInputElement>(null);
  const executeScroll = () => scrollTo?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  useEffect(() => {
    executeScroll();
  }, []);

  const firstNameInput = findUserInputResourceByKey('first_name_input', cmsShopContent.contactStep.userInputs);
  const lastNameInput = findUserInputResourceByKey('last_name_input', cmsShopContent.contactStep.userInputs);
  const emailInput = findUserInputResourceByKey('email_input', cmsShopContent.contactStep.userInputs);
  const telephoneNumberInput = findUserInputResourceByKey(
    'telephone_number_input',
    cmsShopContent.contactStep.userInputs,
  );
  const emailAccountingInput = findUserInputResourceByKey(
    'email_accounting_input',
    cmsShopContent.contactStep.userInputs,
  );
  const telephoneNumberAccountingInput = findUserInputResourceByKey(
    'telephone_number_accounting_input',
    cmsShopContent.contactStep.userInputs,
  );

  return (
    <div ref={scrollTo}>
      <div className="flex flex-col sm:flex-row gap-x-4">
        <UserInput
          name={`${isReportingContact ? 'reporting' : 'contact'}FirstNameInput`}
          prompt={firstNameInput.prompt}
          placeholder={firstNameInput.placeholder}
        />

        <UserInput
          name={`${isReportingContact ? 'reporting' : 'contact'}LastNameInput`}
          prompt={lastNameInput.prompt}
          placeholder={lastNameInput.placeholder}
        />
      </div>

      <UserInput
        name={`${isReportingContact ? 'reporting' : 'contact'}EmailInput`}
        prompt={isReportingContact ? emailAccountingInput.prompt : emailInput.prompt}
        placeholder={isReportingContact ? emailAccountingInput.placeholder : emailInput.placeholder}
        inputMode={InputMode.Email}
      />

      <UserInput
        name={`${isReportingContact ? 'reporting' : 'contact'}TelephoneNumberInput`}
        prompt={isReportingContact ? telephoneNumberAccountingInput.prompt : telephoneNumberInput.prompt}
        placeholder={isReportingContact ? telephoneNumberAccountingInput.placeholder : telephoneNumberInput.placeholder}
        inputMode={InputMode.PhoneNumber}
      />
    </div>
  );
};
