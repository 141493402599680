import { FC, useEffect, useRef, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { Header } from '../Header/Header';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { ProductFamily } from './ProductFamily/ProductFamily';
import { ProductChoice } from './ProductChoice/ProductChoice';
import { apiPostAgreement } from '../../api/api';
import {
  retrieveCmsProduct,
  findSelectedCategoryByKey,
  findSelectedSubcategoryByKey,
  getPriceSum,
} from '../../helper/cms';
import { Conditional } from '../Common/Conditional';
import { Summary } from './Summary/Summary';
import { ConfirmationDialog } from './ConfirmationDialog';
import { Information } from './InformationStep/Information';
import { Invoice } from './InvoiceStep/Invoice';
import { Contact } from './ContactStep/Contact';
import dayjs from 'dayjs';
import { DEFAULT_LANGUAGE } from '../Header/LanguageDropdown';
import { Product, ProductListActions } from '../../types/webshop';
import { LandingPage } from './LandingPage/LandingPage';
import { SigningPage } from './SigningPage/SigningPage';
import { ShopContent } from '../../types/cms_shared_types';
import {
  AgreementParams,
  ContactFormInputs,
  InformationFormInputs,
  InvoiceFormInputs,
  ShopForm,
} from '../../types/api_shared_types';
import { addSpaceDelimiter } from '../../helper/helper';
import { trackEvent } from '../../helper/analytics';

export enum Steps {
  PRODUCT_FAMILY,
  PRODUCT_CHOICE,
  SUMMARY,
  INFORMATION,
  INVOICE,
  CONTACT,
  SIGNING_PAGE,
  LANDING_PAGE,
}

export const SELECTED_CATEGORY_KEY = 'selected_category_key';

const INVALIDATE_CACHE_DAYS = 1;

interface WebshopFormProps {
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  cmsShopContent: ShopContent;
  referenceNumber: string | null;
}

export const WebshopForm: FC<WebshopFormProps> = ({
  selectedProducts,
  selectedProductsActions,
  cmsShopContent,
  referenceNumber,
}) => {
  const [language] = useLocalStorageState<string>('language', { defaultValue: DEFAULT_LANGUAGE });

  const [currentStep, setCurrentStep, { removeItem: removeCurrentStep }] = useLocalStorageState<Steps>('current_step', {
    defaultValue: Steps.PRODUCT_FAMILY,
  });

  const [price, setPrice] = useState('');
  const [vatPrice, setVatPrice] = useState('');

  useEffect(() => {
    const { sumPrice, sumPriceVat } = getPriceSum(selectedProducts);
    setPrice(addSpaceDelimiter(sumPrice.toString()));
    setVatPrice(addSpaceDelimiter((sumPriceVat - sumPrice).toString()));

    if (currentStep === Steps.SUMMARY && selectedProducts.length === 0) {
      setCurrentStep(currentStep - 1);
    }
  }, [selectedProducts, currentStep, setCurrentStep]);

  const [selectedCategoryKey, setSelectedCategoryKey, { removeItem: removeSelectedCategoryKey }] = useLocalStorageState<
    string | undefined
  >(SELECTED_CATEGORY_KEY, { defaultValue: undefined });

  const [selectedSubcategoryKey, setSelectedSubcategoryKey, { removeItem: removeSelectedSubcategoryKey }] =
    useLocalStorageState<string | undefined>('selected_subcategory_key', { defaultValue: undefined });

  const [contractId, setContractId] = useState<number>();
  const [contractAccessLink, setContractAccessLink] = useState('');

  const signingCancelled = useRef(false);
  const [signingErrorOccurred, setSigningErrorOccurred, { removeItem: removeSigningErrorOccurred }] =
    useLocalStorageState<boolean>('signing_error', { defaultValue: false });

  const [lastVisitDate, setLastVisitDate, { removeItem: removeLastVisitDate }] = useLocalStorageState<string>(
    'last_visit_date',
    { defaultValue: dayjs().toString() },
  );

  const resetLocalStorage = (keepCategory = false) => {
    // eslint-disable-next-line no-console
    console.warn('resetting local storage');
    setCurrentStep(Steps.PRODUCT_FAMILY);
    if (!keepCategory) {
      setSelectedCategoryKey(undefined);
    }
    setSelectedSubcategoryKey(undefined);
    setContractId(undefined);
    selectedProductsActions.reset();
  };

  const clearLocalStorage = (keepCurrentStepInfo = true) => {
    if (keepCurrentStepInfo) {
      removeCurrentStep();
      removeSigningErrorOccurred();
    }
    removeSelectedCategoryKey();
    removeSelectedSubcategoryKey();
    removeSummaryFormComment();
    removeInformationFormInputs();
    removeInvoiceFormInputs();
    removeContactFormInputs();
    removeLastVisitDate();
    selectedProductsActions.reset();
  };

  useEffect(() => {
    // Check if we should invalidate the local storage
    if (lastVisitDate) {
      const lastVisit = dayjs(lastVisitDate);
      const diff = dayjs().diff(lastVisit, 'day');
      if (diff > INVALIDATE_CACHE_DAYS) {
        clearLocalStorage();
      }

      setLastVisitDate(dayjs().toString());
    }

    // If either the category key or the subcategory key are no longer valid (either because they
    // were manually changed locally, or because the actual keys have been changed in the CMS),
    // we reset the local storage
    if (selectedCategoryKey) {
      const category = findSelectedCategoryByKey(cmsShopContent.categories, selectedCategoryKey);
      if (category) {
        const subcategory = findSelectedSubcategoryByKey(selectedSubcategoryKey, category.subcategories);
        if (subcategory && selectedProducts.length > 0) {
          return;
        }
        resetLocalStorage(true);
        setCurrentStep(Steps.PRODUCT_FAMILY);
      }
    }

    resetLocalStorage();
    setCurrentStep(Steps.PRODUCT_FAMILY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [summaryFormComment, setSummaryFormComment, { removeItem: removeSummaryFormComment }] =
    useLocalStorageState<string>('summary_form_comment', {
      defaultValue: '',
    });

  const [informationFormInputs, setInformationFormInputs, { removeItem: removeInformationFormInputs }] =
    useLocalStorageState<InformationFormInputs>('information_form_inputs', {
      defaultValue: {
        businessNameInput: '',
        businessCategoryInput: '',
        businessAddressInput: '',
        businessPostNumberInput: '',
        businessCityInput: '',
      },
    });

  const [invoiceFormInputs, setInvoiceFormInputs, { removeItem: removeInvoiceFormInputs }] =
    useLocalStorageState<InvoiceFormInputs>('invoice_form_inputs', {
      defaultValue: {
        organisationNumberInput: '',
        companyNameInput: '',
        companyAddressInput: '',
        companyPostNumberInput: '',
        companyCityInput: '',
        companyCountryInput: '',
        sameInvoiceAddressInput: true,
        invoiceCareOfInput: '',
        invoiceAddressInput: '',
        invoicePostNumberInput: '',
        invoiceCityInput: '',
        invoiceCountryInput: '',
        startDateInput: dayjs().format('YYYY-MM-DD'),
        invoicePeriodInput: '',
        emailAddressPdfInput: '',
        paperInvoiceInput: false,
      },
    });

  const [contactFormInputs, setContactFormInputs, { removeItem: removeContactFormInputs }] =
    useLocalStorageState<ContactFormInputs>('contact_form_inputs', {
      defaultValue: {
        contactFirstNameInput: '',
        contactLastNameInput: '',
        contactEmailInput: '',
        contactTelephoneNumberInput: '',

        sameReportingContactInput: true,

        reportingFirstNameInput: '',
        reportingLastNameInput: '',
        reportingEmailInput: '',
        reportingTelephoneNumberInput: '',

        acceptTermsAndConditions: false,
      },
    });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => {
    setInformationFormInputs({ ...informationFormInputs, businessCategoryInput: '' });
    setInvoiceFormInputs({ ...invoiceFormInputs, invoicePeriodInput: '' });
    setContactFormInputs({ ...contactFormInputs, sameReportingContactInput: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  useEffect(() => {
    setSummaryFormComment('');
  }, [selectedSubcategoryKey, setSummaryFormComment]);

  const previousButtonClicked = () => {
    if (currentStep === Steps.PRODUCT_CHOICE && selectedProducts.length > 0) {
      setShowConfirmationDialog(true);
      return;
    }

    setCurrentStep(currentStep - 1);

    if (currentStep === Steps.PRODUCT_CHOICE) {
      setSelectedSubcategoryKey(undefined);
    }
  };

  const nextButtonClicked = () => {
    setCurrentStep(currentStep + 1);
  };

  const atLeastOneReportingProduct = () => {
    return selectedProducts.some(
      (p) =>
        retrieveCmsProduct(p.code, cmsShopContent.categories, selectedCategoryKey, selectedSubcategoryKey)?.reporting,
    );
  };

  const render = () => {
    if (!cmsShopContent) {
      return;
    }

    const analyticsCategoryIndicator = `${selectedCategoryKey ?? ''}${
      selectedCategoryKey && selectedSubcategoryKey ? '::' : ''
    }${selectedSubcategoryKey ?? ''}`;

    if (currentStep === Steps.PRODUCT_FAMILY) {
      return (
        <ProductFamily
          selectedCategoryKey={selectedCategoryKey}
          setSelectedCategoryKey={setSelectedCategoryKey}
          nextButtonClicked={() => {
            trackEvent('SelectedCateogry', analyticsCategoryIndicator);
            nextButtonClicked();
          }}
          cmsShopContent={cmsShopContent}
        />
      );
    } else if (currentStep === Steps.PRODUCT_CHOICE) {
      const category = findSelectedCategoryByKey(cmsShopContent.categories, selectedCategoryKey);
      if (!category) {
        return <></>;
      }
      return (
        <ProductChoice
          category={category}
          selectedSubcategoryKey={selectedSubcategoryKey}
          setSelectedSubcategoryKey={setSelectedSubcategoryKey}
          selectedProducts={selectedProducts}
          selectedProductsActions={selectedProductsActions}
          nextButtonClicked={() => {
            trackEvent('SelectedSubcategory', analyticsCategoryIndicator);
            nextButtonClicked();
          }}
          cmsShopContent={cmsShopContent}
        />
      );
    } else if (currentStep === Steps.SUMMARY) {
      return (
        <Summary
          selectedProducts={selectedProducts}
          selectedProductsActions={selectedProductsActions}
          price={price}
          vatPrice={vatPrice}
          selectedCategoryKey={selectedCategoryKey}
          selectedSubcategoryKey={selectedSubcategoryKey}
          summaryFormComment={summaryFormComment}
          setSummaryFormComment={setSummaryFormComment}
          nextButtonClicked={() => {
            trackEvent('ConfirmedPrice', analyticsCategoryIndicator);
            nextButtonClicked();
          }}
          cmsShopContent={cmsShopContent}
        />
      );
    } else if (currentStep === Steps.INFORMATION) {
      return (
        <Information
          setInformationFormInputs={setInformationFormInputs}
          informationFormInputs={informationFormInputs}
          hasSpecialShoppingProduct={selectedCategoryKey === 'butik' && selectedSubcategoryKey === 'butik'}
          nextButtonClicked={() => {
            trackEvent('SubmittedLocation', analyticsCategoryIndicator);
            nextButtonClicked();
          }}
          cmsShopContent={cmsShopContent}
        />
      );
    } else if (currentStep === Steps.INVOICE) {
      return (
        <Invoice
          price={price}
          selectedCategoryKey={selectedCategoryKey}
          selectedSubcategoryKey={selectedSubcategoryKey}
          invoiceFormInputs={invoiceFormInputs}
          setInvoiceFormInputs={setInvoiceFormInputs}
          atLeastOneReportingProduct={atLeastOneReportingProduct()}
          nextButtonClicked={() => {
            trackEvent('SubmittedPaymentDetails', analyticsCategoryIndicator);
            nextButtonClicked();
          }}
          cmsShopContent={cmsShopContent}
          language={language}
        />
      );
    } else if (currentStep === Steps.CONTACT) {
      return (
        <Contact
          selectedProducts={selectedProducts}
          selectedCategoryKey={selectedCategoryKey}
          selectedSubcategoryKey={selectedSubcategoryKey}
          comments={summaryFormComment}
          informationFormInputs={informationFormInputs}
          invoiceFormInputs={invoiceFormInputs}
          contactFormInputs={contactFormInputs}
          setContactFormInputs={setContactFormInputs}
          startOneflowAgreementSigning={async (shopForm: ShopForm) => {
            signingCancelled.current = false;
            const params: AgreementParams = { language, shopForm, referenceNumber };
            try {
              const res = await apiPostAgreement(params);
              if (signingCancelled.current) {
                return;
              }
              setContractAccessLink(res.data.access_link);
              window.open(res.data.access_link, '_blank');

              const contractId = Number(res.data.contractId);
              setContractId(contractId);

              trackEvent('CreatedAgreement', analyticsCategoryIndicator);
            } catch {
              setSigningErrorOccurred(true);
              setCurrentStep(Steps.LANDING_PAGE);
              return;
            }
            setCurrentStep(Steps.SIGNING_PAGE);
          }}
          atLeastOneReportingProduct={atLeastOneReportingProduct()}
          cmsShopContent={cmsShopContent}
          referenceNumber={referenceNumber}
        />
      );
    } else if (currentStep === Steps.SIGNING_PAGE) {
      return (
        <SigningPage
          contractId={contractId}
          contractAccessLink={contractAccessLink}
          onCancelSigning={async () => {
            signingCancelled.current = true;
            setCurrentStep(Steps.CONTACT);
            setContractAccessLink('');
          }}
          cmsShopContent={cmsShopContent}
          setCurrentStep={setCurrentStep}
          setSigningErrorOccurred={setSigningErrorOccurred}
          analyticsCategoryIndicator={analyticsCategoryIndicator}
        />
      );
    } else if (currentStep === Steps.LANDING_PAGE) {
      return (
        <LandingPage
          isError={signingErrorOccurred}
          onSuccessButtonClick={() => {
            const url = cmsShopContent.landingPageStep.buttonLinkUrl;
            clearLocalStorage(false);
            window.location.href = url;
          }}
          onErrorButtonClick={() => {
            setSigningErrorOccurred(false);
            setCurrentStep(Steps.CONTACT);
          }}
          cmsShopContent={cmsShopContent}
        />
      );
    } else {
      return;
    }
  };

  return (
    <div>
      <div>
        <Header cmsContent={cmsShopContent} />

        <Breadcrumbs
          currentStep={currentStep}
          selectedCategoryKey={selectedCategoryKey}
          selectedSubcategoryKey={selectedSubcategoryKey}
          price={price}
          vatPrice={vatPrice}
          selectedProducts={selectedProducts}
          selectedProductsActions={selectedProductsActions}
          onBackClick={previousButtonClicked}
          cmsShopContent={cmsShopContent}
        />

        {render()}
      </div>

      <Conditional
        condition={showConfirmationDialog}
        trueRender={
          <ConfirmationDialog
            showModal={true}
            setShowModal={setShowConfirmationDialog}
            onYesCallback={() => {
              selectedProductsActions.reset();
              setSelectedSubcategoryKey(undefined);
              setCurrentStep(currentStep - 1);
            }}
            title={cmsShopContent.confirmationDialog.titleWhenSwitchingVerksamhetstyp}
            description={cmsShopContent.confirmationDialog.descriptionWhenSwitchingVerksamhetstyp}
            cmsShopContent={cmsShopContent}
          />
        }
      />
    </div>
  );
};
