import { parsePhoneNumber } from 'libphonenumber-js';
import { validateCharacters } from '../helper/validation';

const defaultCountryCode = 'SE';

export function validatePhoneNumber(phoneNumber?: string): boolean {
  try {
    return phoneNumber ? !!parsePhoneNumber(phoneNumber, defaultCountryCode) && validateCharacters(phoneNumber) : false;
  } catch {
    return false;
  }
}

export function parseAndFormatPhoneNumber(phoneNumber: string): string {
  try {
    return parsePhoneNumber(phoneNumber, defaultCountryCode).formatInternational().replaceAll(' ', '');
  } catch {
    return '';
  }
}
