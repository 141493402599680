import { FC } from 'react';
import { CategoryItem } from './CategoryItem';
import { Tips } from './Tips';
import { findSelectedCategoryByKey } from '../../../helper/cms';
import { Category, RichText, ShopContent } from '../../../types/cms_shared_types';

interface CategoryGridProps {
  selectedCategoryKey?: string;
  setSelectedCategoryKey: (key?: string) => void;
  cmsShopContent: ShopContent;
}

export const CategoryGrid: FC<CategoryGridProps> = ({
  selectedCategoryKey,
  setSelectedCategoryKey,
  cmsShopContent,
}) => {
  const getTitle = (): string | undefined => {
    if (selectedCategoryKey) {
      return findSelectedCategoryByKey(cmsShopContent.categories, selectedCategoryKey)?.subtitle;
    } else {
      return cmsShopContent.productFamilyStep.tipTitle ?? '';
    }
  };

  const getContent = (): RichText | undefined => {
    if (selectedCategoryKey) {
      return findSelectedCategoryByKey(cmsShopContent.categories, selectedCategoryKey)?.explanation;
    } else {
      return cmsShopContent.productFamilyStep.tipContent;
    }
  };

  return (
    <div className="sm:w-fit mx-auto">
      <div className="mb-8 grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-6 justify-items-center justify-around gap-3">
        {cmsShopContent.categories.map((category: Category) => (
          <CategoryItem
            isSelected={selectedCategoryKey === category.key}
            onClick={(): void =>
              setSelectedCategoryKey(category.key === selectedCategoryKey ? undefined : category.key)
            }
            key={category.key}
            category={category}
          />
        ))}
      </div>

      <Tips title={getTitle()} content={getContent()} />
    </div>
  );
};
