import { FC, MouseEvent } from 'react';
import { CaretDown, Info } from 'phosphor-react';
import { Button } from '../../Common/Button';

interface HelpButtonProps {
  text: string;
  onClick: (e?: MouseEvent) => void;
}

export const HelpButton: FC<HelpButtonProps> = ({ text, onClick }) => {
  return (
    <>
      <div className="hidden md:block pl-20">
        <Button
          large
          text={text}
          textColor="text-text-dark"
          rightIcon={<CaretDown className="text-text-dark" size={22} />}
          onClick={onClick}
          hover
        />
      </div>

      <div className="md:hidden pl-0 flex flex-row items-center">
        <button onClick={onClick} className="p-3 bg-text-gray rounded-full">
          <Info size={24} className="text-white" />
        </button>
      </div>
    </>
  );
};
