import { FC, useCallback, useEffect, useState } from 'react';
import { Category, CmsProduct, ShopContent } from '../../../types/cms_shared_types';
import { ProductCard } from './ProductCard';
import { useFormikContext } from 'formik';
import { ProductChoiceFormInput } from './ProductChoice';
import { Product, ProductListActions } from '../../../types/webshop';
import { ExtraProductDrawer } from './ExtraProductDrawer';
import { Conditional } from '../../Common/Conditional';
import { ProductConfigurationModal } from './ProductConfigurationModal/ProductConfigurationModal';

const SHOW_ALL_PRODUCTS_THRESHOLD = 4;

interface ProductChoiceListProps {
  category: Category;
  setSelectedSubcategoryKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  cmsShopContent: ShopContent;
}

export const ProductChoiceList: FC<ProductChoiceListProps> = ({
  category,
  setSelectedSubcategoryKey,
  selectedProducts,
  selectedProductsActions,
  cmsShopContent,
}) => {
  const [productsOnDisplay, setProductsOnDisplay] = useState<CmsProduct[]>([]);
  const [productsInExtra, setProductsInExtra] = useState<CmsProduct[]>([]);
  const { values, validateForm } = useFormikContext<ProductChoiceFormInput>();

  const [showProductConfigurationModal, setShowProductConfigurationModal] = useState(false);
  const [showExtraProductsDrawer, setShowExtraProductsDrawer] = useState(false);

  const [currentProduct, setCurrentProduct] = useState<CmsProduct>();
  const [modalEditMode, setModalEditMode] = useState(false);

  useEffect(() => {
    validateForm(values);
  }, [selectedProducts, validateForm, values]);

  useEffect(() => {
    const subcategory = category.subcategories.find((sc) => sc.key === values.selectedSubcategoryKey);
    if (!subcategory) {
      return;
    }

    if (
      selectedProducts.some(
        (p) => ![...subcategory.products, ...subcategory.extraProducts].find((pp) => pp.code === p.code),
      )
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `There is a mismatch between the selected products found in local storage and the products available in '${category.key}' > '${subcategory.key}'. This can occur if the subcategory was modified in the local storage state. To fix this, we're going to reset the list of selected products.`,
      );
      selectedProductsActions.reset();
    }

    const newProductsOnDisplay: CmsProduct[] = subcategory.products.filter((p) => {
      // Sometimes, the application can find itself in a state where an extra product will also be in the "main" products.
      // To fix this, we simply consider the "main" products as the ones that aren't also in extra products.
      return !subcategory.extraProducts.find((pp) => pp.code === p.code);
    });
    const newProductsInExtra: CmsProduct[] = [];

    subcategory.extraProducts.forEach((extraProduct) => {
      if (selectedProducts.some((p) => p.code === extraProduct.code)) {
        if (!newProductsOnDisplay.includes(extraProduct)) {
          newProductsOnDisplay.push(extraProduct);
        }
      } else {
        newProductsInExtra.push(extraProduct);
      }
    });

    if (newProductsOnDisplay.length <= SHOW_ALL_PRODUCTS_THRESHOLD) {
      setProductsOnDisplay([...newProductsOnDisplay, ...newProductsInExtra]);
      setProductsInExtra([]);
    } else {
      setProductsOnDisplay(newProductsOnDisplay);
      setProductsInExtra(newProductsInExtra);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.subcategories, values.selectedSubcategoryKey]);

  // immediately store any change to local storage
  useEffect(() => {
    setSelectedSubcategoryKey(values.selectedSubcategoryKey);
  }, [setSelectedSubcategoryKey, values.selectedSubcategoryKey]);

  const moveSelectedExtraProductsToDisplayList = useCallback(() => {
    const newProductsOnDisplay: CmsProduct[] = [...productsOnDisplay];
    const newProductsInExtra: CmsProduct[] = [];

    const selectedExtraProducts = productsInExtra.filter((p) => selectedProducts.some((pp) => pp.code === p.code));

    selectedExtraProducts.forEach((prod: CmsProduct) => {
      newProductsOnDisplay.push(prod);
    });

    productsInExtra.forEach((prod: CmsProduct) => {
      if (!selectedExtraProducts.includes(prod)) {
        newProductsInExtra.push(prod);
      }
    });

    setProductsOnDisplay(newProductsOnDisplay);
    setProductsInExtra(newProductsInExtra);
  }, [productsInExtra, productsOnDisplay, selectedProducts]);

  useEffect(() => {
    const allExtraProductsHaveBeenAdded = productsInExtra.every((p) =>
      selectedProducts.find((pp) => pp.code === p.code),
    );
    if (showExtraProductsDrawer && allExtraProductsHaveBeenAdded) {
      moveSelectedExtraProductsToDisplayList();
    }
  }, [moveSelectedExtraProductsToDisplayList, productsInExtra, selectedProducts, showExtraProductsDrawer]);

  const displayProduct = (prod: CmsProduct, isWithinGroup = false) => {
    return (
      <ProductCard
        key={prod.code}
        product={prod}
        selectedProducts={selectedProducts}
        selectedProductsActions={selectedProductsActions}
        openProductConfigurationModal={() => {
          setCurrentProduct(prod);
          setModalEditMode(selectedProducts.some((p) => p.code === prod.code));
          setShowProductConfigurationModal(true);
        }}
        cmsShopContent={cmsShopContent}
        isWithinGroup={isWithinGroup}
      />
    );
  };

  const displayProducts = (products: CmsProduct[]) => {
    const productGroups: Record<string, CmsProduct[]> = {};
    for (const prod of products) {
      if (prod.productGroupIdentifier) {
        const identifier = prod.productGroupIdentifier.trim();
        productGroups[identifier] = productGroups[identifier] || [];
        productGroups[identifier].push(prod);
      }
    }
    return (
      <>
        {Object.keys(productGroups)
          .sort((a, b) => a.localeCompare(b, 'sv-SE'))
          .map((productGroupIdentifier) => {
            const groupProducts = productGroups[productGroupIdentifier];
            const anySelectedProducts = !!groupProducts.find((product) =>
              selectedProducts.find((p) => p.code === product.code),
            );
            // Only show the identifier if the format isn't "(<identifier text>)", this is explained in Storyblok
            const showIdentifier = !(productGroupIdentifier.startsWith('(') && productGroupIdentifier.endsWith(')'));
            return (
              <div
                key={productGroupIdentifier}
                className={`${
                  anySelectedProducts ? 'border border-sami-green' : 'border border-sami-card-border'
                } flex flex-col pt-2 mb-4 rounded-lg shadow hover:shadow-md`}
              >
                {showIdentifier && <div className="pl-6 py-2">{productGroupIdentifier}</div>}
                {groupProducts.map((prod) => displayProduct(prod, true))}
              </div>
            );
          })}

        {products.filter((prod) => !prod.productGroupIdentifier).map((prod) => displayProduct(prod))}
      </>
    );
  };

  return (
    <div className="w-full sm:max-w-[600px] sm:w-fit flex flex-col items-center">
      {displayProducts(productsOnDisplay)}

      <Conditional
        condition={productsInExtra.length > 0}
        trueRender={
          <ExtraProductDrawer
            showExtraProductsDrawer={showExtraProductsDrawer}
            setShowExtraProductsDrawer={(value) => {
              if (!value) {
                moveSelectedExtraProductsToDisplayList();
              }
              setShowExtraProductsDrawer(value);
            }}
            productsInExtra={productsInExtra}
            displayProductsFunc={displayProducts}
            cmsShopContent={cmsShopContent}
          />
        }
      />

      {currentProduct && (
        <Conditional
          condition={showProductConfigurationModal}
          trueRender={
            <ProductConfigurationModal
              product={currentProduct}
              editMode={modalEditMode}
              showModal={showProductConfigurationModal}
              setShowModal={setShowProductConfigurationModal}
              selectedProducts={selectedProducts}
              selectedProductsActions={selectedProductsActions}
              cmsShopContent={cmsShopContent}
            />
          }
        />
      )}
    </div>
  );
};
