import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { RichText } from '../../../types/cms_shared_types';

interface TipsProps {
  title?: string;
  content?: RichText;
}

export const Tips: FC<TipsProps> = ({ title, content }) => {
  return (
    <div className="max-w-xl max-h-36 container mx-auto">
      {title && <div className={`text-xl sm:text-2xl font-extrabold text-center text-text-dark mb-4`}>{title}</div>}
      <div className="text-sm sm:text-base font-medium text-center text-text-dark mx-7 sm:mx-2">{render(content)}</div>
    </div>
  );
};
