import { FC } from 'react';
import { BreadcrumbStep } from './BreadcrumbStep';

interface BreadcrumbListProps {
  steps: string[];
  currentStep: number;
}

export const BreadcrumbList: FC<BreadcrumbListProps> = ({ steps, currentStep }) => {
  return (
    <>
      <div className="lg:flex flex-row place-content-center hidden">
        {steps.map((step: string, index: number) => {
          return <BreadcrumbStep key={index} name={step} stepNumber={index} selected={currentStep === index} />;
        })}
      </div>

      <div className="flex flex-row justify-center lg:hidden">
        <div className="text-sami-green text-sm font-bold mx-1">{steps[currentStep]}</div>
        <div className="text-input-border text-sm mx-1">{`${currentStep + 1} / ${steps.length}`}</div>
      </div>
    </>
  );
};
