import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useFormikContext } from 'formik';

dayjs.extend(duration);

export interface DayCountInputs {
  formikParams?: {
    key: string;
  };
  startDate?: string;
  endDate?: string;
  daysPerYear?: string;
  title?: string;
  description?: string;
}

export const dayCount = (startDate?: string, endDate?: string, daysPerYear?: string) => {
  if ((startDate === undefined || endDate === undefined) && daysPerYear === undefined) return 0;
  if (startDate === undefined || endDate === undefined) return isNaN(Number(daysPerYear)) ? 0 : Number(daysPerYear);

  const days = Math.abs(Math.round(dayjs.duration(dayjs(endDate).diff(dayjs(startDate))).asDays() + 1));

  if (daysPerYear === undefined || isNaN(Number(daysPerYear))) return days;

  const daysRatio = Number(daysPerYear) / 365;
  const daysPerPeriod = Math.round(days * daysRatio);

  return daysPerPeriod;
};

export const DayCount: FC<DayCountInputs> = ({ formikParams, startDate, endDate, daysPerYear, title, description }) => {
  const { setFieldValue } = useFormikContext();
  const days = dayCount(startDate, endDate, daysPerYear);

  useEffect(() => {
    if (formikParams) {
      setFieldValue(formikParams.key, `${days}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  if (!title) return <></>;

  return (
    <div className="flex flex-col gap-3">
      <div className="font-semibold text-sm">{title}</div>
      <div className="pr-8 text-sm text-text-dark font-medium">{days}</div>
      <div className="pr-8 text-sm text-text-dark font-medium">{description}</div>
    </div>
  );
};
