import { useEffect, useState } from 'react';
import { FC } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { Conditional } from '../Common/Conditional';
import { BrevFranOssContent, ShopContent } from '../../types/cms_shared_types';
import { LoadingSpinner } from '../Common/LoadingSpinner';
import SwedishFlag from '../../images/swedish.png';
import EnglishFlag from '../../images/english.png';

enum Languages {
  Swedish = 'sv',
  English = 'en',
}

const LANGUAGES = {
  [Languages.Swedish]: {
    image: SwedishFlag,
    name: 'Svenska',
    alt: 'Swedish flag',
  },
  [Languages.English]: {
    image: EnglishFlag,
    name: 'English',
    alt: 'UK flag',
  },
};

export const DEFAULT_LANGUAGE = Languages.Swedish;

interface LanguageDropdownProps {
  title?: string;
  cmsContent?: ShopContent | BrevFranOssContent;
}

export const LanguageDropdown: FC<LanguageDropdownProps> = ({ cmsContent }) => {
  const [language, setLanguage] = useLocalStorageState<keyof typeof LANGUAGES>('language', {
    defaultValue: DEFAULT_LANGUAGE,
  });

  const [isLoading, setIsLoading] = useState(false);

  const toggleLanguage = () => {
    setIsLoading(true);
    setLanguage(language === Languages.Swedish ? Languages.English : Languages.Swedish);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [cmsContent]);

  const selectedLanguage = language === Languages.Swedish ? LANGUAGES[Languages.English] : LANGUAGES[Languages.Swedish];

  return (
    <div className="relative inline-block text-left z-50 ">
      <Conditional
        condition={(cmsContent && isLoading) ?? false}
        trueRender={<LoadingSpinner className="mr-5 mt-2" />}
        falseRender={
          <button
            id="menu-button"
            type="button"
            onClick={toggleLanguage}
            className="inline-flex w-full justify-center px-4 py-2"
          >
            <img src={selectedLanguage.image} alt={selectedLanguage.alt} className="h-5" />
            <div className="ml-2 text-sm text-text-dark">{selectedLanguage.name}</div>
          </button>
        }
      />
    </div>
  );
};
