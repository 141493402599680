import { FC, MouseEvent, useEffect, useState } from 'react';
import { displayProductInformation } from '../../../helper/cms';
import { CmsProduct, ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { Button } from '../../Common/Button';
import { Product, ProductListActions } from '../../../types/webshop';

interface ProductCardProps {
  product: CmsProduct;
  selectedProducts: Product[];
  selectedProductsActions: ProductListActions;
  openProductConfigurationModal: () => void;
  cmsShopContent: ShopContent;
  isWithinGroup: boolean;
}

export const ProductCard: FC<ProductCardProps> = ({
  product,
  selectedProducts,
  selectedProductsActions,
  openProductConfigurationModal,
  cmsShopContent,
  isWithinGroup,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const isNonSchablonReportingProduct = product.reporting && !product.isSchablon;

  useEffect(() => {
    setIsSelected(selectedProducts.filter((p) => p.code === product.code).length === 1);
  }, [product.code, selectedProducts]);

  const openModal = (event?: MouseEvent) => {
    event?.stopPropagation();
    openProductConfigurationModal();
  };

  const addProduct = () => {
    if (isSelected) {
      return;
    }

    if (isNonSchablonReportingProduct) {
      const newProduct = {
        code: product.code,
        quantity: '0',
        timeQuantity: '0',
        musicClass: 'A',
        priceYearInclVat: 0,
        priceYearExclVat: 0,
      };
      selectedProductsActions.add(newProduct);
    } else {
      openModal();
    }
  };

  const removeProduct = () => {
    if (isNonSchablonReportingProduct) {
      const prodIndex = selectedProducts.findIndex((p) => p.code === product.code);
      selectedProductsActions.remove(prodIndex);
    } else {
      openModal();
    }
  };

  const computeBorderStyling = () => {
    return isSelected ? 'border border-sami-green' : ' border-sami-card-border ';
  };

  const className = isWithinGroup
    ? 'w-full sm:max-w-[600px] sm:w-[300px] lg:w-[500px] xl:w-[600px] flex flex-col mb-2 pl-6'
    : 'w-full sm:max-w-[600px] sm:w-[300px] lg:w-[500px] xl:w-[600px] flex flex-col mb-4 pl-6 ' +
      `py-6 rounded-lg border ${computeBorderStyling()} ${isSelected ? '' : 'cursor-pointer'} shadow hover:shadow-md`;

  return (
    <div onClick={addProduct} className={className}>
      <div className="flex flex-row items-center">
        <div className="w-4/6 sm:w-4/5 pr-2 sm:pr-4 flex flex-col">
          <div style={{ hyphens: 'auto' }} className="text-base text-text-dark font-bold">
            {product.name}
          </div>
          {isSelected && isNonSchablonReportingProduct && (
            <div className="mt-2 text-sm text-text-dark">{product.description}</div>
          )}
        </div>
        <div className="mx-auto pr-4 text-sami-green">
          <Conditional
            condition={isSelected}
            trueRender={
              <Button
                filled
                text={
                  isNonSchablonReportingProduct
                    ? cmsShopContent.productChoiceStep.removeProductButton
                    : cmsShopContent.productChoiceStep.editProductButton
                }
                onClick={removeProduct}
              />
            }
            falseRender={<Button text={cmsShopContent.productChoiceStep.addProductButton} onClick={addProduct} hover />}
          />
        </div>
      </div>

      <Conditional
        condition={isSelected}
        trueRender={
          <>
            {product.reporting || product.isSchablon ? null : (
              <div className="mr-1 text-sm text-grey font-medium">
                {cmsShopContent.productChoiceStep.selectionHeader}
              </div>
            )}
            {!isWithinGroup && (
              <div className="mr-1 text-sm text-grey mt-1">
                {product.reporting || product.isSchablon
                  ? cmsShopContent.productChoiceStep.reportingProductInformation
                  : displayProductInformation(product, selectedProducts)}
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
