import React, { FC } from 'react';
import { ShopContent } from '../../../types/cms_shared_types';
import { DefaultBottomBar } from '../BottomBar/DefaultBottomBar';
import { Step } from '../Step';
import { CategoryGrid } from './CategoryGrid';

interface ProductFamilyProps {
  selectedCategoryKey?: string;
  setSelectedCategoryKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  nextButtonClicked: () => void;
  cmsShopContent: ShopContent;
}

export const ProductFamily: FC<ProductFamilyProps> = ({
  selectedCategoryKey,
  nextButtonClicked,
  cmsShopContent,
  setSelectedCategoryKey,
}) => {
  return (
    <Step
      fullWidth
      title={cmsShopContent.productFamilyStep.title}
      content={
        <CategoryGrid
          setSelectedCategoryKey={setSelectedCategoryKey}
          selectedCategoryKey={selectedCategoryKey}
          cmsShopContent={cmsShopContent}
        />
      }
      bottomBar={
        <DefaultBottomBar
          continueButtonDisabled={!selectedCategoryKey}
          onClick={nextButtonClicked}
          cmsShopContent={cmsShopContent}
          footerContent={cmsShopContent.productFamilyStep.footer}
        />
      }
    />
  );
};
