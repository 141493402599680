import React from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { RichText, ShopContent } from '../../types/cms_shared_types';
import { Button } from '../Common/Button';
import { Modal } from './Modal';

interface ConfirmationDialogProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  onYesCallback: () => void;
  title?: string;
  description?: RichText;
  cmsShopContent: ShopContent;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  showModal,
  setShowModal,
  onYesCallback,
  title,
  description,
  cmsShopContent,
}) => {
  const closeModal = () => setShowModal(false);

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      small
      header={<div>{title}</div>}
      content={<div>{render(description)}</div>}
      actions={
        <div className="flex flex-row">
          <div className="">
            <Button filled black text={cmsShopContent.confirmationDialog.noButton.toUpperCase()} onClick={closeModal} />
          </div>
          <div className="ml-auto">
            <Button
              filled
              text={cmsShopContent.confirmationDialog.yesButton.toUpperCase()}
              onClick={() => {
                onYesCallback();
                closeModal();
              }}
            />
          </div>
        </div>
      }
    ></Modal>
  );
};
