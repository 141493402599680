import axios, { AxiosResponse } from 'axios';
import {
  AgreementParams,
  AgreementResponse,
  CreateCaseParams,
  PriceProduct,
  PriceResponse,
} from '../types/api_shared_types';
import { BrevFranOssContent } from '../types/cms_shared_types';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
});

export const apiPing = async () => {
  const url = '/ping';
  return api.get(url);
};

export const apiGetCmsContent = async (lang: string, contentName: string) => {
  const url = `/${lang}/${contentName}`;
  return api.get(url);
};

export const apiGetBrevFranOssCmsContent = async (lang: string): Promise<AxiosResponse<BrevFranOssContent>> => {
  return apiGetCmsContent(lang, 'brev-fran-oss');
};

export const apiCreateCase = async (params: CreateCaseParams): Promise<AxiosResponse> => {
  const url = '/cases';
  return api.post(url, params);
};

export const apiCreateCaseWithoutWaiting = (params: CreateCaseParams) => {
  fetch(`${process.env.REACT_APP_API_PATH}/cases`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
    keepalive: true,
  });
};

export const apiGetPrice = async (product: PriceProduct): Promise<AxiosResponse<PriceResponse>> => {
  const url = '/prices';
  return api.post(url, product);
};

export const apiPostAgreement = async (params: AgreementParams): Promise<AxiosResponse<AgreementResponse>> => {
  const url = '/agreement';
  return api.post(url, params);
};

interface PollContractResponse {
  signedStatus: string;
}

export const apiPollContractStatus = async (contractId: number): Promise<AxiosResponse<PollContractResponse>> => {
  const url = `/contracts/${contractId}`;
  return api.get(url);
};
