import { FC } from 'react';
import { ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';
import { LoadingSpinner } from '../../Common/LoadingSpinner';

interface TotalPriceProps {
  price: string;
  vatPrice: string;
  totalPriceFetching: boolean;
  cmsShopContent: ShopContent;
}

export const TotalPrice: FC<TotalPriceProps> = ({ price, vatPrice, totalPriceFetching, cmsShopContent }) => {
  return (
    <div className="flex flex-row mx-3 sm:ml-6 sm:mr-16 mt-4">
      <div className="flex flex-col items-start">
        <div className="text-text-dark text-base sm:text-xl font-bold">
          {cmsShopContent.shoppingCart.shoppingCartModal.total}
        </div>
        <div className="text-text-dark text-sm font-medium">{cmsShopContent.shoppingCart.shoppingCartModal.vat}</div>
      </div>
      <div className="flex flex-col items-end ml-auto self-center">
        <Conditional
          condition={totalPriceFetching}
          trueRender={<LoadingSpinner />}
          falseRender={
            <div className="flex flex-col items-end">
              <div className="text-text-dark text-base sm:text-xl font-bold">
                {price} {cmsShopContent.priceWithMonthFormat}
              </div>
              <div className="text-text-dark text-sm">
                {vatPrice} {cmsShopContent.priceWithoutMonthFormat}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
