import { FC, useState } from 'react';
import { useFormikContext } from 'formik';
import { BrevFranOssFormInputs } from '../../pages/BrevFranOssPage';
import { BrevFranOssContent, Question } from '../../types/cms_shared_types';
import { MusikavtalRedirect } from './MusikavtalRedirect';
import { AnswerState, QuestionCard } from './QuestionCard';
import { SubmissionForm } from './SubmissionForm';
import { SubmitButton, SubmitStatus } from './SubmitButton';
import { Conditional } from '../Common/Conditional';

interface QuestionsProps {
  submitStatus: SubmitStatus;
  setSubmitStatus: (value: SubmitStatus) => void;
  sendCreateCaseRequest: (ignoreContact: boolean) => void;
  cmsBrevFranOssContent: BrevFranOssContent;
  referenceNumber: string;
}

export const Questions: FC<QuestionsProps> = ({
  submitStatus,
  setSubmitStatus,
  sendCreateCaseRequest,
  cmsBrevFranOssContent,
  referenceNumber,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { values, setFieldValue } = useFormikContext<BrevFranOssFormInputs>();

  const setQuestionAnswer = (index: number, state: AnswerState): (() => void) => {
    return () => {
      const newAnswers = [...values.answers];
      newAnswers[index] = state;

      if (state === AnswerState.YES) {
        for (let i = index + 1; i < values.answers.length; i++) {
          newAnswers[i] = AnswerState.NONE;
        }
      }

      setCurrentIndex(Math.min(index + 1, values.answers.length - 1));

      setFieldValue('answers', newAnswers);
    };
  };

  const showGetAgreement = values.answers.some(
    (answer, index) => index !== values.answers.length - 1 && answer === AnswerState.YES,
  );
  const lastAnswer = values.answers[values.answers.length - 1];
  const firstQuestion = cmsBrevFranOssContent.questions[0];

  const isLastQuestion = (question: Question): boolean => {
    return question.key === cmsBrevFranOssContent.questions[cmsBrevFranOssContent.questions.length - 1].key;
  };

  return (
    <>
      <QuestionCard
        question={firstQuestion.question}
        explanation={firstQuestion.explanation}
        yesLabel={cmsBrevFranOssContent.yesAnswer}
        noLabel={cmsBrevFranOssContent.noAnswer}
        onYesClick={() => {
          if (values.answers[0] === AnswerState.NONE) {
            // sendCreateCaseRequest();
          }
          setQuestionAnswer(0, AnswerState.YES)();
        }}
        onNoClick={setQuestionAnswer(0, AnswerState.NO)}
        state={values.answers[0]}
        scrollToBlockMode="start"
        noScrollRef
      />

      {cmsBrevFranOssContent.questions
        .filter(
          (_, index) =>
            index > 0 && index <= currentIndex && (index == 0 || values.answers[index - 1] !== AnswerState.YES),
        )
        .map((question, index) => (
          <QuestionCard
            key={question.key}
            question={question.question}
            explanation={question.explanation}
            yesLabel={cmsBrevFranOssContent.yesAnswer}
            noLabel={cmsBrevFranOssContent.noAnswer}
            onYesClick={() => {
              setQuestionAnswer(index + 1, AnswerState.YES)();

              if (!isLastQuestion(question) && values.answers[index + 1] === AnswerState.NONE) {
                // sendCreateCaseRequest();
              }

              if (isLastQuestion(question)) {
                setSubmitStatus(SubmitStatus.NONE);
              }
            }}
            onNoClick={() => {
              setQuestionAnswer(index + 1, AnswerState.NO)();

              if (isLastQuestion(question) && values.answers[index + 1] === AnswerState.NONE) {
                // sendCreateCaseRequest();
              }
            }}
            state={values.answers[index + 1]}
            scrollToBlockMode="start"
          />
        ))}

      <Conditional
        condition={showGetAgreement}
        trueRender={
          <MusikavtalRedirect
            cmsBrevFranOssContent={cmsBrevFranOssContent}
            referenceNumber={referenceNumber}
            onRedirect={() => sendCreateCaseRequest(true)}
          />
        }
      />

      <Conditional
        condition={lastAnswer === AnswerState.YES}
        trueRender={<SubmissionForm submitStatus={submitStatus} cmsBrevFranOssContent={cmsBrevFranOssContent} />}
      />

      <Conditional
        condition={lastAnswer === AnswerState.NO}
        trueRender={
          <div>
            {cmsBrevFranOssContent.submitOnNoMessage}
            <div className="mt-6">
              <SubmitButton
                text={cmsBrevFranOssContent.submitButton}
                feedbackSuccessfulText={cmsBrevFranOssContent.submitSuccessfulMessage}
                feedbackErrorText={cmsBrevFranOssContent.submitErrorMessage}
                isSubmitting={false}
                submitStatus={submitStatus}
                disabled={submitStatus === SubmitStatus.SUCCESS || submitStatus === SubmitStatus.FAIL}
                onClick={() => sendCreateCaseRequest(true)}
              ></SubmitButton>
            </div>
          </div>
        }
      />
    </>
  );
};
