import { FC } from 'react';
import { ShopContent } from '../../../types/cms_shared_types';
import { Conditional } from '../../Common/Conditional';

interface ShoppingProductMobileProps {
  name: string;
  schablonSummary?: string;
  summary: string;
  price: string;
  icon: JSX.Element;
  priceSimulatorHeader?: JSX.Element;
  priceSimulatorContent?: JSX.Element;
  cmsShopContent: ShopContent;
}

export const ShoppingProductMobile: FC<ShoppingProductMobileProps> = ({
  name,
  schablonSummary,
  summary,
  price,
  icon,
  priceSimulatorHeader,
  priceSimulatorContent,
  cmsShopContent,
}) => {
  return (
    <div className="mb-4 flex flex-row items-center">
      <div className="grow pl-6 pr-4 py-3.5 rounded-lg bg-white border border-sami-card-border shadow hover:shadow-md">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col pr-2.5">
            <div style={{ hyphens: 'auto' }} className="leading-tight text-text-dark text-sm font-bold">
              {name}
            </div>
            <Conditional
              condition={schablonSummary !== undefined}
              trueRender={<div className="mt-2 text-text-dark text-sm">{schablonSummary}</div>}
            />
            <div className="mt-2 text-text-dark text-xs">{summary}</div>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col items-end">{icon}</div>
            <Conditional
              condition={priceSimulatorHeader === undefined}
              trueRender={
                <div className="mt-2.5 text-text-dark font-bold text-xs truncate">
                  {price} {cmsShopContent.priceWithMonthFormat}
                </div>
              }
            />
          </div>
        </div>

        <Conditional
          condition={priceSimulatorHeader !== undefined}
          trueRender={<div className="mt-4 text-end shrink-0">{priceSimulatorHeader}</div>}
        />

        {priceSimulatorContent}
      </div>
    </div>
  );
};
