import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

export interface ErrorState {
  code: string;
  message: string;
}

export function handleError(err: unknown, navigate: NavigateFunction) {
  if (axios.isAxiosError(err)) {
    return navigate('/error', { state: { code: err.code, message: err.message } });
  }
  navigate('/error');
}
