import { FC, useEffect } from 'react';
import { apiPollContractStatus } from '../../../api/api';
import { ShopContent } from '../../../types/cms_shared_types';
import { Button } from '../../Common/Button';
import { Conditional } from '../../Common/Conditional';
import { LoadingSpinner } from '../../Common/LoadingSpinner';
import { CancelBottomBar } from '../BottomBar/CancelBottomBar';
import { Step } from '../Step';
import { Steps } from '../WebshopForm';
import { trackEvent } from '../../../helper/analytics';

interface SigningPageProps {
  onCancelSigning: () => void;
  cmsShopContent: ShopContent;
  contractId?: number;
  contractAccessLink: string;
  setCurrentStep: (arg0: Steps) => void;
  setSigningErrorOccurred: (arg0: boolean) => void;
  analyticsCategoryIndicator: string;
}

export const SigningPage: FC<SigningPageProps> = ({
  onCancelSigning,
  cmsShopContent,
  contractId,
  contractAccessLink,
  setCurrentStep,
  setSigningErrorOccurred,
  analyticsCategoryIndicator,
}) => {
  const CONTRACT_STATUS_POLL_INTERVAL_IN_MSEC = 5000;
  const warnUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (contractId === undefined) {
      return;
    }

    const id = window.setInterval(async () => {
      const res = await apiPollContractStatus(contractId);
      const isSigned = res.data.signedStatus === 'signed';

      if (isSigned || res.data.signedStatus === 'error') {
        setSigningErrorOccurred(res.data.signedStatus === 'error');
        setCurrentStep(Steps.LANDING_PAGE);
      }

      if (isSigned) {
        trackEvent('SignedAgreement', analyticsCategoryIndicator);
      }
    }, CONTRACT_STATUS_POLL_INTERVAL_IN_MSEC);

    return () => {
      clearInterval(id);
    };
  }, [contractId, setCurrentStep, setSigningErrorOccurred, analyticsCategoryIndicator]);

  useEffect(() => {
    window.addEventListener('beforeunload', warnUser);
    return () => {
      window.removeEventListener('beforeunload', warnUser);
    };
  }, []);

  return (
    <Step
      title={cmsShopContent.signingPageStep.title}
      content={
        <div className="sm:w-9/12 max-w-3xl mx-auto mt-6 sm:mt-0 flex flex-col items-center text-center">
          <div className="mb-12 text-text-dark text-lg sm:text-xl font-extrabold">
            {cmsShopContent.signingPageStep.subtitle}
          </div>

          <LoadingSpinner className="mb-4" />
          <Conditional
            condition={contractAccessLink !== ''}
            trueRender={
              <Button
                text={cmsShopContent.signingPageStep.redirectButton}
                onClick={() => window.open(contractAccessLink, '_blank')}
                filled
              />
            }
          />

          <div style={{ hyphens: 'auto' }} className="mt-8 text-text-dark text-base">
            {cmsShopContent.signingPageStep.description}
          </div>
        </div>
      }
      bottomBar={<CancelBottomBar onClick={onCancelSigning} cmsShopContent={cmsShopContent} />}
    />
  );
};
