export const SubcategoryItem = ({
  name,
  isSelected,
  onClick,
}: {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const getTextStyling = () => {
    return isSelected ? 'text-sami-green' : 'text-text-dark';
  };

  const getBorderStyling = () => {
    return isSelected ? 'border border-sami-green' : 'border border-sami-card-border';
  };

  return (
    <button
      type="button"
      onClick={onClick}
      style={{ hyphens: 'auto' }}
      className={`w-full max-w-[400px] mt-4 p-3 text-left text-base ${getTextStyling()} rounded-lg ${getBorderStyling()} ${
        isSelected ? 'cursor-auto' : 'hover:bg-black hover:bg-opacity-5'
      } focus:outline-none`}
    >
      {name}
    </button>
  );
};
