import { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { Toggle } from './Toggle';

interface UserBooleanInputProps {
  name: string;
  title?: string;
  description?: string;
  callback?: (value: boolean) => void;
}

export const UserBooleanInput: FC<UserBooleanInputProps> = ({ name, title, description, callback }) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<boolean>) => (
        <div className="w-full mb-8">
          <div className="mb-2 text-sm text-text-dark font-bold">{title}</div>
          <div className="flex flex-row items-center py-2 overflow-hidden rounded-lg">
            <div className="pr-8 text-sm text-text-dark font-medium">{description}</div>
            <div className="ml-auto">
              <Toggle
                checked={field.value}
                onChange={() => {
                  form.setFieldValue(name, !field.value);
                  if (callback) {
                    callback(!field.value);
                  }
                }}
                onBlur={() => form.setTouched({ ...form.touched, [field.name]: true }, true)}
              />
            </div>
          </div>
        </div>
      )}
    </Field>
  );
};
