import { useEffect, useState } from 'react';
import { Header } from '../components/Header/Header';
import { useLocation } from 'react-router-dom';
import { ErrorState } from '../helper/errors';
import useLocalStorageState from 'use-local-storage-state';
import { DEFAULT_LANGUAGE } from '../components/Header/LanguageDropdown';
import KontaktBrev from '../images/kontakt_brev.png';

export const ErrorDefaultPage = () => {
  const location = useLocation();

  const [language] = useLocalStorageState<string>('language', { defaultValue: DEFAULT_LANGUAGE });

  const [errorState, setErrorState] = useState<ErrorState>();

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    setErrorState(location.state as ErrorState);

    if (language === 'sv') {
      setTitle('Oj, nu blev det fel!');
      setSubtitle('Försök igen senare, om felet kvarstår kan du kontakta vår support.');
    } else {
      setTitle('Oops, something went wrong!');
      setSubtitle('Please try again later, if the error persists please contact our support.');
    }
  }, [language, location.state]);

  return (
    <div className="h-screen flex flex-col justify-between">
      <Header />
      <div className="px-mobile-side sm:px-0 mb-auto">
        <div className="flex flex-col sm:mx-auto">
          <div
            className="flex flex-col items-center pt-8 sm:pt-16 pb-8 sm:pb-8 px-24"
            style={{ fontFamily: 'Montserrat,sans-serif' }}
          >
            <div className="flex flex-col sm:flex-row items-center mt-16">
              <div className="mt-0 sm:mt-0 text-center text-3xl sm:text-8xl text-text-dark font-bold">{title}</div>
            </div>
            <div className="mt-8 sm:mt-16 text-center text-sm sm:text-2xl text-text-dark font-medium">{subtitle}</div>
            <div className="mt-10 sm:mt-16 text-center text-text-gray text-md">
              {errorState ? `${errorState?.code}: ${errorState?.message}` : ''}
            </div>
          </div>
        </div>
      </div>
      <footer className="sm:h-20 h:16 sm:mx-4 sm:my-2 mx-2 my-1">
        <figure className="sm:flex bg-sami-green hover:bg-sami-green-dark rounded-full p-2 sm:p-2 sm:w-16 sm:h-16 w-12 h-12 float-right">
          <a href="mailto:support@sami.se">
            <img
              className="sm:w-12 sm:h-12 w-8 h-8 rounded-full mx-auto"
              src={KontaktBrev}
              alt=""
              width="384"
              height="512"
            />
          </a>
        </figure>
      </footer>
    </div>
  );
};
