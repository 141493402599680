import PiwikPro from '@piwikpro/react-piwik-pro';
import { CustomEvent } from '@piwikpro/react-piwik-pro';

export type ANALYTIC_ACTIONS =
  | 'SubmittedLetter'
  | 'SelectedCateogry'
  | 'SelectedSubcategory'
  | 'ConfirmedPrice'
  | 'SubmittedLocation'
  | 'SubmittedPaymentDetails'
  | 'CreatedAgreement'
  | 'SignedAgreement';

const WEBSHOP_CUSTOM_EVENT_CATEGORY = 'WebshopEvent';

const piwikContainerId = process.env.REACT_APP_PIWIK_ID;
const piwikContainerUrl = process.env.REACT_APP_PIWIK_URL ?? 'https://sami.containers.piwik.pro';

export function setupAnalytics() {
  if (!piwikContainerId) {
    return;
  }
  try {
    PiwikPro.initialize(piwikContainerId, piwikContainerUrl);
  } catch (e) {
    // Unhandled, Piwik related errors won't prevent proper usage of the webshop
  }
}

export function trackEvent(action: ANALYTIC_ACTIONS, name?: string, value?: number) {
  if (!piwikContainerId) {
    return;
  }
  try {
    CustomEvent.trackEvent(WEBSHOP_CUSTOM_EVENT_CATEGORY, action, name, value);
  } catch (e) {
    // Unhandled, Piwik related errors won't prevent proper usage of the webshop
  }
}
