import { FC, useState } from 'react';
import { Field, FieldProps } from 'formik';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

const theme = createTheme({
  palette: {
    primary: {
      main: '#26A46F',
    },
  },
});

interface UserDateInputProps {
  name: string;
  prompt: string;
  inputFormat?: string;
  allowPastDates?: boolean;
  dateLimitEarly?: string;
  dateLimitLate?: string;
}

export const UserDateInput: FC<UserDateInputProps> = ({
  name,
  prompt,
  inputFormat,
  allowPastDates = false,
  dateLimitEarly,
  dateLimitLate,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full mb-8">
      <div className="flex flex-col mb-2 text-sm text-text-dark font-bold">{prompt}</div>
      <div className="sm:flex sm:flex-row">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <Field name={name}>
              {({ field, form }: FieldProps<Dayjs>) => {
                return (
                  <DatePicker
                    inputFormat={inputFormat ?? 'YYYY-MM-DD'}
                    disablePast={!allowPastDates}
                    value={field.value ?? null /* convert undefined to null to display empty selection */}
                    open={open}
                    onChange={(newDate) => {
                      const date = newDate ?? undefined; // convert nulls back to undefined
                      if (dateLimitEarly && dayjs(date).diff(dayjs(dateLimitEarly)) < 0) {
                        form.setFieldValue(name, dateLimitEarly);
                      } else if (dateLimitLate && dayjs(dateLimitLate).diff(dayjs(date)) < 0) {
                        form.setFieldValue(name, dateLimitLate);
                      } else {
                        form.setFieldValue(name, date);
                      }
                    }}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    closeOnSelect={true}
                    showToolbar={false}
                    disableOpenPicker
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <div
                        onClick={() => setOpen(true)}
                        className="p-3 pl-1 rounded-lg bg-input-gray border border-opacity-20 border-input-border hover:border-b-2 hover:border-t-0 hover:border-x-0 hover:bg-sami-hover hover:border-sami-green"
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <div className="mr-5">{InputProps?.endAdornment}</div>
                          <input
                            ref={inputRef}
                            {...inputProps}
                            className="bg-inherit text-md text-text-dark outline-none"
                            readOnly
                          />
                        </Box>
                      </div>
                    )}
                    componentsProps={{
                      actionBar: {
                        actions: ['today', 'clear'],
                      },
                    }}
                  />
                );
              }}
            </Field>
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};
