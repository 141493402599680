import { FC, useState } from 'react';
import { ShoppingCart as ShoppingCartIcon } from 'phosphor-react';
import { ShopContent } from '../../../types/cms_shared_types';
import { ShoppingCartModal } from './ShoppingCartModal';
import { Product, ProductListActions } from '../../../types/webshop';

interface ShoppingCartProps {
  selectedCategoryKey?: string;
  selectedSubcategoryKey?: string;
  price: string;
  vatPrice: string;
  selectedProducts?: Product[];
  selectedProductsActions?: ProductListActions;
  disable?: boolean;
  cmsShopContent: ShopContent;
}

export const ShoppingCart: FC<ShoppingCartProps> = ({
  selectedCategoryKey,
  selectedSubcategoryKey,
  price,
  vatPrice,
  selectedProducts,
  selectedProductsActions,
  disable,
  cmsShopContent,
}) => {
  const [showShoppingCartModal, setShowShoppingCartModal] = useState(false);

  const getHoverStyling = () => {
    if (!disable) {
      return 'hover:bg-black hover:bg-opacity-5 cursor-pointer';
    }
    return '';
  };

  return (
    <>
      <div
        onClick={() => !disable && setShowShoppingCartModal(true)}
        className={`w-fit sm:flex flex-row sm:items-center p-2 sm:px-6 sm:py-2 bg-input-gray sm:bg-transparent rounded-lg sm:rounded-[100px] border border-input-border sm:border-none ${
          disable ? 'hidden' : ''
        } ${getHoverStyling()}`}
      >
        <ShoppingCartIcon size={32} className="text-sami-green" />

        <div className="hidden sm:block ml-3 text-base text-sami-green font-bold truncate">
          {price} {cmsShopContent.priceWithMonthFormat}
        </div>
      </div>

      {selectedProducts && selectedProductsActions ? (
        <ShoppingCartModal
          showShoppingCartModal={showShoppingCartModal}
          setShowShoppingCartModal={setShowShoppingCartModal}
          selectedCategoryKey={selectedCategoryKey}
          selectedSubcategoryKey={selectedSubcategoryKey}
          price={price}
          vatPrice={vatPrice}
          selectedProducts={selectedProducts}
          selectedProductsActions={selectedProductsActions}
          cmsShopContent={cmsShopContent}
        />
      ) : (
        <></>
      )}
    </>
  );
};
