import dayjs from 'dayjs';

export const REFERENCE_NUMBER_REGEX = /^[k|K]\d{8}$/;
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+)*$/;
export const SWEDISH_POST_CODE_REGEX = /^(\d{3} \d{2}|\d{5})$/;
export const LETTERS_ONLY_REGEX = /^([^0-9]*)$/;

export function capitalizeFirstLetter(value: string): string {
  return value ? value[0].toUpperCase() + value.slice(1).toLowerCase() : '';
}

export function addSpaceDelimiter(price: string) {
  return price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatDate(date: string | Date | undefined, includeYear = true): string {
  if (!date) {
    return '';
  }
  return dayjs(date).format(includeYear ? 'DD/MM/YYYY' : 'DD/MM');
}
