import { MusicClass } from '../components/Webshop/ProductChoice/ProductConfigurationModal/ProductConfigurationModal';
import { InputType } from '../components/Webshop/ProductChoice/ProductConfigurationModal/ProductInput';
import {
  BrevFranOssContent,
  Category,
  CmsProduct,
  ProductInputTypeOption,
  Question,
  Subcategory,
  UserInput,
  UserSelectInput,
} from '../types/cms_shared_types';
import { Product } from '../types/webshop';
import { addSpaceDelimiter, formatDate } from './helper';

export function findQuestionByNumber(questions: Question[], questionNumber: number): Question | undefined {
  return questions.find((question: Question) => question.key === `question_${questionNumber}`);
}

export function findUserInputResourceByKey(key: string, resources?: UserInput[]): UserInput {
  if (!resources) {
    throw new Error(
      `Couldn't find UserInputResource with key '${key}' because given 'resources' array was undefined or null`,
    );
  }
  const elem = resources.find((ui: UserInput) => ui.key === key);
  if (!elem) {
    throw new Error(`Couldn't find UserInputResource with key '${key}'`);
  }
  return elem;
}

export function findUserSelectInputResourceByKey(key: string, resources?: UserSelectInput[]): UserSelectInput {
  if (!resources) {
    throw new Error(
      `Couldn't find UserSelectInputResource with key '${key}' because given 'resources' array was undefined or null`,
    );
  }
  const elem = resources.find((usi: UserSelectInput) => usi.key === key);
  if (!elem) {
    throw new Error(`Couldn't find UserSelectInputResource with key '${key}'`);
  }
  return elem;
}

export function getBrevFranOssUserInput(
  userInputKey: string,
  brevFranOssContent?: BrevFranOssContent,
): UserInput | null {
  if (!brevFranOssContent) {
    return null;
  }
  return brevFranOssContent.userInputs.find((u: UserInput) => u.key === userInputKey) ?? null;
}

export function findSelectedCategoryByKey(categories: Category[], key?: string): Category | undefined {
  const category = categories.find((category: Category) => category.key === key);
  if (!category) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't find the category associated with key '${key}'`);
    return;
  }
  return category;
}

export function findSelectedSubcategoryByKey(key?: string, subcategories?: Subcategory[]): Subcategory | undefined {
  if (!subcategories) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't find the subcategory associated with key '${key}'`);
    return;
  }
  const subcategory = subcategories.find((subcategory: Subcategory) => subcategory.key === key);
  if (!subcategory) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't find the subcategory associated with key '${key}'`);
    return;
  }
  return subcategory;
}

export function isInvalidDropdownType(type: string, options: ProductInputTypeOption[]): boolean {
  return type === InputType.DROPDOWN && options.length === 0;
}

export const isMeasureSimulatable = (product: CmsProduct): boolean => {
  if (product.measureMin === undefined || product.measureMax === undefined) {
    return false;
  }
  return product.hasMeasure && !product.measureDisableSimulation;
};

export const isTimeMeasureSimulatable = (product: CmsProduct): boolean => {
  if (product.timeMeasureMin === undefined || product.timeMeasureMax === undefined) {
    return false;
  }
  return product.hasTimeMeasure && !product.timeMeasureDisableSimulation;
};

export function displayProductInformation(product: CmsProduct, selectedProducts: Product[]): string {
  try {
    const returnProduct = selectedProducts.find((p) => p.code === product.code);
    if (returnProduct) {
      const arr = [];

      if ((!product.reporting && product.hasMeasure) || (product.reporting && isMeasureSimulatable(product))) {
        if (
          product.measureInputType === InputType.DROPDOWN &&
          !isInvalidDropdownType(product.measureInputType, product.measureOptions)
        ) {
          const option = product.measureOptions.find((o) => o.salesforceValue.toString() === returnProduct.quantity);
          if (option) {
            arr.push(`${option?.displayValue}`);
          } else {
            arr.push(`${addSpaceDelimiter(returnProduct.quantity)} ${product.measureUnit}`);
          }
        } else {
          arr.push(`${addSpaceDelimiter(returnProduct.quantity)} ${product.measureUnit}`);
        }
      }

      if ((!product.reporting && product.hasTimeMeasure) || (product.reporting && isTimeMeasureSimulatable(product))) {
        if (
          product.timeMeasureInputType === InputType.DROPDOWN &&
          !isInvalidDropdownType(product.timeMeasureInputType, product.timeMeasureOptions)
        ) {
          const option = product.timeMeasureOptions.find(
            (o) => o.salesforceValue.toString() === returnProduct.timeQuantity,
          );
          if (option) {
            arr.push(`${option?.displayValue}`);
          } else {
            arr.push(`${addSpaceDelimiter(returnProduct.timeQuantity)} ${product.timeMeasureUnit}`);
          }
        } else {
          arr.push(`${addSpaceDelimiter(returnProduct.timeQuantity)} ${product.timeMeasureUnit}`);
        }
      }

      if (returnProduct.musicClass === MusicClass.B) {
        arr.push('Radio / TV');
      }

      if (returnProduct.startDate && returnProduct.endDate) {
        const includeYear = false;
        const periodStart = formatDate(returnProduct.startDate, includeYear);
        const periodEnd = formatDate(returnProduct.endDate, includeYear);
        if (product.periods && product.periods.includes('{start}') && product.periods.includes('{end}')) {
          arr.push(product.periods.replace('{start}', periodStart).replace('{end}', periodEnd));
        } else {
          arr.push(`${periodStart} - ${periodEnd}`);
        }
      }

      return arr.join(', ');
    } else {
      throw new Error(`Couldn't find product '${product.code}'`);
    }
  } catch {
    return '';
  }
}

export function displaySchablonProductInformation(prod: Product, product: CmsProduct): string {
  return `${addSpaceDelimiter(
    prod.schablonQuantity ?? '',
  )} ${product.measureSchablonUnit.toLowerCase()}, ${addSpaceDelimiter(
    prod.schablonTimeQuantity ?? '',
  )} ${product.timeMeasureSchablonUnit.toLowerCase()}`;
}

export const retrieveCmsProduct = (
  code: string,
  categories: Category[],
  selectedCategoryKey?: string,
  selectedSubcategoryKey?: string,
): CmsProduct | undefined => {
  const category = findSelectedCategoryByKey(categories, selectedCategoryKey);
  const subcategory = findSelectedSubcategoryByKey(selectedSubcategoryKey, category?.subcategories);

  const allProducts = [...(subcategory?.products ?? []), ...(subcategory?.extraProducts ?? [])];
  const prod = allProducts.find((p) => p.code === code);

  if (!prod) {
    // eslint-disable-next-line no-console
    console.error(`Couldn't find product '${code}'`);
    return;
  }

  prod.measurePeriod ||= getMeasurePeriod(prod);

  return prod;
};

export const getPriceSum = (selectedProducts: Product[]): { sumPrice: number; sumPriceVat: number } => {
  const [sumPrice, sumPriceVat] = selectedProducts.reduce(
    ([priceSum, vatPriceSum], { priceYearInclVat, priceYearExclVat }) => [
      priceSum + (priceYearExclVat ?? 0), // priceYear naming is misleading and does not have to be per year
      vatPriceSum + (priceYearInclVat ?? 0), // priceYear naming is misleading and does not have to be per year
    ],
    [0, 0],
  );
  return { sumPrice, sumPriceVat };
};

export const getMeasurePeriod = (cmsProduct: CmsProduct): string => {
  return cmsProduct.measurePeriod ?? 'month';
};

// Return the price of a product per month
export const getSchablonPricePerMonth = (cmsProduct: CmsProduct, price: number): number => {
  const period = getMeasurePeriod(cmsProduct);
  switch (period) {
    case 'year':
      return price / 12;
    case 'month':
    default:
      return price;
  }
};
