import { FC } from 'react';
import { Category } from '../../../types/cms_shared_types';

interface CategoryItemProps {
  category: Category;
  isSelected: boolean;
  onClick: () => void;
}

export const CategoryItem: FC<CategoryItemProps> = ({ category, isSelected, onClick }) => {
  return (
    <button
      type={'button'}
      key={category.key}
      onClick={onClick}
      className={`w-full h-full pt-3 pb-1 sm:h-36 sm:w-32 sm:pb-4 sm:px-4 sm:pt-5 rounded-lg flex flex-col items-center ${
        isSelected ? 'bg-sami-green' : 'hover:bg-sami-green hover:bg-opacity-10'
      } border  border-sami-card-border`}
    >
      <link rel="preload" as="image" href={category.iconClickedFilename} />
      <img
        className="mb-1 h-8 w-8 sm:h-16 sm:w-16"
        src={isSelected ? category.iconClickedFilename : category.iconNotClickedFilename}
      />
      <div
        className={`h-full flex items-center text-center text-[10px] sm:text-sm mx-0.5 ${
          isSelected ? 'text-input-gray font-bold' : 'text-text-dark font-medium '
        }`}
      >
        {category.name}
      </div>
    </button>
  );
};
