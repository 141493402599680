import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import { LengthType } from 'react-spinners/helpers/props';

interface LoadingSpinnerProps {
  size?: LengthType;
  color?: string;
  className?: string;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 32, color = '#26A46F', className }) => {
  return <ClipLoader size={size} color={color} className={className} />;
};
